import React from "react";

const chinmayBarve = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Chinmay Barve</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>National Payments Corporation of India(NPCI)</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>An aptitude test followed by a single interview.</p>
          </p>
          <p>
            <br />
            <b>
              2. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>
              Academic Eligibility :<br />
              CLASS XII - Required: 60%
              <br />
              UG - Required:60%
              <br />
              CLASS X - Required: 60%
              <br />
            </p>
          </p>
          <br />
          <p>
            <b>3. What was the structure of the Aptitude Round ? </b>
            <br />
            <p>
              English Language based questions, Analytical Reasoning,
              Quantitative Aptitude were covered in the first part of the test,
              Technical(ML based) questions in the second part, Written English
              Test(give views on, say, healthcare in ~200 words) in the third
              part, Two programming questions in the last part.
            </p>
          </p>
          <p>
            <b>
              4. After being shortlisted, how long was the wait time before the
              interviews : began? During this period, what specific areas did
              you focus on to prepare effectively?
            </b>
            <br />
            <p>
              The interviews were scheduled a week after being shortlisted.
              During this period, I mainly focused on the things mentioned in
              the resume.
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              5. How many rounds were there in the company, can you elaborate
              about each round in brief ?
            </b>
            <br />
            <p>
              There were two rounds: Aptitude round followed by a single
              interview. The Aptitude round had an online test which consisted
              of several parts as mentioned above while the interview round
              consisted of a single interview(Technical combined with HR) that
              lasted for about 25-30 minutes.
            </p>
          </p>
          <br />
          <p>
            <b>
              6. What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <br />
            <p>
              There were questions based on C/C++, DSA, OOPS, DBMS, SQL,
              HTML(project specific), APIs(project specific).
            </p>
          </p>
          <br />
          <p>
            <b>7. How many students were shortlisted after each round? </b>
            <br />
            10 students were shortlisted after the aptitude round, and 7 out of
            those 10 were selected in the interview round.
          </p>
          <br />
          <p>
            <b>8. Some good resources you used in your internship prep? </b>
            <br />
            <a href="https://www.udemy.com/course/datastructurescncpp/?couponCode=ST21MT121624">
              Mastering Data Structures & Algorithms using C and C++
            </a>
            <br />
            <a href="https://youtu.be/HcOc7P5BMi4?si=EH5fyD1vzhjkHQYr">
              HTML Tutorial for Beginners | Complete HTML with Notes & Code
            </a>
            <br />
            <a href="https://youtu.be/HcOc7P5BMi4?si=EH5fyD1vzhjkHQYr">
              CSS Tutorial for Beginners | Complete CSS with Project, Notes &
              Code
            </a>
            <br />
          </p>
          <br />
          <p>
            <b>
              {" "}
              10. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>
              6 to 8 questions based on the project followed by some questions
              based on the answers I gave on the previously asked questions.
              Clubs and extracurricular activities were given significant
              attention in my interview and had a very positive impact on my
              performance in the interview.
            </p>
          </p>
          <p>
            <b>
              {" "}
              11. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              The company selected 7 out of the 10 shortlisted candidates, and
              hence, if shortlisted after the aptitude round, try your best to
              grab the opportunity.
            </p>
          </p>

          <br />
          <br />
          <p>
            <b> Any Final tips ?</b>
            <br />
            <p>
              CGPA be good enough to make you eligible for the opportunity and
              skills be good enough to grab it! Good luck!
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:chinmay2324@gmail.com">chinmay2324@gmail.com</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default chinmayBarve;
