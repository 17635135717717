import React from "react";

const SaanviRohanShet = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        className="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem"
                        }}
                    ></span>
                </a>
                <div className="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Saanvi Rohan Shet
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                        <br />  The interview process was divided into 3 rounds, 2 technical and 1 HR
                        <br />1st technical round - This round was online, the interviewer checks your resume and asks questions based on the topics you've put down and how well versed you are with the basics of each topic. It was kind of like a rapid fire round where he continuously asked questions and wanted precise answers. My interview was based on questions on DSA, DBMS, CN and one problem solving question. Few of the questions asked were…

                        <br />DSA - What are the real life applications of a stack, queue, array. Difference between circular and normal queue, difference between mutable and immutable objects with examples. Algorithms for DFS, BFS, Prims. What is a MST(Minimum spanning tree), BST and their applications. What is recursion, does recursion use stack. Where are function calls stored in stack. 

                        <br />DBMS - What are entities, primary key, foreign key, SEQUENCE, view. What is the Transaction Control Language - COMMIT, ROLLBACK. 

                        <br />   CN - What are the steps in SDLC, TCP vs UDP, 7 layers of the OSI Model, Data link layer protocol, Latency and throughput. What happens when you type google.com. Working of DNS.

                        <br />Puzzle - He asked the camel and banana puzzle. Link to problem.

                        <br /> <br />   2nd technical round - This interview was conducted offline and primarily focused on my projects. The interviewer asked me to explain my projects in detail and then followed up with cross-questions. The emphasis was largely on the real-world viability of my projects, with the interviewer trying to identify potential loopholes.
                        <br />   It's crucial to be thoroughly familiar with your projects, as this can help you address such questions confidently. In my case, the interviewer didn't focus much on technical specifics but rather on the feasibility of my projects.
                        <br /> <br />  3rd HR round - Basic HR round questions... Why Wells Fargo? Why should we choose you? What are your strengths and weaknesses? Speak confidently and come prepared with a few questions beforehand.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            There was a CGPA criteria of above 7.5 for the test.
                            <br />First round shortlisting was based on test scores.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round?</b>
                        <p>
                            The test was conducted on the AMCAT platform and consisted of:
                            <br />- Verbal Ability & Comprehension Section
                            <br />- Graphical & Statistical Interpretation
                            <br />- 3 Coding Questions (2 easy + 1 tough)
                            <br />All questions were compulsory with no negative marking.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>4. How many students were shortlisted after each round?</b>
                        <p>
                            - 127 students appeared for the online test
                            <br />- 22 students cleared the first round
                            <br />- 11 students cleared the second round
                            <br />- All went to the HR round
                            <br />- Finally, 11 were selected
                        </p>
                    </p>
                    <br />
                    <p>
                        <p>
                            After being shortlisted, how long was the wait time before the interviews began? During this period, what specific areas did you focus on to prepare effectively? 
                            Our interviews were conducted over two days. On the first day, the first round was completed for most candidates. The second and third rounds, along with the remaining first-round interviews for a few students, were conducted on the second day.
                            Before the first round, I primarily referred to the GFG archives and the top 50 interview questions to refresh key concepts. Same before the HR round I looked up some common HR questions and their ideal answers. Of Course you'll need to tailor it based on your experience, but it gives you a rough blueprint and helps reduce some anxiety.
                        </p>
                        <b>5. What topics or skills did the interviewers focus on during the interviews?</b>
                        <p>
                            First Round:
                            <br />- DSA (Stack, Queue, Algorithms)
                            <br />- DBMS (Entities, Keys, Transactions)
                            <br />- Computer Networks (SDLC, Protocols, OSI Model)
                            <br />
                            <br />Second Round:
                            <br />- Detailed Project Discussion
                            <br />
                            <br />HR Round:
                            <br />- Behavioral Questions
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>6. Some good resources for internship preparation?</b>
                        <p>
                            Aptitude Prep:
                            <br />- Striver's SDE Sheet
                            <br />- IndiaBix
                            <br />
                            <br />Interview Prep:
                            <br />- Gate Smashers DBMS Playlist
                            <br />- GeeksforGeeks Archives
                            <br />- GFG Interview Questions
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>7. Tips for cracking coding questions</b>
                        <p>
                            Master the basics like array and string manipulation first.
                            <br />Refer to Striver's A2Z DSA playlist to strengthen concepts.
                            <br />Build confidence by solving easy questions before moving to medium and hard levels.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>Final Tip</b>
                        <p>
                            Be confident while answering questions. If you don't know the answer to a question, don't try to fabricate or fake your way through it, as the interviewer will likely notice. Instead, be honest and say that you're not aware of the answer.
                            Thoroughly review your resume and ensure you are well-prepared to discuss every detail. Include projects you are genuinely confident in and passionate about, as your enthusiasm and understanding will shine through when answering questions about them.
                            The entire process can be extremely hectic and exhausting. Companies often start visiting as soon as the 5th semester starts, so it's advisable to begin preparing during the summer break before the semester starts. Rejections are inevitable and can be disappointing, but resilience is key. Trust that if you've put in the work, the results will follow.
                            Good Luck!
                        </p>
                    </p>
                    <br />

                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:shetsr22.comp@coeptech.ac.in">
                        shetsr22.comp@coeptech.ac.in
                        </a>
                        {/* <br />
                        Phone : 7875869800
                        <br /> */}
                        LinkedIn : <a href="https://www.linkedin.com/in/saanvi-shet-44756325a/">Saanvi Shet</a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SaanviRohanShet;