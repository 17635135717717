import React from "react";

const Omkar_kale = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>Omkar Kale</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>
						NPCI (National Payments Corporation of India)
					</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<p>
							<ul>
								<li>
									The interviewer asked me to introduce myself and elaborate on
									my academic journey. I shared insights into my educational
									background, interests, and experiences, focusing on how they
									shaped my skills and aspirations.{" "}
								</li>
								<li>
									The technical round began with a discussion about the Machine
									Learning project I had mentioned in my resume. The interviewer
									asked detailed questions about the project's implementation,
									the algorithms I used. They also asked conceptual questions on
									ML, which I was able to answer confidently. After this, the
									discussion shifted to my full-stack project, where I explained
									its functionality, how it worked, and some specific
									implementation details.
								</li>
								<li>
									The interviewer then tested my knowledge of core subjects like
									DBMS and Data Structures. They asked me to explain the
									different types of joins in DBMS, the purpose of the HAVING
									clause, and to write a query using the HAVING and GROUP BY
									clauses. In Data Structures, they asked about real-life
									applications of stacks and queues, followed by questions about
									a project in my resume that involved a Trie data structure.
									This included explaining how a Trie works and its use cases.
								</li>
								<li>
									The technical round concluded with a coding problem where I
									was asked to reverse a string. I explained three different
									approaches to solve the problem, highlighting their time and
									space complexities. The interviewer asked me to write the code
									for one of the approaches, which I did successfully.
								</li>
							</ul>
						</p>
					</p>
					<p>
						<br />
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<p>
							Branch - CS
							<br />
							No backlogs
							<br />
							There was no cgpa criteria.
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round ? </b>
						<br />
						<p>
							Aptitude round included questions on Machine Learning concepts and
							coding problems. This round was designed to evaluate both
							theoretical knowledge and practical problem-solving abilities.
							After the aptitude test, 10 students were shortlisted for the
							interview stage based on their performance.
						</p>
					</p>
					<p>
						<b>
							4. After being shortlisted, how long was the wait time before the
							interviews : began? During this period, what specific areas did
							you focus on to prepare effectively?
						</b>
						<br />
						<p>
							There was a gap of around 7-8 days. During this period, I focused
							on revising the core subjects, projects, important DSA questions.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							5. How many rounds were there in the company, can you elaborate
							about each round in brief ?
						</b>
						<br />
						First one was Aptitude test.
						<br />
						Then after shortlisting, we had online interview as a second round.
						In this interview itself, both technical interview and HR interview
						was covered.
					</p>
					<br />
					<p>
						<b>
							6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
						DSA, DBMS, ML(because there was a project in my resume based on it).
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round? </b>
						<br />
						10 after aptitude
						<br />7 after interview
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep? </b>
						<br />
						Striver’s DSA sheet.
					</p>
					<br />
					<p>
						<b> 9. Some tips to crack coding questions ?</b>
						<br />
						<p>Striver's DSA sheet</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							10. How many questions were asked about projects, clubs and
							extracurricular activities in your resume ?
						</b>
						<br />
						<p>
							They asked about projects for atleast 10-15 mins. And clubs and
							extra activities for max 10 mins.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							11. Is there something specific that you would want to tell people
							who try for the same company ?
						</b>
						<br />
						<p>
							Be clear about everything related to your projects and the core
							subjects that are there in your resume.
							<br />
							Practice DSA questions.
						</p>
					</p>
					<br />
					<p>
						<b> Any Final tips ?</b>
						<br />
						<p>Keep solving DSA questions.</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID :{" "}
						<a href="mailto:omkarkale7888@gmail.com">omkarkale7888@gmail.com</a>
						<br />
						{/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default Omkar_kale;
