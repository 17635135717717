export const columns = [
  { id: "rank", label: "Rank", maxWidth: 20,align: "center", },
  // { id: "name", label: "Name", minWidth: 70, align: "left",align: "center", },
  { id: "hr_username", label: "HackerRank\u00a0Username", maxWidth: 50,align: "center", },
  {
    id: "score",
    label: "Score",
    maxWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

export const codequestJr = [
  {
    rank: "1",
    name: "Alhad Deodhar",
    hr_username: "deodhar_alhad",
    score: "133.60",
  },
  {
    rank: "2",
    name: "",
    hr_username: "Aditya84x",
    score: "103.60",
  },
  {
      rank: "3",
      name: "",
      hr_username: "var_kud2005",
      score: "102.35",
    },
    {
      rank: "3",
      name: "",
      hr_username: "shivamhallale",
      score: "102.35",
    },
  {
    rank: "4",
    name: "Mehmood Deshmukh",
    hr_username: "deshmukhmehmood1",
    score: "101.15",
  },
  
  
   {
    rank: "4",
    name: "",
    hr_username: "sreyasudheeran",
    score: "101.15",
  },
 
  {
    rank: "5",
    name: "Hardik Mutha",
    hr_username: "hardikmutha2005",
    score: "95.63",
  },
  {
    rank: "6",
    name: "Sahil Malawde",
    hr_username: "msahil2806",
    score: "94.15",
  },
   {
    rank: "7",
    name: "Yashwant Bhosale",
    hr_username: "yashwantbhosale1",
    score: "86.25",
  },
 
  {
    rank: "7",
    name: "Suswan Joglekar",
    hr_username: "suswan_joglekar",
    score: "86.25",
  },
  {
      rank: "8",
      name: "",
      hr_username: "vaishnavirembale",
      score: "83.35",
    },
    {
      rank: "9",
      name: "",
      hr_username: "aryafirstone1",
      score: "82.15",
    },
    {
      rank: "10",
      name: "Suswan Joglekar",
      hr_username: "shubhrashevkar",
      score: "80.40",
    },
  {
      rank: "11",
      name: "Tanishq Borkar",
      hr_username: "tanishqnborkar",
      score: "80.00",
    },
    {
      rank: "11",
      name: "",
      hr_username: "allenvarghese044",
      score: "80.00",
    },
    
    {
      rank: "12",
      name: "Krushna Bankar",
      hr_username: "krushnabankar79",
      score: "79.25",
    },
    {
      rank: "13",
      name: "",
      hr_username: "vedantmalode23",
      score: "73.00",
    },
    
    {
      rank: "14",
      name: "",
      hr_username: "shreya050805",
      score: "70.85",
    },
    {
      rank: "15",
      name: "",
      hr_username: "tanishqtote_55",
      score: "67.75",
    },
 
];

export const codequestSr = [
  {
    rank: "1",
    name: "Malhar Badve",
    hr_username: "themalharbdv2046",
    score: "101.70",
  },

  {
    rank: "1",
    name: "Shreyash Patil",
    hr_username: "shreyashrpatil11",
    score: "101.70",
  },
  {
    rank: "2",
    name: "Pratham Tandale",
    hr_username: "prathamt12345",
    score: "79.50",
  },
  
  {
    rank: "3",
    name: "Avadhoot Ghewade",
    hr_username: "avadhootsg",
    score: "69.48",
  },
  {
      rank: "4",
      name: "Abhishek Kale",
      hr_username: "kaleabhishek2104",
      score: "67.48",
    },
  {
    rank: "5",
    name: "Amogh Kulkarni",
    hr_username: "kulkarniamogh201",
    score: "66.35",
  },

  {
      rank: "6",
      name: " ",
      hr_username: "kaleos22_comp",
      score: "66.05",
    },
   {
    rank: "7",
    name: "",
    hr_username: "Harshavardhan07",
    score: "64.92",
  },
 
  {
    rank: "8",
    name: "Arbaj Shaikh",
    hr_username: "arbaj_2004",
    score: "56.00",
  },
 
  {
    rank: "9",
    name: "Pratik Kardile",
    hr_username: "pkkardile19",
    score: "50.00",
  },
  {
      rank: "10",
      name: "Dhruva Totatla",
      hr_username: "dhruvavt22_comp",
      score: "44.00",
    },
    {
      rank: "11",
      name: "Avdhut Kamble",
      hr_username: "shreyas3920",
      score: "41.20",
    },
   
    {
      rank: "12",
      name: "Yash Hodlur",
      hr_username: "yashhodlur",
      score: "40.00",
    },
    {
      rank: "12",
      name: "Shravan Mukkawar",
      hr_username: "mukkawarshravan1",
      score: "40.00",
    },
   
    
    {
      rank: "13",
      name: "Pranav Kinge",
      hr_username: "pranavkinge123",
      score: "39.50",
    },
   
    {
      rank: "14",
      name: "",
      hr_username: "user_137",
      score: "29.50",
    },
    {
      rank: "15",
      name: "Sahil Patil",
      hr_username: "sahilpatil1804",
      score: "25.50",
    },
  
];


