import React from "react";

const deep_oak = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Deep Oak
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br /> The interview had two rounds:

                            <br />Technical Round
                            <br />This round was all about testing my knowledge of the subjects I had mentioned in my CV, like DSA, DBMS, OOPs, Computer Networks, and AI. They also asked a lot of questions related to my projects. Most of the questions were practical and made me think about how to apply concepts in real scenarios, which was pretty interesting.
                            <br /> HR Round
                            <br /> The HR round was more about knowing me better as a person. They asked the usual questions like my strengths, weaknesses, extra-curricular activities, and a bit about my family background. There were also some tricky real-life corporate situations where they wanted to know how I’d handle them.

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            <br /> Eligibility for the Aptitude Test: All Computer Engineering students with a CGPA of 8 or above were allowed to appear for the aptitude test.

                            <br />  Shortlisting After the Test: The top 16 candidates who scored the highest marks in the least amount of time were shortlisted for the next round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            <br /> The aptitude round had a total of 11 questions:

                            <br />  3 SQL Queries: These were spread across different SQL languages and tested my understanding of database concepts.
                            <br /> 3 Coding Questions:
                            <br /> One was based on the sliding window technique.
                            <br />  Another involved finding errors in a given code snippet.
                            <br />  The third one was a dynamic programming (DP) problem.
                            <br />  5 Logical Reasoning Questions: These were standard logical questions, testing problem-solving and reasoning skills.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            <br /> The aptitude round was held on 5th December, right in the middle of a hectic period—we had just finished our end-semester written exams, and vivas were still going on. On the morning of 9th December, the company conducted a pre-placement talk (PPT), followed by the announcement of the shortlist. The entire interview process was completed on the same day.

                            <br />  During this short gap, I focused on preparing strategically:

                            <br /> Knowing My CV Inside Out: I made sure I was confident about every point on my CV. I only included things that I was absolutely sure of and could discuss in detail.
                            <br /> Targeted Preparation: I spent time revising the key subjects and projects listed on my CV, ensuring I was ready to answer practical, project-related, and concept-based questions.
                            <br /> Sticking to what I knew well helped me stay confident and prepared.

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Apart from the things mentioned earlier, I would only like to shed some more light about my Technical round. Compared to previous interviews, this was different as they asked some really practical questions ensuring we aren’t limited just to the bookish knowledge. I was asked to draw the ER diagram of my DBMS project, Flow of CN Project, various OOPs paradigms and in depth technical explanation. Questions about different APIs and especially REST  APIs were asked. Then I was also asked real life questions like explain the flow of placing an order on amazon, ER for it, OOPs classes and functions on it. Some questions like explain the interview room as an OOPs class and functions related to it were asked to explain on the paper. Then also some easy to medium DSA questions were asked, then asked for various different approaches to solve it, sorting algos, etc.

                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>

                        <br /> Questions were asked on each of the above mentioned topics apart from OS as I hadn’t mentioned it anywhere in my cv as was more confident about other subjects which were already taught by the end of the 5th semester. They went deep into the APIs and libraries I used in my projects, exploring the practical applications and challenges I faced.

                        <br /> Since I had mentioned Software Engineering as a hobby on my CV, this became a significant area of discussion. I was asked a lot of questions about it, especially during the HR round.

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        After the Aptitude Round: 16 students were shortlisted for the interview process.
                        <br />During the Technical Round: The process varied—some candidates were asked to wait after the technical round, while others were immediately moved on to the HR round.
                        <br /> After the HR Round: Almost everyone who appeared for the HR round ended up getting selected.

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />For DSA:

                        <br /> Striver's DP Series: Great for mastering Dynamic Programming step-by-step.
                        <br /> Leetcode Top 150 DSA Questions: Covers the most frequently asked problems in interviews.
                        <br /> For SQL:

                        <br /> Leetcode’s 50 SQL Queries: Perfect for building a solid understanding of SQL concepts.
                        <br /> For Core Subjects (DBMS, CN, etc.):

                        <br /> Gate Smashers, Jenny’s Lectures, and Abdul Bari: These YouTube channels explain concepts clearly and concisely.
                        <br /> Textbooks Mentioned in the Syllabus: I referred to the prescribed books for Computer Networks and DBMS, which were a reliable source for in-depth knowledge.

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            To crack coding questions, start by thoroughly understanding the problem and planning your approach before coding. Begin with a simple solution and optimize it later, focusing on time complexity and efficient data structures. Practice common problem patterns like dynamic programming, sliding window, and binary search, and always consider edge cases. Finally, write clean, efficient code, and test it thoroughly while explaining your thought process clearly during interviews. Even if you are unsure of the answer, or finding it hard to come up with something, try to be vocal about it and can even change the problem to something simpler first, give that answer, and at times you may also receive hints. They don’t expect you to know all the answers, just want to judge your way of thinking and an out of the box approach gives a lasting impression.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            There were quite a few questions focused on my projects, clubs, and extracurricular activities. Interviewers were particularly interested in my hobbies and the positions of responsibility I held, such as being the Telematches Organizer and the Design and Media Head of SPIC MACAY - Classical Music Club. They also asked about specific incidents from these roles and the key learnings I gained from them, which helped highlight my leadership, problem-solving, and organizational skills.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            When preparing for this interview process, it's important to focus on both your technical skills and your personal attributes. Make sure you’re well-versed in the subjects listed on your resume, especially those related to your projects. Practice coding regularly and be prepared to discuss your projects in-depth—know your work inside and out.

                            <br /> Aside from technical preparation, don't underestimate the importance of confidence and humility. Show your passion for the role and your eagerness to learn, but stay humble about what you know. Communication is key, so make sure you articulate your thought process clearly during technical rounds and be honest when you don’t know something—problem-solving and a positive attitude can go a long way.

                            <br /> Finally, be yourself! The interviewers are not just evaluating your knowledge, but also how well you fit within the company culture.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            This internship journey may be long and challenging, but that’s what prepares you for the real world ahead. Every coding problem, every interview question, and every moment of doubt is shaping you into a stronger, more capable version of yourself. Stay patient, trust the process, and have faith—this is just the beginning of something bigger!


                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:deepoak17@gmail.com">
                            deepoak17@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default deep_oak;
