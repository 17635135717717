import React from "react";

const idris_adeeb = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Idris Adeeb
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br />
                            1)<b> First Round </b> : Online Aptitude Test.
                            <br />
                            2) <b>Second Round </b>: 12-hour Hackathon at Mastercard's Office.

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            <br />
                            Applicable to: Computer, ENTC, and Electrical Engineering students with a minimum 7.5 CGPA and no backlogs.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round consisted of two DSA questions:
                            <br />
                            1) A Medium-Hard question
                            <br />
                            2) A Hard question
                            <br />
                            I solved the first question completely and managed one test case for the second. Other selected candidates solved at least one question completely.
                            <br />
                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            Post shortlisting, we received an email detailing hackathon rules and team assignments (8 members per team, from different colleges).
                            <br />
                            The hackathon was 15 days later, so I focused on honing my frontend skills since most teammates were proficient in MERN stack. I learned Tailwind CSS, improved my general frontend skills, and explored how to integrate Tailwind with Node.js.
                            <br />
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        As it was a Hackathon, there were no interview rounds.
                        <br />
                        Duration: 12 hours, held at Mastercard’s new office.
                        <br />
                        Schedule:
                        <br />
                        Arrived at 6:00 a.m.
                        <br />
                        Problem statements revealed at 8:00 a.m.
                        <br />
                        Work concluded by 5:00 p.m., followed by project presentations.
                        <br />
                        Each team had a mentor for guidance and evaluation.
                        <br />
                        Although my team didn't win, individual performance during the hackathon was assessed for internships. Results were emailed 5 days later.
                        <br />

                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        Take initiative: actively share ideas, lead discussions, and present the project to mentors.
                        <br />Contribute consistently: never remain idle during a hackathon
                        <br />Keep on communicating with your team members and your mentor
                        <br />Be prepared for any questions that can be asked by your mentor regarding the ongoing project and also technical concepts so brush up on your CS fundamentals for it
                        <br />Try to implement new ideas into the project to give it a unique from the rest of the competition

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Applicable to: Computer, ENTC, and Electrical Engineering students with a minimum 7.5 CGPA and no backlogs.
                        <br />After the aptitude test: 25 students shortlisted.
                        <br />After the hackathon: 7 students selected

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br /> For Mastercard: Tailwind CSS - Net Ninja (YouTube)
                        <br />General:
                        <br />DBMS, OS - Love Babbar One-Shot
                        <br />CN - Gate Smashers
                        <br />DSA - Leetcode NeetCode 150
                        <br />Aptitude: IndiaBix and GeeksforGeeks

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        I feel that if you solve the Neetcode 150 questions, you can solve or at least have an idea for how to solve a new coding question when you see it.
                        <br /> Also I tried to solve the daily leetcode question as it helped me learn new concepts
                        <br />Try to solve at least one coding question every day during prep time.

                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            As there was no interview, No questions regarding projects, clubs, and extracurricular activities were asked.
                            <br />Only some questions regarding preferred tech stack were asked and where you can contribute on day to the Hackathon project.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Be prepared for anything. There may be an Interview or there may be a Hackathon. Make sure you know what Mastercard as a Company does and just chill, do not panic. Enjoy the process as it happens
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Start prepping for the Internship process as soon as possible. Many companies will come all year round so don’t be disheartened during this tedious process. Just keep your head up and enjoy the process.

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:adeeb.idris@gmail.com">
                        adeeb.idris@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default idris_adeeb;
