import React from "react";

const Kaustubh_Kharat = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                    Kaustubh Kharat 
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                        1.	Aptitude test 
                        <br/>2.	Technical Round
                        <br/>3.	Managerial Round
                        <br/>4.	HR Round
                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                        I felt that the interviewer focused on the following points: How thorough are you with concepts? How well can you communicate with others?
                        How effectively can you convene your thoughts to form a logical answer?
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        Questions related to Logical Reasoning, 2 Coding questions and some Quantitative Reasoning .
                        </p>

                    </p>
                    {/* <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                        Shortlist was announced few days after aptitude test.(about 3 days later) <br/>
                        I mainly focused on my projects(including all tech stack and functionalities), regarding DSA project I studied about the algorithms and data structure used in it, 
                        get the Time complexity of every function used in my project. Make sure I know the pseudocode of functions. <br/>
                        In DSA, revised all the concepts, sorting algorithms(their Time Complexities) <br/>
                        For last minute doubt gfg is the best resource for OOP and DBMS

                        </p>

                    </p> */}
                    <br />
                    <p>
                        <b> 4. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        1 Aptitude test + 3 Interview Rounds. <br/>
                        Technical Round: The interviewer asked questions based on DSA, DBMS and Networks related to the projects mentioned in my CV. Further questions related to scaling up the project and security were also asked.
                        <br/>Managerial Round: The interview was very meticulous. The interviewer asked Why I had used a certain tech stack and its advantages over the others for all of my projects. Thus, I was compelled to give an in-depth analysis and functioning for the same.
                        <br/>HR Round: The interview was focused on my communication skills and how effectively I can communicate my thoughts with others. The interviewer asked questions like “What steps do you perform to reach your goal?”.
    
                    </p>
                    <br />
                    <p>
                        <b>5. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        -DSA: Data Structures like min heap and applications of Data Structures. <br/>
                        -DBMS: SQL queries to execute certain functions and some details about stored procedures. <br/>
                        -OS: Paging and Memory Management. <br/>
                        -System Design: Concepts like security and privacy like asymmetric key cryptography, SQL injections, buffer overflows and other vulnerabilities like side channel attack etc. <br/>
                        -Networks: Firewall, Null routes, Protocols like HTTP and FTP.

                    </p>
                    <br />
                    <p>
                        <b>6. How many students were shortlisted after each round? </b>
                        <br />
                        After Aptitude Round: 12 students. <br/>
                        After Technical Round: 7 students. <br/>
                        After the Managerial and HR Round, 3 students were selected.

                    </p>
                    <br />
                    <p>
                        <b>7. Some good resources you used in your internship prep? </b>
                        <br />
                        - <a href="https://leetcode.com/">Leet Code</a><br/>
                        - <a href="https://www.learncpp.com/">Learn C++ </a><br/>
                        - <a href="https://www.youtube.com/@NeetCode">NeetCode YouTube Channel </a> <br/>
                        - <a href="https://www.youtube.com/@LowLevelTV">Low Level Learning YouTube Channel </a><br/>
                        - <a href="https://www.youtube.com/@Computerphile">ComputerPhile YouTube Channel</a> <br/>
                        -Striver’s Sheet

                    </p>
                    <br />
                    <p>
                        <b> 8. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Use memoization for DP problems. Practice a lot of greedy and array related problems.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 9. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        Interviews were mostly based on projects. The managerial round asked about how I get along with people and how comfortable I am working with a team. Extracurricular activities were asked in the HR round.
                        </p>
                    </p>
                    <br />
                    {/* <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        
                        </p>
                    </p> */}
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Thoroughly prepare for each and every word mentioned in your CV. Do not limit yourself to concepts related projects but research the paraphernalia and jargons as well. Be confident while giving interviews. Understand that you won’t be able to know everything beforehand so don’t make up facts out of thin air. Always have a smile and an open outlook. 
                        </p>
                    </p>
                    <br />

                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="kharatka22.comp@coeptech.ac.in">
                        kharatka22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
                    </div>
                </div>
            </section >
        </div >
    );
};

export default Kaustubh_Kharat;