import React from "react";

const avadhoot_ghewade = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Avadhoot Ghewade
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Algoquant Fintech</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br />
                            The selection process consisted of the following stages:
                            <br />
                            1.	Aptitude  Round.                   <br />
                            2.	Three Technical Rounds.
                            <br />
                            The entire process was conducted in offline mode.


                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Students with a CGPA of 8.0 or above were shortlisted for the aptitude round.                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round was held immediately after the company's introductory presentation. It consisted of:

                            <br /> 3 Coding Questions (DSA-focused, ranging from above average to hard)
                            <br />1 SQL Question

                            <br />I managed to fully solve 1 coding question and partially solve 2 others
                            (passing 8–10 test cases out of 15 for each). Candidates who solved 2 to 3
                            questions partially were also shortlisted for the next round.

                        </p>

                    </p>
                    {/* <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The test took place on 5th December and the Pre-Placement talk and  Interviews were scheduled on 9th December. The shortlist was announced few minutes before the interviews, so I had already prepared for the interviews after the test without waiting for the shortlist declaration. During this period, I solved few DSA questions on Leetcode just to remain in touch with coding.
                            <br />
                            I read the GFG articles for OOP in Java, and my handwritten notes for Computer Networks and DBMS revision. One good thing was that our end semester exam had just finished so I just needed revision for these subjects.
                            <br />
                            Also I had brushed up on all my projects and also prepared for some questions that were likely to be asked about these projects.
                            <br />
                            My major focus this time was on the HR interview preparation and I had prepared answers for probable questions which the HR might ask.


                        </p>

                    </p> */}
                    <br />
                    <p>
                        <b> 4. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <br />The first technical round was intense, lasting about 1.5 hours. It covered a
                        mix of DSA questions, C++ concepts, and system design problems. Since the role was for a Quant Developer, the interviewer focused heavily on advanced C++ topics such as template metaprogramming, CRTP, and smart pointers. I was also asked to implement a shared pointer. Additionally, I had to design a
                        distributed processing system and discuss potential issues like deadlocks and
                        their solutions.

                        <br />Resources for advanced cpp topics:
                        <br /> https://www.youtube.com/@CodingInterviewPrep
                        <br />https://www.youtube.com/@karaniitgn0908

                        <br />The second technical round was purely focused on problem-solving and lasted 45–50 minutes. I was asked around 8 DSA questions. There were total of 12 DSA questions spread across the first two rounds. ALL of these were directly from LeetCode Hard. About 6–7 questions were from Striver’s DSA Sheet which I was able to solve effectively. From remaining questions, I independently solved 3–4 questions, while others were solved with hints from the interviewer. They were insistent on finding optimal solutions and repeatedly asked if I could further optimize my answers.

                        <br />The third and final round began with a rapid-fire session of 6–7 tricky and brainstorming math questions. I was then asked to prove some of the most obvious looking mathematical expressions in great depth. This maths part was quite challenging. The interviewer later focused on the internal implementation of STL data structures and standard C++ algorithms. I was also questioned on C language fundamentals. The round concluded with a couple of HR questions, such as "Why AlgoQuant?"

                    </p>
                    <br />
                    {/* <p>
                        <b>5. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        C++ (meta-template programming), system design, puzzles, OS.

                    </p> */}
                    <br />
                    <p>
                        <b>5. How many students were shortlisted after each round? </b>
                        <br />
                        Aptitude Round: 9 students were selected.
                        <br />
                        Technical Round 1: 4 students were selected.
                        <br />
                        Technical Round 2: 2 students were selected.
                        <br />
                        Technical Round 3: Both of us were offered internships.


                    </p>
                    <br />
                    {/* <p>
                        <b>7. Some good resources you used in your internship prep? </b>
                        <br />
                        <br />For internship preparation, the TY diary of my senior was incredibly helpful. Here are some other key resources:
                        <br />YouTube Channels:
                        <br />GeeksforGeeks – Good for C++ concepts, smart pointers.
                        <br />CodingInterviewPrep and karaniitgn0908 for advanced C++ and problem-solving.
                        <br />://www.youtube.com/@CodingInterviewPrep
                        <br />://www.youtube.com/@karaniitgn0908



                    </p> */}
                    <br />
                    {/* <p>
                        <b> 8. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            <br />Striver’s DSA Sheet: Follow it for structured practice on essential data structures and algorithms.https://takeuforward.org/strivers-a2z-dsa-course/strivers-a2z-dsa-course-sheet-2/
                            <br />Consistent Practice: Solve problems regularly, starting from easy to hard.
                            <br />Optimize: Always aim for more efficient solutions after brute force.
                            <br />Recognize Patterns: Identify common patterns in problems to solve them faster.
                            <br />Learn from Mistakes: Review optimal solutions and improve your approach.

                        </p>
                    </p> */}
                    <br />
                    <p>
                        <b> 6. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        The interviewers showed no interest in extracurricular activities, clubs, or hackathons. Their focus was solely on the projects implemented in C and C++. In the 2nd technical round I was asked about the status of my coding profiles such as leetcode, codechef, codeforces, gfg, etc.
                        </p>
                    </p>
                    <br />
                    {/* <p>
                        <b> 10. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            For anyone applying to the same company, make sure you understand the role you're applying for and the specific skills required. In the case of a Quant Developer role, be prepared for topics like metaprogramming, low-latency programming, and advanced C++ concepts.
                            Familiarize yourself with the company's core focus areas to tailor your preparation effectively.
                        </p>
                    </p> */}
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Focus on mastering the fundamentals of data structures and algorithms, as they form the core of most technical interviews. Practice extensively on platforms like LeetCode and refer to curated resources like Striver's DSA Sheet for targeted preparation. Additionally, don’t just memorize solutions—work on optimizing your problem-solving approach. Lastly, stay calm during the process, be honest about what you know, and believe in yourself.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:ghewadeas22.comp@coeptech.ac.in">
                            ghewadeas22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default avadhoot_ghewade;
