import React from "react";

const tanuja_patil = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Tanuja Patil</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>Structure of Entire Interview Process:</b>
            <p>
              There were 3 rounds:
              <ol>
                <li>
                  <b>Technical Round (35-45 minutes):</b>
                  
                    Initially, I was asked questions based on my project like what tech tasks I used, why I chose a particular topic for the project, etc. I contributed mostly in frontend so I was asked to explain the full view of one of my websites. Based on my dsa project which was on the linked list many questions were asked. In the case of DBMS, concepts were asked related to my project (normalization, entities, attributes, etc). Few logical questions were also asked. Then for DSA, asked me to give visualized solution of a particular question.
                    
                </li>
                <li>
                  <b>Professional Fitness Round (15-20 minutes):</b>
                  
                    <li>
                    This was a mixture of technical and HR rounds. I was given various situations and had to answer them.Few questions about my project again. I was head of MindSpark, so few questions were asked related to my work there. General questions like why DB, where do you see yourself in next 5 years etc.</li>
    
                  
                </li>
                <li>
                  <b>HR Round (10 minutes):</b>
                  <ul>
                    <li>
                    I was asked to introduce again and questions related to clubs and my responsibilities. 
                    </li>
                  </ul>
                </li>
              </ol>
            </p>
          </p>
          <br />

          <p>
            <b>Structure of Aptitude Round:</b>
            <p>
            Aptitude round was online(90 minutes). MCQ section ( OOPS, DBMS, code snippets in JAVA) + Coding round (2 questions) 
            </p>
          </p>
          <br />

          <p>
            <b>How many students were shortlisted after each round?</b>
            <p>
              Around 30 students were shortlisted for the interview process.
              Out of these, 11 were selected for the summer internship.
            </p>
          </p>
          <br />

          <p>
            <b>
              After being shortlisted, how long was the wait time before the
              interviews began? During this period, what specific areas did you
              focus on to prepare effectively?
            </b>
            <p>
              The interviews started immediately after the Pre-Placement Talk
              (PPT). Students were called serially, and during the wait time, I
              revised OOPS and DBMS-related concepts.
            </p>
          </p>
          <br />

          <p>
            <b>
              What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <p>DSA, OOPS, DBMS, puzzles, and project-based questions.</p>
          </p>
          <br />

          <p>
            <b>
              How many questions were asked about projects, clubs, and
              extracurricular activities in your resume?
            </b>
            <p>
            Many questions were asked about projects in technical as well as professional fitness rounds. About clubs, they were asked in the HR round mostly.


            </p>
          </p>
          <br />

          <p>
            <b>
              Final Tips for Juniors:
            </b>
            <ul>
              <li>Be confident in explaining your work.</li>
              <li>Prepare for behavioral questions.</li>
              <li>
                Clear your concepts in OOPS, DBMS, and DSA. Focus on both theory
                and practical implementation.
              </li>
              <li>
                Stay calm even if you don’t know an answer. Don’t look at it like your viva.


              </li>
            </ul>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:{" "}
            <a href="mailto:patilts22.comp@coeptech.ac.in">
              patilts22.comp@coeptech.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default tanuja_patil;
