import React from "react";

const dhruv_sadhale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Dhruv Sadhale
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Hilti</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>
                        
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br />
                            1. Aptitude test round
                            <br />2. One Technical Interview
                            <br />3. One HR Interview

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            For the interview rounds there was neither any branch criteria nor any CGPA criteria.
                            The selection was done purely on the basis of the aptitude test.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round consisted of:
                            <br />1. One coding question
                            <br />2. General aptitude based questions
                            <br />3. Questions on DBMS, CN and OS
                            <br /><br />Everyone got one out of two fairly straightforward coding questions. The one I solved was based on Arrays.
                            <br /> I was given a sequence of already executed Alt+Tab key press window shifts and I had to find out which minimized window would be seen after a given number of key presses.
                            <br /> In other words, you can imagine it to be a circular queue based approach.

                            <br /><br />General aptitude based questions involved problems on simple and compound interest, work and time, logical reasoning based on images of dice faces, probability, reflections and similar variations.

                            <br /><br />Compared to other companies’ aptitude rounds, the questions on DBMS and CN were in-depth which probably was a differentiating factor.
                            <br /> They involved relational algebra questions and questions on various application layer protocols such as SMTP from computer networks.
                            <br /> It is advised to prepare these topics thoroughly before giving any aptitude test.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The aptitude test took place on a Sunday and the list of the shortlisted students was released on Tuesday.
                            <br /> The interviews took place on Wednesday.
                            <br />Due to the limited time in between the rounds, I primarily relied on the preparation I had done during the summer break.
                            <br /> In the interim period, I polished my speaking skills which helped me in the HR round later. I didn’t give any specific attention to the technical interview preparation since I was confident about what I needed to say when asked regarding my projects.


                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        1. Aptitude test round
                        2. One Technical Interview
                        3. One HR Interview


                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        The technical interviewer focused primarily on the projects and skills mentioned on my resume, current affairs in technology and Java based OOP questions. I was asked a question on memory management in member methods and member variables of classes in Java.
                        <br />The HR interview consisted of questions regarding my strengths, weaknesses and my short term goals in the corporate world. The HR interview played a decisive role in my selection because the interviewer too looked pleased with the way I answered all questions in my 45 minute long interview. Although I gave all the textbook answers to all the textbook questions such as ‘Introduce yourself’, I kept my responses prompt and concise.
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Around 25 students were shortlisted after the Aptitude test round.
                        <br />Separate technical and HR rounds took place for almost every shortlisted candidate, barring a few. Ultimately, 4 students were selected.
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        For DSA preparation, I solved LeetCode and GeeksForGeeks.
                         I also used Strivers A2Z DSA sheet for quick topic recaps. I watched one shot youtube videos of CodeHelp- by Babbar for DBMS and OS.
                        <br />
                        They are pretty sufficient to get an intuition about those subjects.
                        <br />
                        I referred to GFG articles on DBMS and CN too. 
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Expose yourself to hard level questions as much as possible. As soon as you finish reading the question, think about the topic to which the question belongs.
                        <br /> Once you have finalized the topic whose method you need to use, just go forward with it without stressing upon the time complexity.
                        <br />Focus on the edge cases because a lot of test cases are passed even if you achieve decent time complexity on the edge cases. 
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        Majority of the questions were asked on projects mentioned in my resume. No questions were asked about clubs and extracurricular activities.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        <br />It is always recommended to surf the internet and read about what the company’s primary objectives are before you sit for interviews.
                        <br /> Having a brief overview about the company’s operations does prove to be a highlight in HR interviews. Whenever the interviewers ask whether you have any questions for them, always ask a question regarding the company’s technical stack requirement for the role.
                        <br />This will not only give you an idea of what is yet to come but it will also give the interviewers an insight into your thought process about learning new programming languages.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Focus on quality over quantity. Conceptually dense and high quality projects matter, the length of your resume doesn’t.
                        <br />Remember to never exaggerate the response to the question to introduce yourself.
                        <br />Body language, discipline and consistency always fetch you results in the long run.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:dhruv.sadhale@gmail.com">
                        dhruv.sadhale@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default dhruv_sadhale;
