import React from "react";

const Yash = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        className="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div className="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Yash Pardeshi
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br />There were 2 rounds in the entire interview process,
                            <br /> Technical Round
                            <br /> HR Round

                        </p>
                    </div>
                    <br />
                    <div>
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            <br /> The shortlisting for interviews was based on the test scores.
                            <br /> Clearing the first interview round is based on how you display your technical skills but the final decision is on the panel.
                            <br /> Clearing the HR round is all about convincing the interviewer about your compatibility to the company.

                        </p>
                    </div>
                    <br />
                    <div>
                        <b>3. What was the structure of the Aptitude Round?</b>
                        <p>
                            <br />There were 2 portfolios to choose from,
                            Institutional Clients Group (ICG) Tech
                            Personal Banking Wealth Management (PBWM) Tech

                            <br />  The test for PBWM was aptitude based.
                            <br /> The ICG Tech test had 4 sections :
                            <br /> 1. Coding - 2 questions in 45 minutes
                            <br /> 2. SQL - 12 questions in 15 minutes
                            <br /> 3. ReactJS - 12 questions in 15 minutes
                            <br /> 4. Angular - 12 questions in 15 minutes

                            <br /> Both the tests were conducted on SHL platform.

                        </p>
                    </div>
                    <br />
                    <div>
                        <b>4. After being shortlisted, how long was the wait time before the interviews began?</b>
                        <p>
                            <br /> The list of shortlisted candidates for the interviews was released 4 days after the test and 1 day prior to the interviews.
                            <br /> After being shortlisted, I focused on how Java works, OOPS in Java, searching & sorting algorithms, DSA. Then I went through the projects listed on my resume in detail. Further, I revised DBMS concepts and practiced basic SQL queries.
                            <br />  Prior to the interview, I prepared a proper introduction for the technical round. The panel focused on the topics I mentioned in my intro.

                        </p>
                    </div>
                    <br />
                    <div>
                        <b>5. How many rounds were there in the company? Can you elaborate about each round in brief?</b>
                        <p>
                            <br /> <b>1. Technical Round </b>
                            <br /> There were 2 interviewers in the panel.
                            The technical interview begins with an introduction where I covered the skills I am confident in and the ones I used in my projects. Then I was asked to explain about my latest project. After explaining the functionalities and technologies present, they started questioning about the structure of the project and further into the backend. I was told to explain how the database (Firebase) would be modified upon altering the project structure. It was linked to scalability & hierarchy which relates to the large CITI corporation. Then they asked me to explain Bubble Sort on paper followed by the time complexities of all sorting algorithms. The next few questions were based on the differences in Python, C and Java as I mentioned all three in my intro. The interview concluded with 2 non - technical questions, where do you see the industry in 5 years and where do you see yourself in 5 years.

                            <br /> <b>2. HR Round </b>
                            <br />There was 1 interviewer in the panel.
                            He was quite friendly which made the environment stress free. I was asked about how the process had been until now and how I was feeling about it. He proceeded to get info about my family background. Then he asked me why I chose engineering. The main question next was why do I want to join a bank. After my answer, he explained about CITI’s work and made a few points like how risky the job really is. This led him to the next question, “Why CITI and not a product based company like Adobe ?”. Considering how interactive he was, it was more of a discussion rather than an interview. The whole point of the interview was to convince him why I am a suitable candidate.


                        </p>
                    </div>
                    <br />
                    {/* <div>
                        <b>6. What topics or skills did the interviewers focus on during the interviews?</b>
                        <ul>
                            <li>Data Structures and Algorithms (DSA)</li>
                            <li>Puzzles</li>
                            <li>Problem-solving approaches</li>
                            <li>Basic programming concepts (C, recursion, linked lists, arrays)</li>
                            <li>Behavioral questions in the HR round</li>
                        </ul>
                    </div> */}
                    <br />
                    <div>
                        <br /> <b>6. How many students were shortlisted after each round?</b>
                        <br /> From the test, 32 people were selected for ICG Tech and 12 people were selected for PBWM.
                        <br /> Around 12 students were sent for the ICG Tech HR round.
                        <br /> Finally, they decided to offer internships to 3 PBWM students and 9 ICG Tech students.

                    </div>
                    <br />
                    {/* <div>
                        <b>8. Some good resources you used in your internship prep?</b>
                        <ul>
                            <li>DSA Preparation: Kunal Kushwaha’s YouTube channel (it's for JAVA but concepts are explained in detail)</li>
                            <li>Linux Commands: Kunal Kushwaha’s tutorials</li>
                            <li>DBMS: Jenny’s Lectures, Gate Smasher</li>
                            <li>Computer Networks: Gate Smasher</li>
                        </ul>
                    </div> */}
                    <br />
                    <div>
                        <b>7. Some tips to crack coding questions?</b>
                        <p>
                        <br />  For any question, read the complete question (don’t try to quick read, you may end up missing a point). After understanding the problem, decide on the approach and then brainstorm to find the solution.
                        <br /> To make it better, you need to be able to segregate the questions into categories (greedy, dp, etc.) so you know which approach to use.
                        <br /> I would suggest having a good command on any one language so that you can turn your solution into code quicker than others. (Time matters)

                        </p>
                    </div>
                    <br />
                    <div>
                        <b>8. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
                        <p>
                        Many questions were asked on the project I explained. They were based on the structure and database of the project. Other than that, some of them were regarding the skills on my resume.

                        </p>
                    </div>
                    <br />
                    <div>
                        <b>9. Is there something specific that you would want to tell people who try for the same company?</b>
                        Prepare your resume perfectly, you should know your projects inside out. Focus on the basics of DSA & OOPS, concepts that matter regardless of your projects. For banks, you should always know DBMS, mainly SQL queries.

                    </div>
                    <br />
                    <div>
                        <b>Any Final tips?</b>
                        <br /> This is just the beginning of a journey, don’t let it hinder your goals.

                    </div>
                    <br /> <br /> 
                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID: <a href="mailto:pardeshiyk22.comp@coeptech.ac.in">pardeshiyk22.comp@coeptech.ac.in</a>
                        <br />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Yash;
