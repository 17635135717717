import React from "react";

const shraddha_darade = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						className="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div className="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Shraddha R. Darade
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>
						Wells Fargo
					</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<br />
						<p>
						The interview process began with an Aptitude Test, conducted at the college on 7th August. After the test, shortlisted candidates advanced to the interview stage, which consisted of three rounds:<br />
1.	First Round: Technical 1  (10th Aug)<br />
2.	Second Round: Technical 2 Round (11th Aug)<br />
3.	Third Round: HR/Behavioral Interview (11th Aug)<br />
						</p>
					</p>
					<br />
					<p>
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<br />
						<p>
						The criteria for shortlisting candidates in the initial stages of the recruitment process included eligibility for students from specific branches: Computer Engineering, ENTC, Electrical, and Instrumentation. Additionally, candidates were required to have a CGPA of 7.5 or above 
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round?</b>
						<br />
						<p>
						The online Aptitude Test had three sections:<br />
1.	English Proficiency: Synonyms/antonyms, grammar errors, sentence completion, and 3 comprehension passages with 4-5 questions each.<br />
2.	Business Analytics: Questions on candlestick graphs and stock price prediction.<br />
3.	Coding: Three questions—two easy and one medium (for me). The third question varied, with some getting a medium-level and others a hard one.<br />
						</p>
					</p>
					<br />
					<p>
						<b>4. After being shortlisted, how long was the wait time before the interviews began? During this period, what specific areas did you focus on to prepare effectively?</b>
						<br />
						<p>
						The list of shortlisted students was announced on 9th August, and the interviews began on 10th August, leaving very little time to prepare for the interview. I quickly brushed up on DSA, core subjects, and reviewed my projects.
						</p>
					</p>
					<br />
					<p>
						<b>
							5. How many rounds were there in the company, and can you elaborate
							about each round in brief?
						</b>
						<br />
						<p>
							<b>First Round:</b>
							<br />
							- The first round was fully technical and conducted online from the college. Multiple panels were involved, so questions varied for each candidate. My interview began with an introduction, followed by questions about my projects, including why specific technologies were chosen. I was then given two DSA questions on linked lists, both of which I solved. The interviewer also asked about OOPS concepts and differences between Java (my preferred language) and C++. This lasted for about 30-35 min.
							<br />
							<b>Second Round: Technical 2 Round</b>
							<br />
							- This round focused on both technical and behavioural aspects, and was totally resume based. The interviewer asked about my projects, the tech involved in it, why I chose Java, and gave me a DSA question on a 2D array to solve and dry run. They also inquired about my family background, goals, and leadership experiences. Behavioural questions included how I would contribute to Wells Fargo and what I bring to the company . The interview concluded after a series of resume-based and situational questions.This lasted for about 45-50 min.
							<br />
							<b>Last Round: HR Round</b>
							<br />
							- The final round focused entirely on behavioural questions. I was asked about my greatest achievement, my support system, and other similar questions to understand my personality and values. This lasted for about 20 min.
						</p>
					</p>
					<br />
					<p>
						<b>6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)</b>
						<br />
						<p>
						The interviewers primarily focused on DSA, projects, and core subjects like DBMS, along with assessing behavioural aspects.
						</p>
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round?</b>
						<br />
						<p>
							<b>Aptitude Round:</b> 150 students shortlisted <br />
							<b>After Technical 1</b> 22 students selected for interviews, 13 progressed to Round 2 and 3<br />
							<b>Final Selection</b> 11 students received internship offers.
						</p>
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep?</b>
						<br />
						<p>
						1.Striver's SDE 450 Sheet
							<br />
							2. Love Babbar's Youtube channel for dsa prep
							<br />
3. Apna College for fundamentals
<br />
4. LeetCode and GeeksforGeeks: For extensive question practice
<br />
						</p>
					</p>
					<br />
					<p>
						<b>9. Some tips to crack coding questions?</b>
						<br />
						<p>
						Consistent practice is the key to mastering coding questions. Make it a habit to solve questions daily from platforms like LeetCode and GeeksforGeeks. Focus on understanding the concepts thoroughly before attempting problems, as this will help you approach them with confidence. Remember, the more you practice, the better you’ll get. Stay dedicated, and you’ll see great results!
						</p>
					</p>
					<br />
					<p>
						<b>10. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
						<br />
						<p>
						I was asked many questions about the project mentioned in my resume, as well as a few questions about extracurricular activities.
						</p>
					</p>
					<br />
					<p>
						<b>11. Is there something specific that you would want to tell people who try for the same company?</b>
						<br />
						<p>
							--
						</p>
					</p>
					<br />
					<p>
						<b>Any Final Tips?</b>
						<br />
						<p>
							<i>
							Stay consistent with your preparation, focus on understanding concepts, and practice coding questions regularly. Highlight your projects and experiences confidently in interviews, and always be honest and clear in your responses. Remember, preparation and perseverance are the keys to success. Keep pushing forward, and you'll achieve your goals!
							</i>
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID:{" "}
						<a href="mailto:daradesr22.comp@coeptech.ac.in">
						daradesr22.comp@coeptech.ac.in
						</a>
						<br />
					</div>
					{/* <div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Phone Number: 8767973352
						<br />
					</div> */}
				</div>
			</section>
		</div>
	);
};

export default shraddha_darade;