import React from "react";

const arnav_prasad = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Arnav Prasad
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>UBS</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br />Round 1: Aptitude Test
                            <br />-	Mode: Offline
                            <br />-	Location: Cognizant Lab
                            <br /> Round 2: Technical Interview
                            <br /> -	Mode: Offline
                            <br /> -	Location: Main Building, COEP
                            <br /> Round 3: HR Interview
                            <br /> -	Mode: Offline
                            <br /> -	Location: Main Building, COEP



                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Eligibility Criteria for the Aptitude Test: Candidates with a CGPA above 7.0 were eligible to appear for the test.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            <br />Platform: Conducted on HackerRank
                            <br />Structure:
                            <br /> -	Section (i): Multiple Choice Questions (MCQs)
                            <br /> -	Section (ii): 1 Coding Question
                            <br /> All questions were compulsory. No negative marking.
                            <br /> Topics Covered:
                            <br /> -	Object-Oriented Programming (OOPs)
                            <br /> -	Computer Networks
                            <br /> -	Operating Systems (OS)
                            <br /> -	Database Management Systems (DBMS)
                            <br />-     Quant-based questions and Puzzles
                            <br />Coding Question:
                            <br />-	The coding question was easy and could be solved in any language (e.g., Python, C++, Java, etc.).
                            <br />-	Question Description:
                            <br /> 	Given a list of IP addresses in the format xx.xx.xx.xx, check for valid and invalid IP addresses.
                            <br /> 	Logic: Verify that each segment (xx) meets the constraint 0 (less than = ) xx (less than =) 255.
                            <br /> 	Prerequisite: Understanding the structure of an IP address.
                            <br /> Different candidates were assigned different coding questions. The one provided above is the specific question I received.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The Aptitude Round was conducted on 29th July, and the shortlist was announced later that same night.
                            The interviews were scheduled for the following day, 30th July.
                            During the brief preparation period, I focused on my projects to ensure I could explain them effectively. Fundamental core topics, especially OOPs.


                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Round-1: Technical Interview
                        <br />The technical interview focused on testing basic concepts, problem-solving skills, and understanding of projects.
                        <br /> Structure:
                        <br />Introduction: The interview began with a brief introduction.
                        <br />Project Discussion: I chose to explain my DSA project. The interviewers delved deep into the project, asking multiple questions to gauge my understanding and problem-solving approach.
                        <br />Technical Questions: Graph Theory: Questions on Prim’s, Dijkstra’s Algorithm, Minimum Spanning Tree, etc.
                        <br />OOPs Concepts:A major focus for all candidates, regardless of their preferred programming language. I had 2 interviewers specialized in C++ and Java, testing my understanding of core OOP concepts(Inheritance, Code Reusability, Polymorphism,etc)
                        <br />Discussion on Software Engineering Principles: While discussing my DSA project, I mentioned that I wrote almost every function myself. This led to an interesting 10-minute discussion on software engineering principles like code reusability and whether it aligns with my approach.
                        <br />The interview lasted for more than an hour.
                        <br />Although I wasn't able to answer all the questions, my curiosity, questions, and problem-solving mindset impressed the interviewers.
                        <br />Questions Asked to Other Candidates:
                        <br />-	Flask: What is Jinja in Flask?
                        <br />-	SQL: Types of joins and writing queries.
                        <br />-	Assembly Language: A candidate was even asked to write code in assembly language.
                        <br />The questions varied widely and were tailored to individual resumes and projects .

                        <br /><br />Round-2: HR Interview
                        <br />The HR interview was more informal and varied in nature.
                        <br />The HR interview was short since my technical interview covered most aspects about me.I was asked to Introduce myself.
                        <br />Later they explained how UBS works!
                        <br />Questions asked to other Candidates:
                        <br /> -	sing a song.
                        <br /> -	sketch something.
                        <br />-	Sharing family background, etc

                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>

                        <br />
                        Core Topics:
                        <br />
                        -	DSA (Data Structures and Algorithms): Topics: Graph Theory (Prim’s, Dijkstra’s), Linked List, Stack, Queue, etc.
                        <br />
                        -	OOPs (Object-Oriented Programming): The most important area for my interview. In-depth questions were asked to test a solid understanding of OOP principles, regardless of the programming language used.
                        <br />
                        Project Knowledge: Be thoroughly prepared with your projects, as they help interviewers evaluate the practical application of your knowledge. Expect detailed questions about your contributions and the challenges you faced.
                        <br />
                        Other Topics:
                        <br />
                        -	Computer Networks: Only a few questions were asked.
                        <br />
                        -	Logical/Reasoning: Some logical questions were included to assess problem-solving skills.


                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Eligibility Criteria for the Aptitude Test: Candidates with a CGPA above 7.0 were eligible to appear for the test.
                        <br />Shortlisting After the Aptitude Test: Based on test scores, 30 students were shortlisted.
                        <br />Technical and HR Interview Process: Candidates who cleared the Technical Interview were sent for the HR Interview.
                        <br />Final Selection: 5 students were selected for the internship.

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        OOPs: NPTEL Course: For understanding core concepts.
                        <br />
                        Computer Networks: Book: A Top-Down Approach: Computer Networking.
                        <br />
                        Logical/Reasoning: IndiaBix: online platform for logical questions.

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            <br />Practice a variety of problems;
                            <br />I prefer Striver's A2Z DSA playlist
                            <br />(try to solve 2-3 leetcode questions daily)


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            <br /> Projects: A significant portion of my technical interview (around 20-25 minutes) focused on my projects. The interviewers asked detailed questions to evaluate my understanding, contributions, and problem-solving abilities.
                            <br />Clubs and Extracurricular Activities: Although I mentioned my club details and extracurricular activities during my introduction, I wasn’t asked any follow-up questions on them.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Master OOPs Concepts: Confidence in OOPs principles is crucial as it is given significant weightage in the interview process.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The internship process can be tiring, hectic, and sometimes disheartening. However, remember: Rejections are a part of the journey, and opportunities are abundant. Stay resilient—if you’ve prepared well, results will follow.

                            <br />Be Prepared: Companies often start visiting as soon as the 5th semester begins. Use your summer break to start preparing, focusing on key technical concepts and practicing coding regularly.

                            <br />Be Honest and Confident:If you don’t know the answer to a question, don’t fake it. Instead, admit that you’re not aware and express willingness to learn. Confidence and authenticity often leave a positive impression.

                            <br />Don’t Fear Competition: Most companies prioritize finding the right fit over selecting a specific number of candidates. For example, UBS planned to hire 10 candidates but selected only 5.

                            <br />Acknowledge Luck and Uncertainty: Factors like the interviewer’s domain knowledge, aptitude test surrounding disturbances, wifi/LAN issues and other external conditions can influence outcomes. Focus on what’s in your control — preparation, clarity, and confidence.

                            <br />Good Luck!!



                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:arnavp22.comp@coeptech.ac.in">
                            arnavp22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default arnav_prasad;
