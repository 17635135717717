export const editorial_2024 = [
    {
        sy : [
            {
                problem_name: "COEP Debate Club, Debsoc",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/coep-debate-club-debsoc",
            },
            {
                problem_name: "BoltBot and TallyTron",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/boltbot-and-tallytron",
            },
            {
            problem_name: "Festival of Traders",
            problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/festival-of-traders",
            },
            {
                problem_name: "Akash's CakeQuest",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/akashs-cakequest",
                },
                {
                    problem_name: "Sam the Pro Gamer",
                    problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-junior/challenges/sam-the-pro-gamer",
                    },
        ],
        
        ty : [
            {
                problem_name: "Boat Club Feast",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/boat-club-feast",
            },
            {
                problem_name: "Amanda the Fisher Girl",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/amanda-the-fisher-girl",
            },
            {
            problem_name: "Battle of the Bots",
            problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/battle-of-the-bots",
            },
            {
                problem_name: "The great divide",
                problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/the-great-divide-coep",
            },
            {
            problem_name: "Goku's Algorythmia Expedition",
            problem_statement: "https://www.hackerrank.com/contests/codequest-5-0-sr-ty/challenges/gokus-algorythmia-expedition",
            },
        ],
        
    },
]

export default editorial_2024; 