import React from "react";

const prajwal_bhosale = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Prajwal Bhosale
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            The interviews took place in two rounds :
                            <br />1) Technical Round : 
                            <br />3-4 DSA Coding questions, OOP questions, Project related questions, DBMS theory questions and queries.
                            <br />2) HR round :
                            <br />Questions based on extracurricular activities mentioned in my resume, corporate life based situational questions, basic questions on family background, etc.


                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Students above 8 cgpa were shortlisted for the test. Selection for the interviews was based on the test scores.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round consisted of logical and mathematical ability questions, 3 Database queries, and 3 Coding questions.
                            <br />Two of the three coding questions were easy and I could pass all the test cases in them. The third coding question was difficult and involved Dynamic Programming. I could pass 8/15 test cases for this question as the rest of the test cases were giving “Time Limit Exceeded” error.
                            <br/> The database queries were of easy to moderate difficulty and I could solve all of them completely.
                            <br />The key was to solve the maximum number of questions in the minimum possible time.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews 
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The test took place on 5th December and the Pre-Placement talk and  Interviews were scheduled on 9th December. The shortlist was announced few minutes before the interviews, so I had already prepared for the interviews after the test without waiting for the shortlist declaration. During this period, I solved few DSA questions on Leetcode just to remain in touch with coding.
                            <br />
                            I read the GFG articles for OOP in Java, and my handwritten notes for Computer Networks and DBMS revision. One good thing was that our end semester exam had just finished so I just needed revision for these subjects.
                            <br />
                            Also I had brushed up on all my projects and also prepared for some questions that were likely to be asked about these projects.
                            <br />
                            My major focus this time was on the HR interview preparation and I had prepared answers for probable questions which the HR might ask.


                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were total 3 rounds :
                        <br /><br />1)	Online Test (Logical and mathematical questions + 3 Database queries + 3 Coding questions). 
                        <br />First coding question : It was similar to the question where we have to find peak elements in an array.
                        <br />Second coding question : In this question a string was given (which resembled a credit card number) and we had to check if the string is a valid credit card number.
                        For this question they had given 3 constraints for the String : 
                        <br />1) It should be of 16 digits.
                        <br />2) It should contain only digits from 0-9.
                        <br />3) It should pass the Luhn Algorithm (It was explained in the question).
                        <br />Third coding question was a more complex version of the "Filling Bookcase Shelves" question on Leetcode(Question number 1105).
                        <br /><br />2)	Technical Interview: This round started with my Introduction. Then the interviewer gave me 3-4 DSA coding questions. The questions were based on : Strings, Bit manipulation, Linked lists, PriorityQueues.
                        <br />I could solve all of them. Then the interviewer proceeded by asking OOP concepts (specifically for Java as I had mentioned it is as my primary language). The OOP questions were related to : the four pillars of OOP with real life examples and Java code, difference between abstract class and interfaces in Java,  difference between Overloading and Overriding again to be explained with a Java code snippet.
                        <br />Then he asked me about my Machine Learning project which was a face recognition based attendance system. After I explained to him the project, he asked me about the dataset size and the model used for training. He then asked me about my DSA project which was based on Knowledge graphs, and then questioned me about my choice for the data structures used in the project. Then I was asked to demonstrate the working of heapsort algorithm and AVL trees by writing their codes as these data structures(Heaps and AVL trees) were used in my project.
                        <br />Then he asked me few theory questions about DBMS like : “what are ACID properties, explain them with example”, “what is normalisation and why do we use it?” , “show me how to normalise a schema from 1nf to bcnf with an example”.Then he asked me to make the ER model and schema for BookmyShow website, asked me to normalise it and then asked me to write 3-4 queries on the schema which I had made.
                        <br />Lastly, he asked me few basic questions on Git and GitHub.
                        <br />I was able to answer all the questions and the interviewer looked satisfied with my answers.
                        <br />At the end, I asked a couple of questions to the interviewer about the projects which the previous year interns had worked on at Pattern Technologies. This round lasted for approximately 50 minutes to 1 Hour.
                        <br /><br />Key note : Provide examples with code wherever you can even if the interviewer has not asked for them.

                        <br /><br />3)	HR interview : I was called for the HR round 5 minutes after my technical round ended. This round also began with my introduction and then the interviewer’s introduction. Then she asked me about my family background. She then asked me about the soft skills workshop which I had mentioned in my resume and about the outcomes of the workshop.
                        <br />Then she asked me to tell something about myself which is not mentioned in my resume. She also gave me a situational question based on corporate life : “what will you do if you are working on a project and your manager asks you to do it in a particular way but you have another approach which you think will be better than your manager’s approach?”.
                        <br />The Key to answering such questions is to follow the company’s principles and values which are mentioned in the Pre-Placement talk and answer the questions accordingly.
                        <br />Also she asked me about my role and responsibilities as the Technical Head at CSI COEP which I had mentioned in my resume.
                        <br/> I asked 2-3 questions about the company to the interviewer at the end.
                        <br />The interviewer also offered me a Samosa :) before ending the interview. This round lasted for about 25-30 minutes.
                        <br /><br />Key note : Maintain your body language and communicate politely but confidently. Answer questions adhering to the company’s values.


                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>

                        <br />DSA : This is one of the most important topics which you should be very thorough with. Know all the Data structures used in your projects very well and understand their working. Solving questions is the best way to master this subject.
                        <br />DBMS : Do the course seriously and sincerely in academics, it will greatly help you during the interviews. You should be able to make schemas as well as write efficient queries.
                        <br />Behavioral Questions : These questions are also very important in the HR round so make sure you prepare answers to some probable questions and also try to think what all the interviewer can ask you from your resume.

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Initially the students above 8 cgpa were shortlisted for the test.
                        <br />After the test, 16 students were shortlisted for technical interviews.
                        <br />5 students were shortlisted for HR interview, and eventually 4 were selected for the Internship.

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        Leetcode and GeeksforGeeks : For Coding practice.
                        <br />
                        GeeksforGeeks Articles and last minute notes : For OOP concepts.
                        <br />
                        Kushwaha’s OOP playlist.
                        <br />
                        Apna College’s Java playlist for learning Java.
                        <br />
                        Striver’s youtube videos for understanding Dynamic Programming concepts.
                        <br />
                        Codehelp by Babbar’s DBMS and OS playlist. His DBMS questions are very important.
                        <br />
                        Database System Concepts Textbook which our college uses for the curriculum.
                        <br />
                        Computer Networks - A top down approach : for CN preparation.

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Understand the data structures really well, solve topicwise questions on Leetcode and GFG consistently. While solving questions, initially solve it using brute force approach and then try to optimise the solution. If you are stuck on a question in the test or interview, visualise the process flow of the question and try to make a brute force approach for it using the appropriate data structures.
                            <br />
                            Once you have the brute force solution, try to optimise it further by thinking again over your choice of data structures and algorithms.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Many questions were asked about the projects in the technical round. Make sure that you do good projects and spend a good amount of time doing the projects.
                            <br /> Also having projects from various domains in your resume helps. Do not exaggerate about your skills and projects in your resume.
                            <br />Questions based on clubs and extracurricular activities were asked only in the HR interview.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Focus mainly on DSA and DBMS concepts for this company. While answering questions, provide examples wherever necessary even if the interviewer has not asked for them.
                            <br />Be confident during the HR interview as your body language and communication skills matter during the HR round. Also accept suggestions and criticism in a positive manner during the interviews.
                            <br />Know the company’s values and ethics and try to reflect upon them while answering the HR interview questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            The Interview process can be very exhausting and sometimes also depends majorly on  luck!! Make sure that you do not get demotivated and discouraged by failures.
                            <br />If you have taken efforts, you will definitely get fruitful outcomes. Keep working sincerely and capitalize on opportunities at the right time!!
                            <br />All the best !!


                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:pjlbhosale@gmail.com">
                            pjlbhosale@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default prajwal_bhosale;
