import React from "react";

const rutuja_dautpure = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                    Rutuja Dautpure
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citibank</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                        Following the aptitude test <br/>
                        There were 2 rounds of interview: <br/>
                        1. Technical round
                        2. HR round

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                        Students were shortlisted based on their performance in the aptitude round then the shortlisted students could appear for technical 
                        interview and selected students from this round were supposed to appear for HR round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        1.Coding Questions <br />
                        2.SQL <br />
                        3.React <br />
                        4.Angular <br />

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                        Shortlist was announced few days after aptitude test.(about 3 days later) <br/>
                        I mainly focused on my projects(including all tech stack and functionalities), regarding DSA project I studied about the algorithms and data structure used in it, 
                        get the Time complexity of every function used in my project. Make sure I know the pseudocode of functions. <br/>
                        In DSA, revised all the concepts, sorting algorithms(their Time Complexities) <br/>
                        For last minute doubt gfg is the best resource for OOP and DBMS

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        The entire process has 3 rounds: <br/>
                        1.Aptitude Test: <br/>
                        It includes question on SQL,Angular,React and 2 coding questions. <br/>
                        2.Technical Round: <br/>
                        It begin with my introduction. This round primarily focused on projects. I was asked to describe a project that the interviewer found interesting, including the tech stack used and the reason behind its selection. For my DSA project, 
                        I was questioned about the algorithms and data structures implemented, their real-life applications, and potential alternative algorithms. Additionally, I was asked about SQL and its various operations. <br/>
                        3.HR Round: <br/>
                        First I was asked to introduce myself followed by questions about my experience in the previous interview. I was also asked about my hobbies, my role in the projects I worked on, and my contributions during the hackathon 
                        I participated in. He asked why I want to join Citibank and about the basic services the bank provides to its customers. Additionally, I was asked whether I prefer working in a team or independently and to share instances where I worked as part of a team, highlighting my contributions as a team member.
        
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        The interviewer primarily focused on my projects, delving deeply into their details. 
                        I was asked about alternative approaches to the methods I had implemented and how the knowledge gained from these projects could be applied to real-life scenarios. Additionally, more focus was on SQL.
                        <br/>Interviewer can ask to write an SQL Query for the functionality you have implemented in your project.

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Following the aptitude test, 32 students were shortlisted for the ICG role, and 12 were shortlisted for the PWBM role.
                        For ICG, 12 students were selected for the HR round, and finally, 9 received offer for ICG, while 3 received offer for PWBM role.
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        Coding Practice:  LeetCode, gfg
                        <br/>For DBMS: Love Babbar
                        <br/>OOPS: Gfg, Interviewbit
                        <br/>Gfg last minutes note for other core concepts
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Be consistent in solving coding questions. Start by using a brute-force approach, then work towards optimizing your solution. Follow a DSA course that guides you step by step, covering all the essential topics. 
                        Consistently solving 3-4 questions daily will help you a lot.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        The interviewer primarily focused on the project. You should know every small detail of your project like which techstack is used, the reason behind its selection, and possible alternatives.
                        <br/>For DSA projects the algorithm and data structure used. Time complexities of all the functions, can it be optimized by using other potential algorithm. The detailed working of algorithm.
                        <br/>Regarding extracurricular activities, the interviewer asked about my hobbies and the responsibilities I undertook as a coordinator during the college fest
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Be confident, express your point of view and your opinion clearly. Your conversation should reflect your willingness to learn and to work. It's also important to stay calm under pressure, listen actively, and engage in meaningful conversations.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Be positive throughout the interview process, stay calm and confident. Be thorough through your projects and resume. All the Best!!!
                        </p>
                    </p>
                    <br />

                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:dautpurerv22.comp@coeptech.ac.in">
                        dautpurerv22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default rutuja_dautpure;