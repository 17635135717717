import React from "react";

const LiznaNuruddinCharaniya = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        className="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    />
                </a>
                <div className="container text-justify">
                    <h2 className="mb-2">Lizna Nuruddin Charaniya</h2>
                    <h5 className="mb-2">Wells Fargo</h5>
                    <p className="text-gray-500 italic">
                        Computer Engineering, Batch of '26
                    </p>

                    {[
                        {
                            question: "What was the structure of the entire interview process?",
                            answer: (
                                <>
                                    The interview process consisted of four rounds:
                                    <ul className="list-disc pl-5">
                                        <li>Aptitude Round</li>
                                        <li>Technical Round 1 (Virtual)</li>
                                        <li>Technical Round 2 (In-person)</li>
                                        <li>HR Round</li>
                                    </ul>
                                </>
                            )
                        },
                        {
                            question: "What were the criteria for shortlisting candidates in the initial stages of the recruitment process?",
                            answer: "Students were initially shortlisted based on the aptitude test performance."
                        },
                        {
                            question: "What was the structure of the Aptitude Round?",
                            answer: (
                                <>
                                    The aptitude test had three sections:
                                    <ul className="list-disc pl-5">
                                        <li>English Proficiency (Basic Vocabulary and Comprehension)</li>
                                        <li>Business Analytics (Interpreting candlestick graphs and stock price prediction)</li>
                                        <li>Coding (3 easy coding questions)</li>
                                    </ul>
                                </>
                            )
                        },
                        {
                            question: "How many students were shortlisted after each round?",
                            answer: (
                                <>
                                    After the Aptitude Round, 22 students were shortlisted for interviews.
                                    <br />
                                    Finally, 11 students received the final offer.
                                </>
                            )
                        },
                        {
                            question: "How many rounds were there for the company, can you elaborate about each round in brief?",
                            answer: (
                                <>
                                    <p>There were 4 rounds in total</p>
                                    <p><strong>Aptitude Round</strong></p>
                                    <p>
                                        <strong>Technical Round 1 (Virtual)</strong> – The first technical interview was primarily focused on assessing my technical knowledge and problem-solving abilities. It started with questions on Data Structures and Algorithms (DSA), where I had to solve problems related to sorting algorithms and trees. The interviewer then moved on to DBMS, asking me to write and explain SQL queries. There were also questions about Computer Networks, testing my understanding of fundamental concepts like protocols and layers and few OS related questions. Since my resume highlighted Java, I was asked in-depth questions on multithreading and other core Java concepts followed by a few questions on Python. Additionally, the discussion touched upon machine learning, cloud and Node.js, as these were mentioned in my resume.
                                    </p>
                                    <p>
                                        <strong>Technical Round 2 (in person)</strong> – This round was entirely focused on my projects. I had to explain the details of each project I had listed on my resume, including the technologies used, challenges faced, and outcomes achieved. The interviewer also asked about my contributions and how I approached solving problems in each project. This was followed by a few DBMS questions.
                                    </p>
                                    <p>
                                        <strong>HR Round</strong> – The HR interview was more behavioral in nature. The questions revolved around my extracurricular activities and achievements outside of academics. The interviewer was interested in understanding how I manage my time, balance different commitments.
                                    </p>
                                </>
                            )
                        },
                        {
                            question: "What topics or skills did the interviewers focus on during the interviews?",
                            answer: (
                                <>
                                    The interviews focused on:
                                    <ul className="list-disc pl-5">
                                        <li>Data Structures and Algorithms (DSA)</li>
                                        <li>Database Management Systems (DBMS)</li>
                                        <li>Operating Systems (OS)</li>
                                        <li>Computer Networks</li>
                                        <li>Java and Python Concepts</li>
                                        <li>Machine Learning and Cloud Computing</li>
                                    </ul>
                                </>
                            )
                        },
                        {
                            question: "Some good resources for internship preparation?",
                            answer: (
                                <>
                                    <ul className="list-disc pl-5">
                                        <li>Striver's SDE Sheet</li>
                                        <li>GeeksforGeeks (GFG)</li>
                                        <li>LeetCode</li>
                                    </ul>
                                    Recommended to focus on core subjects like Computer Networks, DBMS, and Operating Systems
                                </>
                            )
                        },
                        {
                            question: "Tips for cracking coding questions",
                            answer: "Practice consistently. The more problems you solve, the better you'll get at recognizing patterns."
                        },
                        {
                            question: "Is there something specific you'd want to tell people trying for the same company?",
                            answer: (
                                <>
                                    Be well-rounded. Companies look for candidates who are not only technically strong but also articulate and confident.
                                    <br />
                                    Understand your projects thoroughly and be prepared to discuss them in depth.
                                </>
                            )
                        }
                    ].map((item, index) => (
                        <div key={index} className="mb-4">
                            <p className="font-bold">{index + 1}. {item.question}</p>
                            <p>{item.answer}</p>
                        </div>
                    ))}

                    <div className="mt-4">
                        <h3 className="text-xl font-bold mb-2">Final Tip</h3>
                        <p>Believe in yourself and stay confident throughout the process!</p>
                    </div>

                    <div className="mt-4">
                        <h3 className="text-xl font-bold mb-2">Contact Info:</h3>
                        <div className="text-gray-500">
                            Email-ID: <a href="mailto:charaniyaln23.comp@coeptech.ac.in" className="underline">
                                charaniyaln23.comp@coeptech.ac.in
                            </a>
                            <br />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LiznaNuruddinCharaniya;