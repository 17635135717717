import React from "react";

const Aalokkumar_jaiswal = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Aalokkumar Jaiswal
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>
						Marvell Semiconductors
					</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<p>
							The interview process consisted of a Aptitude test followed by 3
							technical rounds(specifically in my case) but some people only had
							2 technical rounds followed by a HR round.
						</p>
					</p>
					<p>
						<br />
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<p>
							All the E-group students above 7 CGPA were allowed to give the
							Aptitude test.
							<br />
							Initially the non-CS branch students were allowed to give the
							Hardware test but at the aptitude venue they were asked to give
							the Software test if they want because the hardware rolls were
							closed.
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round ? </b>
						<br />
						<p>
							Basically it had 2 sections the first was of general ability and
							math questions and the second section consisted of some C code
							snippets wherein we had to find the output ,also there were some
							file handling questions.There were no coding problems.If one is
							having sufficient knowledge about DSA,C programming and python
							he/she can easily clear the Aptitude test
						</p>
					</p>
					<br />
					<p>
						<b>
							4. After being shortlisted, how long was the wait time before the
							interviews : began? During this period, what specific areas did
							you focus on to prepare effectively?
						</b>
						<br />
						<p>
							My first technical round began at 3.30 pm till that I utilized
							that time for preparation wherein i especially focused on
							preparing the important DSA questions,OOPS concepts,OS last
							minutes notes,DBMS last minutes notes ,CN etc.I got a call for the
							second round immediately after my first round which was
							online.Then after that the company officials told us that the
							further process will be totally online.So next day i got a call
							for my third technical round and the HR call was scheduled on the
							immediate next day.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							5. How many rounds were there in the company, can you elaborate
							about each round in brief ?
						</b>
						<br />
						1)Aptitude Round: Online aptitude round ,we were asked to give it in
						the Cognizant lab.It basically consisted of simple maths questions
						and the C programming,Python programming questions,questions on RAID
						and questions of file handling.There were no coding questions but be
						also prepared for that as our seniors had the coding problems.
						<br />
						<br />
						2)First Technical Round: This round started with my intro.The
						interviewer asked me about me,my hobbies etc.Then he just started
						with my projects which i explained him briefly.So put your best
						projects in your resume.Then he aksed me to write a code in C/Python
						for the merge sort algorithm as one of my projects was based on this
						only.Then he asked me some OS questions as it was mentioned on my
						resume.He asked me “can we open a .exe file?”.”What happens when we
						double click on .exe file?”.He also asked me a puzzle-{" "}
						<a href="https://www.geeksforgeeks.org/puzzle-farmer-goat-wolf-cabbage/">
							https://www.geeksforgeeks.org/puzzle-farmer-goat-wolf-cabbage/
						</a>
						<br />
						<br />
						3)2nd Technical round(Online mode): He also started with a brief
						introduction of mine. Then he played around the questions on
						structures and pointers in C, Difference between malloc and calloc
						and some similar questions like why do we need to typedef while
						executing malloc so he was making me confuse even i was correct with
						my answer so the interviewer may confuse you but you should stick to
						the correct answer.Then he asked me a puzzle -
						<a href="https://www.geeksforgeeks.org/puzzle-7-3-bulbs-and-3-switches/">
							https://www.geeksforgeeks.org/puzzle-7-3-bulbs-and-3-switches/
						</a>{" "}
						Then we had basic discussion on Computer networks like what is the
						difference between IPV4 and IPV6 addresses? What is TTL in IPV4?
						Difference between switches and routers.It was of 45 minutes.
						<br />
						<br />
						4)3rd Technical round: We started with the introduction of mine .Then
						he asked me some questions on C structures and asked me to define
						the structure array and insert elements in that array. Also he asked
						me to access those elements using . or "arrow" operators and asked when
						to use . and when to use "arrow".He then gave me a code snippet and asked
						to predict the output and discrepancies if any.Then he asked me a
						simple maths question- sum of first 100 natural numbers is 5036 and
						two numbers are missing and difference between them is 8 find those
						numbers.
					</p>
					<br />
					<p>
						<b>
							6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
						 DSA ,puzzles, OS,CN
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round? </b>
						<br />
						After Aptitude round 28 students were shortlisted from the 100-110
						students who had appeared for the same.After the first technical
						round around 20 students were shortlisted for the second technical
						round.After the second technical some 10-12 students were asked to
						give HR.Finally 7 made it to Marvell!
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep? </b>
						<br />
						Love babbar’s DSA playlist, GFG articles, Striver’s SDE sheet, Love
						babbar’s DBMS and OS playlists, Sem 3 and sem 4 DSA assignments are
						must if the role is related to C programming.
					</p>
					<br />
					<p>
						<b> 9. Some tips to crack coding questions ?</b>
						<br />
						<p>
							Practice makes a man perfect. So practice as much DSA problems as
							you can. Also you can solve the problem of the day challenge on
							GFG which provides variety of DSA problems everyday.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							10. How many questions were asked about projects, clubs and
							extracurricular activities in your resume ?
						</b>
						<br />
						<p>
							Most of the questions were asked on projects only .Some questions
							were asked on extracurricular activities like what was your role
							or what does that portfolio basically do?
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							11. Is there something specific that you would want to tell people
							who try for the same company ?
						</b>
						<br />
						<p>
							Please clear your basics of python/C programming and be ready for
							the answer to any questions related to memory management in C.
						</p>
					</p>
					<br />
					<p>
						<b> Any Final tips ?</b>
						<br />
						<p>
							This process is very much luck based .So don’t get disheartened if
							you are not selected or could not be able to pass the aptitude
							rounds.Keep trying.Have a faith in yourselves and destiny,Believe
							in your preparation.Keep talking to your family and friends and
							also try to maintain a good CGPA of at least 7.5 .Remember that
							good things take time. All the best for your preparations.
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID :{" "}
						<a href="mailto:jaiswalam22.comp@coeptech.ac.in">
							jaiswalam22.comp@coeptech.ac.in
						</a>
						<br />
						{/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default Aalokkumar_jaiswal;
