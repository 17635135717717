import React from "react";

const sakshi_powar = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Sakshi Janardan Powar
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>National Payment Corporation Of India(NPCI).</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<p>
							1st round was the online aptitude test.
							<br />
							2nd round was the interview for the shortlisted students from
							online test. There was only one interview round in which they
							asked technical as well as HR questions.
						</p>
					</p>

					{/* Commented because no answer was provided in the doc: question numbers have been changed considering this question dropped*/}
					{/* <p>
						<br />
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<p>
						</p>
					</p> */}
					<br />
					<p>
						<b>2. What was the structure of the Aptitude Round ? </b>
						<br />
						<p>
							Following was the structure of aptitude test:
							<ul>
								<li>
									Section A – verbal ability, logical reasoning, and quants.
									(easy)
								</li>
								<li>Section B – machine learning 20 questions (medium)</li>
								<li>
									Section C – essay on random topic(for me the topic was{" "}
									<b>"Air Pollution and Its Health Risks"</b>) , 224 word limit
									(easy)
								</li>
								<li>
									Section D – coding, 2 questions of easy to medium difficulty
									(medium)
								</li>
							</ul>
							<br />
							Coding questions:
							<br />
							1. <b>Array Problem:</b> Given an array of N integers, the task
							was to repeatedly find the minimum element, add its value to a
							running total, and remove it along with its adjacent elements from
							the array. This process continued until the array was empty, and
							the total sum of all removed minimum elements was output.
							<br />
							2. <b>Matrix Problem:</b> Given an m×n matrix, where each cell
							represents a change in a robot's strength, the robot needed to
							traverse from the top-left corner to the bottom-right corner. The
							robot could move only right or downward. Positive values increased
							strength, negative values decreased it, and zero values left it
							unchanged. The task was to determine the minimum initial strength
							required for the robot to safely complete the journey without its
							strength dropping to zero or below at any point.
						</p>
					</p>
					<p>
						<b>
							3. After being shortlisted, how long was the wait time before the
							interviews : began? During this period, what specific areas did
							you focus on to prepare effectively?
						</b>
						<br />
						<p>
							There was a 10-day gap between the announcement of the shortlisted
							students and the interviews. During this time, I focused on Python
							and its various libraries commonly used in data analysis and data
							science, such as NumPy, Pandas, and Seaborn etc. Additionally, I
							studied Natural Language Processing (NLP), as the job roles
							mentioned in the company’s job description were aligned with
							positions like Data Analyst, Data Scientist, and AI/ML-related
							roles.
							<br />I studied my project thoroughly, revised concepts of
							Object-Oriented Programming (OOPS) , Database Management Systems
							(DBMS) and practised Data Structures and Algorithms (DSA)
							questions.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							4. How many rounds were there in the company, can you elaborate
							about each round in brief ?
						</b>
						<br />
						<p>
							As mentioned earlier, there were two rounds in the selection
							process: the aptitude test and the virtual interview.
							<br />
							In the interview, the first question was to introduce myself.
							Then, the interviewer asked detailed questions about my projects.
							They focused on database-related aspects of my projects and the
							features I had implemented. Next, the interviewer asked
							Python-related questions. They asked,{" "}
							<i>"Why is Python called dynamically typed?"</i> and{" "}
							<i>
								"What is the difference between ‘call by reference’ and ‘call by
								value?’"
							</i>{" "}
							I was also asked to write code to explain this difference while
							sharing my screen.
							<br />
							After that, they moved on to Database Management System (DBMS)
							concepts and asked, <i>"What are indexes in SQL?"</i>. For
							Object-Oriented Programming (OOPS), the questions included what
							polymorphism is, as well as questions on operator overloading,
							function overriding, and virtual functions.
							<br />
							It was helpful to explain OOPS concepts with real-world examples
							during the interview.
						</p>
					</p>
					<br />
					<p>
						<b>
							5. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
						Mostly on DSA, DBMS and OOPS.
					</p>
					<br />
					<p>
						<b>6. How many students were shortlisted after each round? </b>
						<br />
						96 students were shortlisted for aptitude test.
                        <br />
						<b>After the Aptitude Round</b>: 10 students were selected for
						interviews.
                        <br />
						<b>After the Interview Round</b>: 7 were finally chosen for the
						internship.
					</p>
					<br />
					<p>
						<b>7. Some good resources you used in your internship prep? </b>
						<br />
						Striver’s DSA sheet and DSA Playlist, CodeHelp DBMS playlist.
						<br />
						GFG is a good platform for preparation.
					</p>
					<br />
					{/* Answers for following questions are not provided */}
					{/* <p>
						<b> 9. Some tips to crack coding questions ?</b>
						<br />
						<p>
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							10. How many questions were asked about projects, clubs and
							extracurricular activities in your resume ?
						</b>
						<br />
						<p>
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							11. Is there something specific that you would want to tell people
							who try for the same company ?
						</b>
						<br />
						<p>
						</p>
					</p>
					<br /> */}
					<p>
						<b> Any Final tips ?</b>
						<br />
						<p>
							Don’t take aptitude tests for granted, Practice for aptitude tests
							as well. Don’t lose hope, “Waqt sabka aata hai!”.
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID :{" "}
						<a href="mailto:powarsj22.comp@coeptech.ac.in">
							powarsj22.comp@coeptech.ac.in
						</a>
						<br />
						{/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default sakshi_powar;
