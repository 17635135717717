import React from "react";

const atharv_munot = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Atharv Munot
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>BP Solutions India Pvt Ltd</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <br />
                        <p>
                            There were 3 interviews conducted in total: 
                        <br />
                            1. Technical Round: Based on your projects and the technology used.
                        <br />
                            2. Technical + HR Round: Based on your academic profile and how well you know BP
                        <br />
                            3. HR Round: Based on you and your ambitions, and how you believe those goals align with BP’s
                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                        1.	For the 1st Round: based on the score in the Aptitude Round
                        <br />
                        2.	For the 2nd Round: based on the performance in the Technical Round
                        <br />
                        3.	For the 3rd Round: based on the performance in the Techincal + HR Round
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        <br />
                        1.	Theory Questions: MCQ-type questions based on System Design, Cloud Infrastructure, Automation tools, and Software Development Lifecycle
                        <br />
                        2.	Coding Questions: 2 Coding questions; one medium, and one hard
                        </p>
                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            After being shortlisted for the Interview rounds, the first interview (i.e., the technical round) took place the next day, and the remaining 2 took place after a day’s break.
                            <br />
                            We didn’t know there would be 3 rounds of interviewing and thought there would just be one (as only one interview round took place last year). Thus, for the first round: I re-visited the PPTs I had made to explain the projects, ensured that the code was up and running, and read about new technology that can be used to improve my projects. I also revised CO, CN, DBMS, OOPS, and System Design topics (LMNs: Last Minute Notes -LMNs - GeeksforGeeks, and used ChatGPT to ask me relevant interview questions based on the Requirement Document provided by BP). Lastly, I researched BP’s: origin, vision, historical turning points, recent achievements, recent investments, and growth plan.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 4 rounds in total.
                        <br />
                            1.	Aptitude and Coding Round (Online) - Aptitude and Coding Round: The questions were based on System Design, Cloud Architecture, the basics of DBMS, CN, CO, and Automation Frameworks (like Selenium).
                            <br />
                            2.	Technical Round (Online) - Almost all questions were based on projects we’ve done, with the remaining few being behavioral situations and puzzles (logical reasoning-based).<br />
                            3.	Technical + HR Round (In-Person) - Questions based on our college curriculum; this was more like a conversation. We were asked if we had any questions for BP, which allowed us to build rapport with the interviewer.<br />
                            4.	HR Round (In-Person) - Questions were for personal information relevant to the job and future scope with BP.<br />
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        This is covered in the answer above.
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        1.	For the 1st round: 13 students<br />
                        2.	For the 2nd round: 6 students<br />
                        3.	For the 3rd round: 2 Students<br />
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        GFG LMNs, Topic-based problem set from LC Discussions, System Design YT Series by Gaurav Sen, TUF Striver’s DSA Sheet.
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Stay consistent with a couple of coding websites like LC, GFG, CC, etc. Solve their daily problems and some challenging ones. That will go a long way even beyond the scope of preparation for the internship.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Covered in previous answer.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Be perfect with your projects. You should know its ins and outs. You should be able to answer questions like: why did you choose to do this project, who will it benefit from, what are current technologies that are similar to your project, and what is the recent research done in that particular field, etc.
                        Also, you must know your project's working process, as well as its advantages and shortcomings. 
                        Depending on the Job Description Document during your recruitment process, focus on the topics mentioned. Direct questions on those will be asked. For example, during our aptitude round, we were asked a few questions based on cloud automation and SDLC, topics that were mentioned in the Job Description Document
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Stay consistent with your efforts, and passion
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:munot.atharv@gmail.com">
                        munot.atharv@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default atharv_munot;
