import React from "react";

const atharva_sardesai = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Atharva Sardesai
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>IdeaS</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<p>
							The interview process consisted of two rounds:
							<ol>
								<li>Technical Round (40-45 mins)</li>
								<li>HR Round (20 mins)</li>
							</ol>
						</p>
					</p>
					<p>
						<br />
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<p>
							For the Initial stage, students were shortlisted purely based on
							their CGPA.
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round ? </b>
						<br />
						<p>
							Students were shortlisted directly for the Interview round based
							on their CGPA. There was no aptitude test conducted.
						</p>
					</p>
					<p>
						<b>
							4. After being shortlisted, how long was the wait time before the
							interviews : began? During this period, what specific areas did
							you focus on to prepare effectively?
						</b>
						<br />
						<p>
							After the shortlist was announced, we were given about a week to
							prepare for the interviews. Initially, the interviews were
							scheduled for the next day, but heavy rainfall caused a
							postponement by a week.
							<br />
							<b>Interpersonal Questions:</b>
							<br />I began with rehearsing answers to common interpersonal
							questions, such as:
							<ul>
								<li>Introduction</li>
								<li>My future plans / What I look for in a company</li>
								<li>
									Background about IdeaS: Why it stands out in the market and
									what the company does
								</li>
							</ul>
							Rehearsing these questions is crucial as they shape the first and
							last impressions you make, even in technical rounds.
							<br />
							<b>Technical Preparation</b>
							<br />
							<ul>
								<li>
									I went through some online interview videos on YouTube to
									understand IdeaS’ focus areas.
								</li>
								<li>
									I revisited core concepts of C, C++, File Handling, Time
									Complexity, Pointers, and Strings. Revisiting means to to know
									all possible minute details about those topics. Some examples
									can be - Differences between C and C++, Updates in different
									versions of C++, Comparison of auto and extern variable
									modifiers (these were actually asked in previous companies).{" "}
								</li>
								<li>
									Focused on DSA topics: Arrays, Strings, Stack, and Dynamic
									Programming, as they are commonly asked in interviews.
								</li>
								<li>
									Reviewed Babbar’s cheat sheets for DBMS, CN, and basic OS
									concepts.
								</li>
							</ul>
							Practiced programming questions on LeetCode, revisiting previously
							solved questions and attempting their variations just to get a
							feel of it.
							<br />
							Last but surely not the least, I went through all of my projects
							and made sure I understood each and every bit of it. Especially
							those components made by my peers. (Eventually this proved to be
							very useful).
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							5. How many rounds were there in the company, can you elaborate
							about each round in brief ?
						</b>
						<br />
						<p>
							<b>Rounds:</b>
							<ol>
								<li>
									<b>Technical Round</b>
								</li>
								<li>
									<b>HR Round</b>
								</li>
							</ol>
							<b>Technical Round:</b>
							<br />
							The technical round was heavily focused on the projects I had
							developed. My advice to everyone would be to thoroughly understand
							every aspect of your project, down to the smallest details.
							<br />
							The interviewer asked me to share my favorite project and
							elaborate on it. I chose a project that showcased significant
							depth and covered key core concepts of DSA.
							<br />
							I had developed a Simplified Version Control System that I had
							developed during the previous semester with Avnish Chaudhary and
							Idris Adeeb, which is closely related to Git. I explained the Git
							commands we implemented, the data structures used, and the thought
							process behind each implementation. The interaction felt more like
							an in-depth discussion about the project than a typical Q&A
							interview.
							<br />
							The round concluded with a logical math question — The Train-Bird
							Problem.
							<br />
							<b>HR Round:</b>
							<br />
							In the HR round, I started by introducing myself. I was then asked
							about my future goals and what I knew about the company.
							<br />
							The interviewer also posed situational questions designed to test
							my conversational and leadership skills.
						</p>
					</p>
					<br />
					<p>
						<b>
							6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
						The topics asked were DSA (mostly projects) and one puzzle (atleast
						for me). Some of the other candidates were asked to write SQL
						Queries for a schema, and a leetcode medium question to solve.
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round? </b>
						<br />
						For the technical Round, around 24-25 students were shortlisted.
						Amongst them, 5 people were shortlisted for the HR Round, from which
						two were finally selected.
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep? </b>
						<br />
						Stirver’s SDE Sheet, CodeHelp by Babbar - DBMS, CN and OS Notes.
						<br />
						Cracking the coding Interview by Gayle, Laakmann and McDowell.
					</p>
					<br />
					<p>
						<b> 9. Some tips to crack coding questions ?</b>
						<br />
						<p>
							More and more practice of questions. And one important point to
							note here is that quality of questions are more important than
							quantity, especially if you have less time. Try to solve all
							varieties of a problem before moving to the next one.
							<br />
							And don’t be afraid of questions :) Every question will make you
							learn and understand, so just go out and try to solve it!!
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							10. How many questions were asked about projects, clubs and
							extracurricular activities in your resume ?
						</b>
						<br />
						<p>
							The Technical Round focused primarily on projects, so ensure you
							can explain them in detail.
							<br />
							For the HR Round, expect situational questions like, "How would
							you handle a particular scenario?" Here, you can share examples of
							how you’ve tackled similar situations in the past—especially if
							you’ve been a head or member of a fest or club.
							<br />
							Take help of your extracurricular activities to refine your
							answers.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							11. Is there something specific that you would want to tell people
							who try for the same company ?
						</b>
						<br />
						<p>
							Be confident and cover all your fundamental concepts thoroughly.
							Answer questions honestly, and if you don’t know something, it’s
							okay to admit it.
							<br />
							Believe it or not, your conversational skills play a very
							important role in any interview process. Do not panic in any case,
							stay calm and relaxed in every situation.
						</p>
					</p>
					<br />
					<p>
						<b> Any Final tips ?</b>
						<br />
						<p>
							It's perfectly normal to feel nervous, whether it's your first
							interview or your fifth. Just go out there and express yourself.
							Don't overthink it—an interview is simply a conversation for the
							recruiter to get to know you.
							<br />
							Learn from your mistakes and strive not to repeat them. Remember,
							you've come a long way. Have Faith in Yourself and trust the
							process. You've got this! 😊
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID :{" "}
						<a href="mailto:atharvaps22.comp@coeptech.ac.in">
							atharvaps22.comp@coeptech.ac.in
						</a>
						<br />
						{/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default atharva_sardesai;
