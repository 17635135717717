import React from "react";

const suhas_dode = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Suhas Dode
					</h2>
					<br />
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
					<br />
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<br />
						<p>
							There were two domains ICG tech and PWBM from which we have to chose one.
							I selected the ICG tech. <br />
							First was Aptitude round, then Technical round, and final HR round.
						</p>
					</p>
					<br />
					<p>
						<b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
						<br />
						<p>
							First, they shortlisted students based on their CGPA. <br />
							In the aptitude, as I said, they were looking for the basic concepts of React and Angular plus the database concepts (for the ICG tech Role).
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round?</b>
						<br />
						<p>
							Aptitude round had multiple-choice questions on the database topics, SQL queries, React, and Angular. <br />
							Two coding questions were asked. Both were easy. <br />
							One was finding the count of characters that are not repeated in a string, and the second was to construct an algorithm for the state of cells after a certain number of days based on the given conditions. <br />
							This round had a 1-hour time limit. Students were shortlisted based on the time utilized.
						</p>
					</p>
					<br />
					<p>
						<b>4. After being shortlisted, how long was the wait time before the interviews began? During this period, what specific areas did you focus on to prepare effectively?</b>
						<br />
						<p>
							After the Aptitude round, there were 4 days in between. <br />
							In that time, I focused on my projects and basically what I had mentioned in my resume.
						</p>
					</p>
					<br />
					<p>
						<b>5. How many rounds were there in the company, can you elaborate about each round in brief?</b>
						<br />
						<p>
							There were only two rounds after aptitude: <br />
							<b>First was the technical round:</b> <br />
							The interviewers were very friendly. <br />
							- They asked me to introduce myself. <br />
							- Then asked some questions on one of my projects and where I implemented that project. <br />
							- Then he asked a question to swap two variables without using a temp variable. <br />
							- After this, he gave me an array and asked me to tell different sorting algorithms and which I would prefer based on the size of the array. <br />
							- Then he asked me two SQL queries, which were pretty easy, and the joins in SQL. <br />
							- After this, he asked me some HR questions, though it was a technical round like: <br />
								- What do you see unique in Citi? <br />
								- Why do you want to join Citi? <br />
								- Tell me your 3 weaknesses and strengths, etc. <br />
							- Finally, he ended with asking if I had any questions to ask. <br />
							<b>Next round was the HR round:</b> <br />
							This was short. <br />
							- He asked me to introduce myself. <br />
							- Then he asked why I chose this field and if there was any specific reason. <br />
							- What do I think about Citi in brief? <br />
							- Why Bank? <br />
							- How could Citi handle large-scale transactions every day around the world? <br />
							- And finally, any questions I had to ask.
						</p>
					</p>
					<br />
					<p>
						<b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)</b>
						<br />
						<p>
							There were two interviewers. <br />
							- One focused on the technical questions of DBMS and simple programming. <br />
							- In the technical round, he was asking questions on SQL and simple DSA questions on arrays and sorting. <br />
							- He also focused on the behavioral questions.
						</p>
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round?</b>
						<br />
						<p>
							After the Aptitude round, a total of 44 students were shortlisted: <br />
							- 32 for the ICG tech domain. <br />
							- 12 for the PWBM domain for the technical round. <br />
							After the Technical round, 21 students were shortlisted for the HR round, and finally, 12 students were selected for the final offer.
						</p>
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep?</b>
						<br />
						<p>
							- I followed Striver’s A-Z DSA playlist along with solving the Striver’s SDE sheet for solving problems. <br />
							- For Database and O.S. basic concepts, I watched Love Babbar’s playlist. <br />
							- For OOPs, GFG is good. Also, there is Kunal Kushwaha’s playlist for Java OOPs.
						</p>
					</p>
					<br />
					<p>
						<b>9. Some tips to crack coding questions?</b>
						<br />
						<p>
							- Start solving DSA sheets from DAY 1. <br />
							- It’s very important to solve problems in a given time. <br />
							- Daily practice of DSA questions is very important. <br />
							- Try to get maximum test cases passed.
						</p>
					</p>
					<br />
					<p>
						<b>10. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
						<br />
						<p>
							- Some questions were asked on one of my projects. <br />
							- None were asked on clubs and extracurricular activities.
						</p>
					</p>
					<br />
					<p>
						<b>11. Is there something specific that you would want to tell people who try for the same company?</b>
						<br />
						<p>
							- Just be confident about what you are saying and what you know. <br />
							- Don’t exaggerate in the interview. <br />
							- Mention only those things in the resume that you are totally confident with. <br />
							- Be calm during the interview. <br />
							- Luck plays a very important role here that can also affect the whole interview and the result. So don’t get upset. <br />
							- Just be frank and give the interview positively.
						</p>
					</p>
					<br />
					<p>
						<b>Any Final Tips?</b>
						<br />
						<p>
							<i>
								This internship process is very hectic. You might face rejections in initial interviews. <br />
								But don’t feel bad. Seek guidance from seniors. <br />
								And don’t let failure overcome your mind. Hang on with the process and give your best.
							</i>
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID: <a href="mailto:suhasdode9@gmail.com">suhasdode9@gmail.com</a>
						<br />
					</div>
					<br />
					{/* <div style={{ color: "gray", fontSize: "1.15rem" }}>
						<p>
							<strong>Phone Number:</strong> 8668501948
						</p>
					</div> */}
				</div>
			</section>
		</div>
	);
};

export default suhas_dode;