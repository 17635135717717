import React from "react";

const chirag_ingale = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Chirag Krishna Ingale
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Deutsche Bank
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />


          <br />

          <p>
            <b>1. Structure of the Entire Interview Process:</b>
            <ul>
              <li>Online Aptitude (online)</li>
              <li>Technical Interview</li>
              <li>Pro-fit Interview</li>
              <li>HR Interview</li>
            </ul>
          </p>
          <br />

          <p>
            <b>2. Structure of Aptitude Round:</b>
            <p>
              1.Overall Structure: The Aptitude Round was allowed to be given from anywhere.Aptitude was conducted on the HackerEarth Platform.
              There was proctoring during the entire Test.
              <br />
              2.Sections: The Aptitude Round consisted of 10 MCQs and 2 Coding Questions.
              <br />
              MCQs:Each MCQ carried 4 Marks.The MCQs were based on Computer Networks(Subnetting Numericals),Database Management System(MongoDB),
              Transformers,Finding the Output of the Codes(Questions in Both C++ and JAVA were asked),Mapping (Indexing).
              <br />
              Coding Questions:One Coding Question carried 50 Marks and the Second Coding Question carried 20 Marks.
              In the First Question we were given a 3 dimensional Array A[i][j][k] of dimension N*N*N and we had to find the maximum value of N such that the sum of all the elements of the array is less than S (A given Input value).
              <br />
              In the Second Question we had to find minimum maximum distance.
            </p>
          </p>
          <br />

          <p>
            <b>3. How many students were shortlisted after each round?</b>

            <br /> 1.After the Aptitude Round 33 Students were Shortlisted for the Technical Interview.
            <br />2.After the Technical Interview 22 to 23 Students were Shortlisted for the Profit Interview.
            <br />3.After the Profit Interview 12 Students were Shortlisted for the HR Interview.
            <br />4.11 Students were given the Final Offer.


          </p>
          <br />

          <p>
            <b>
              4. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>
              The candidates from Computer Engineering Branch with CGPA greater than or equal to 7 were allowed to give the Aptitude Round.
            </p>
          </p>
          <br />

          <p>
            <b>
              5. After being shortlisted, how long was the wait time before the
              interviews began? During this period, what areas did you focus
              on?
            </b>
            <p>
            <br /> 1.The Aptitude Round was Conducted on 3rd September and the List of Shortlisted Students was announced on 5th September and The Technical Interview was scheduled for the next day.So we had 1 day as a wait time before the Interviews began.
              <br />2.After the Aptitude Round, I started Revising the Skills and the Subjects I mentioned on My Resume along with Data Structures and Algorithms.I specially focused on Operating Systems,Data Structures and Algorithms and on my Projects.
            </p>
          </p>
          <br />

          <p>
            <b>6.How many rounds were there for the company, can you elaborate about each round in brief :</b>
            <ul>
              <li>
                <b>Aptitude Round (Online):</b> This Round consisted of 10 MCQs and 2 Coding Questions.
              </li>
              <li>
                <b>Technical Interview:</b> The Technical Interview was conducted for about 35 to 40 Minutes.I was asked Questions from my Resume.Some Web Related Questions were asked Since I had mentioned about contributing to the making of my Club’s Website.Questions on GET Method and POST Method were asked.Questions on my Project were asked, I had used MongoDB so the interviewer asked me a question that What type of a database is MongoDB?(NoSQL).I was asked questions on Operating Systems, they included deadlocks,conditions for deadlocks,how can the deadlocks be avoided?.Questions on Object Oriented Programming were asked, 4 pillars of Object Oriented Programming etc. I was asked to differentiate between C and C++ then C++ and Python.I asked 2 to 3 Questions at the end of the Interview.
              </li>
              <li>
                <b>Pro-fit Interview:</b> The Pro-fit Interview was conducted for about 30 Minutes, After giving a brief introduction, The Interviewer asked me Questions about My family,the Profession of my family members and how did the profession of my brother inspire me to do something.I was asked questions about my daily life and technical questions relating to them.Since I had mentioned about Operating System on my Resume the Interviewer asked me What is the name of the boot manager of Linux.I was asked why do I want to Join Deutsche Bank.At the end of the Interview I asked 2 to 3 questions.
              </li>
              <li>
                <b>HR Interview:</b>The HR Interview was conducted for about 20 to 25 Minutes. I was asked to give a brief introduction.I was asked about my Family,My hobbies and My college life.Questions like what was the biggest achievement or happiest moment for me,The environment in the college were asked.I was asked Questions based on my Project.At the end of the interview I asked 2 to 3 Questions.
              </li>
            </ul>
          </p>
          <br />

          <p>
            <b>
              7. What topics or skills did the interviewers focus on during the
              interviews?
            </b>
            <p>
              <ul>
                <li>Technical Interview: During the Technical Interview the Interviewer focused on the Topics mentioned in my Resume.The Topics like Web development,Database,Operating System,Object Oriented Programming,Programming Languages were asked.</li>
                <li>
                  Profit Interview: The Interviewer focused both on my Technical skills and my Daily Life.He also focused on the Project mentioned in the Resume.
                </li>
                <li>HR Interview: During the HR Interview the Interviewer focused on my Daily Life,My Projects and on My College Life.</li>
              </ul>
            </p>
          </p>
          <br />

          <p>
            <b>8. Some good resources used in internship prep:</b>
            <ul>
              <li>DSA: Code Help (Love Babbar’s DSA Playlist) and Striver’s SDE Sheet (Also Striver’s Playlist to understand the solutions to questions)</li>
              <li>Web Development:Code With Harry</li>
              <li>DBMS, OS:Code Help (Love Babbar’s Lectures),InterviewBit for Most frequently asked questions in the Interview.</li>
              <li>Computer Networks: We had Data Communication in the previous semester so I had a little knowledge about Computer Networks from the same subject.I used InterviewBit to study the most frequently asked questions.</li>
            </ul>
          </p>
          <br />

          <p>
            <b>9. Tips to crack coding questions:</b>
            <p>
            <br />1.Read The Question and try to identify a pattern.
              <br />2.Analyse the Test cases and try to come up with a Brute force solution.
              <br />3.If You come up with an Optimal solution then try to implement it.
              <br />4.To get a good understanding of DSA You can try Striver’s SDE Sheet and Practice as much as You can.

            </p>
          </p>
          <br />

          <p>
            <b>
              10. How many questions were asked about projects, clubs, and
              extracurricular activities?
            </b>
            <p>
              Many Questions were asked about Projects. Questions related to Tech stacks of the Project,Implementation were asked.
              Not many questions were asked about Club and extracurricular activity.
            </p>
          </p>
          <br />

          <p>
            <b>
              11.Is there something specific that you would want to tell people who try for the same company :
            </b>
            <p>
            <br />1.If possible,try learning Operating Systems, it is an interesting subject and can work for You as a plus point in the Interview.
              <br />2.Questions on Projects are common so try studying the Project in very detail.You can study about the Tech Stacks related to Your Project in detail.
              <br />3.Object Oriented Programming is an Important topic, try learning it in detail for the specific Programming Language You use. Additionally, You can study about the most frequently asked questions for Object Oriented Programming.
              <br />4.Ensure that You attend the PPT(Pre Placement Talk). While attending the Talk if You have some doubts ask them during the Interview.

            </p>
          </p>
          <br />

          <p>
            <b>12. Final Tips for Juniors:</b>
            <p>
              Try to be positive during the Entire Interview Process.Enjoy learning different skills.During the Entire process stay connected with Your friends and share Your thoughts with them and try staying calm during the Process.Be confident,Believe in Yourself. Best of Luck.
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:{" "}
            <a href="mailto:ingaleck22.comp@coeptech.ac.in">
              ingaleck22.comp@coeptech.ac.in
            </a>
            <br />

          </div>
        </div>
      </section>
    </div>
  );
};

export default chirag_ingale;
