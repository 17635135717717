import React from "react";

const anishShetty = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Anish Shetty</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Corporation</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>The interview process for the PBWMT field began with an aptitude test, evaluating skills in Quantitative Reasoning, Data Structures, and solving two coding problems. This was followed by three in-depth interview rounds.</p>
          </p>
          <p>

            <br />
            <b>
              2. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>In the first round more focus was given on the understanding a candidate has in his/her projects. The in depth knowledge of the concepts used and if there are any mistakes what measures can be taken. The ability to answer fast and more important efficiently is the most important criteria. In the second round the problem solving ability and willingness to learn is what was seen. Although the problems were easy sometimes they might ask some core concepts of JAVA or OS.</p>
          </p>
          <br />
          <p>
            <b>3. What was the structure of the Aptitude Round ? </b>
            <br />
            <p>
              The aptitude round was very easy. It had questions on basic Logical Reasoning, Quantitative Reasoning, Data Structures and Algorithms and basic questions about programming. Learning the concepts of OOPs is very important as there might be some tricky questions asked.
              The coding questions were of easy level and didn’t take much time. Make sure your code works with minimum time and space complexity.
            </p>
          </p>
          <p>
            <b>
              4. After being shortlisted, how long was the wait time before the
              interviews : began? During this period, what specific areas did
              you focus on to prepare effectively?
            </b>
            <br />
            <p>
              I gave more focus on my projects. Even the smallest of concepts that I used were crucial to be known. Why a specific data structure or library is used and how is it more beneficial all such things is what I focused on. Along with it, it is important to know the basic concepts of OS, Computer Networks, Object oriented programming, databases etc. Practice SQL queries.
            </p>
          </p>
          <br />
          {/* <p>
            <b>
              5. How many rounds were there in the company, can you elaborate
              about each round in brief ?
            </b>
            <br />
            <p> None
            </p>
          </p> */}
          <br />
          <p>
            <b>
              5. What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <br />
            <p>
              More focus was given on DSA and DBMS for my first interview round. Details about the data structures used, the loop holes in the software created all this was asked. Also for the second round more of puzzles were asked and they were quite easy.
            </p>
          </p>
          <br />
          <p>
            <b>6. How many students were shortlisted after each round? </b>
            <br />
            For the PBWMT 15 students were shortlisted and for ICG Tech 25 students were shortlisted after the aptitude round.
            Within 6 days the interview rounds were conducted.Everyone gave the round 1 and 2 of the interviews although only 6 from the 15 in PBWMT gave the HR round. Then only 3 students were selected for the final internship.
          </p>
          <br />
          <p>
            <b>7. Some good resources you used in your internship prep? </b>
            <br />
            I used LeetCode for practicing several algorithms, data structures and sql. dsa450 has several basic and advanced level problems on data structures and their implementations. W3schools, GeeksForGeeks are some platforms that can be used for understanding the concepts in depth. To save time you can ask ChatGPT to explain the concept in short.
          </p>
          <br />
          <p>
            <b> 8. Some tips to crack coding questions ?</b>
            <br />
            <p>Cracking coding questions requires consistent practice and a strong grasp of underlying concepts. Focus on proper implementation, optimizing time and space complexity, and mastering algorithms and their applications. Stick to one language if preferred, but aim for expertise in it.</p>
          </p>
          <br />
          <p>
            <b>
              9. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>In the first round of the interview, the focus was entirely on projects. Candidates were required to explain the concepts applied in their work, emphasizing the importance of thoroughly understanding your projects. Be prepared to justify your choices—why you opted for a specific concept, data structure, algorithm, or software over alternatives, as these are commonly asked questions.
              While extracurricular activities might come up during the HR round, such questions are rare and usually take a backseat to technical discussions.</p>
          </p>
          <br />
          <p>
            <b>
              10. Is there something specific that you would want to tell people who try for the same company?
            </b>
            <br />
            <p>Stay relaxed while giving the interview. Your confidence and willingness to learn and grasp knowledge is more important. If you have made some mistake, accept it and try giving a solution if you have any.
            </p>
          </p>
          <br />
          <p>
            <b> Any Final tips ?</b>
            <br />
            <p>
              The internship process can often feel like a matter of luck. You might excel in your skills, but sometimes, the interviewer might have a different set of expectations or priorities. Don’t be disheartened—stay persistent, keep improving, and your effort will eventually pay off. Keep going, and success will find you!
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID: <a href="mailto:anishds22.comp@coeptech.ac.in
">anishds22.comp@coeptech.ac.in
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default anishShetty;
