import React from "react";

const sayali_khedkar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Sayali Khedkar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            Technical Interview<br />
                            Pro-fit round <br />
                            HR round

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Students were shortlisted based on their performance in the aptitude round and then the shortlisted students could appear for interview
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            It was conducted online (from anywhere) .
                            Duration : 90 min<br />
                            2 sections : MCQ and 2 Coding que <br />
                            Mcq were based on CN, OS, DBMS, Java, DSA
                            Questions were on number of subnets, indexes in dbms, OS concepts and java code snippets. <br />
                            In coding section, one of the question was based on greedy approach. The other question was on 3D matrix where we have to find the maximum dimension N such that the sum of all elements does not exceed threshold S, which could be solved by optimisation of binary search problem.Passing maximum number of test cases was the key.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began?</b><br /><p> During this period, what specific areas did you focus on to prepare effectively?
                                The shortlist was announced a few days after the aptitude round and the interviews were scheduled the next day of announcing the shortlist. <br />
                                During this time i mainly focussed on my projects,
                                making sure I know every bit of it including all the tech stacks and features.
                                Also revised all dsa concepts , sorting algorithms , oops, dbms concepts (GFG last minute notes can be a good choice at this time) </p>

                        
                        <br />

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were 3 interview rounds
                        <br />Technical round (45-60 min) : 
                        <br/> Firstly I was asked que on few dsa concepts. Then they asked implementations of some data structures and implementing one data structure using other like linked list using queue etc, which I had to code on paper. Then we began with indepth discussion on one of my project,where questions on various features, implementation of it, validation checks etc were asked.Also i was asked me to draw schema of my project ,explain relationships and few sql queries on the same. Questions on react, regex , dbms , oops concetps, python vs cpp etc were also asked. 
                        <br />Professional Fitness round (25-30 min) :
                        <br/> This was mostly to test how well can we handle various situations. He gave me a few scenarios and asked what my approach would be to tackle those. He also asked my projects, and the impact that they have created. Also since I am a head in SDS club , he asked about the various projects, maintanence of those projects and our workflow at SDS. Then some standard questions like why Deutsche bank, future aspirations etc. 
                        <br />HR round (10-15 min) :
                        <br/> Basic and standard question related to family background, experience in hostel, learnings were asked. Also I was asked about what I know about company, the ppt . Make sure you pay attention in the ppt as it might help answering company related questions in interview. 

                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        Topic focussed in interview :
                        DSA, sorting techniques, DBMS, react, scenario based questions

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        32 students were shortlisted for interview and 11 got the final offer

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        Striver's A2Z DSA sheet <br />
                        GFG and Leetcode for coding practice.<br />
                        Striver's SDE sheet <br />
                        GFG Last minute notes <br />
                        Dbms by Love Babbar<br />

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Practice coding questions regularly, participate in contests. Try to dentify patterns among them. First go with the brute force and then try to optimise it further. Focus on understanding various types of questions rather than just solving a large quantity.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>A lot of questions were asked on projects and on the tech stacks used in it. Most of my interview revolved around projects so make sure you know your projects in and out. Also few questions on club and what it does was also asked.    </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Do not include anything in your resume that you are not fully confident about.Know atleast few things about the company and in the pro-fit round try to include collaboration, respecting others opinion keywords in your interview.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Start early and be consistent in your preparation. It may be a tough grind but make sure to put your best effort as the result would be worth it. Lastly don't forget to enjoy this process.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:khedkarss22.comp@coeptech.ac.in">
                            khedkarss22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/sayali-khedkar-a26414279 ">Sayali khedkar</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default sayali_khedkar;
