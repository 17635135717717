import React from "react";

const piyushDeshpande = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>

        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Piyush Uday Deshpande
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>
              <ol>
                <li> Technical Round</li>
                <li> Managerial Round</li>
                <li> HR Round</li>
              </ol>
            </p>
          </p>
          <p>
            <br />
            <b>
              2. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>The criteria was no backlogs and CGPA greater than 7.5.</p>
          </p>
          <br />
          <p>
            <b>
              3. After being shortlisted, how long was the wait time before the
              interviews : began? During this period, what specific areas did
              you focus on to prepare effectively?
            </b>
            <br />
            <p>
              After shortlisting we had 1 day to prepare. I overviewed my
              projects in my resume and made sure that I was equipped with
              fundamental concepts of all the technologies or subjects that I
              mentioned in technical skills and subjects section.
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              4. How many rounds were there in the company, can you elaborate
              about each round in brief ?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  Technical Round : This round was solely based on the technical
                  skills that I mentioned in the resume. I was asked questions
                  about OOPs , OS and DSA. I was also asked language specific
                  questions like C++ STL. I was asked fundamental concepts of OS
                  like CPU Scheduling algorithms , Memory architecture. The
                  questions from OOPs were ranging from easy to complex. DSA
                  questions also had the same range (starting from easy to
                  complex).
                </li>
                <li>
                  Managerial Round : This round had both sort of questions
                  technical as well as HR . The technical part was solely based
                  on projects and their implementation. HR questions were easy
                  like family background and interests.
                </li>
                <li>
                  HR Round : Questions like why should we hire you and what do
                  you do in free time were asked.{" "}
                </li>
              </ol>
            </p>
          </p>
          <br />
          <p>
            <b>5. How many students were shortlisted after each round? </b>
            <br />
            22 students were shortlisted after the Aptitude test. 13 students
            were shortlisted after the technical round. Finally, 11 students
            were selected.
          </p>
          <br />
          <p>
            <b>6. Some good resources you used in your internship prep? </b>
            <br />
            <p>
              <ol>
                <li>LeetCode</li>
                <li>GeeksforGeeks</li>
                <li>Love Babbar</li>
              </ol>
            </p>
          </p>
          <br />
          <p>
            <b> Any Final tips ?</b>
            <br />
            <p>
              Just do your best. There are a lot of things that are not in your
              hand so don’t feel stressed if something does not work out as you
              expected. I want you all to remember one thing from starting of
              this process , any rejection does not define your capability , you
              and your preparation define it. Remember those efforts you take
              will never go in vain.
            </p>
          </p>
          <br />

          {/* <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Phone: 7756003898
            <br />
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default piyushDeshpande;
