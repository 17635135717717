import React from "react";

const bhargav_gejji = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Bhargav Gejji
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Marvell</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>

                            <br /> There were 2 Technical rounds and 1 HR interview.
                            <br />  After each technical round few students were shortlisted for the next round.


                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            <br /> Eligibility for the Aptitude Test: All Computer Engineering students with a CGPA of 8 or above were allowed to appear for the aptitude test.

                            <br />  Shortlisting After the Test: The top 16 candidates who scored the highest marks in the least amount of time were shortlisted for the next round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            <br /> The Aptitude round consisted of a test of MCQ questions. There was a section
                            on Aptitude questions like series, find the missing number, blood relations,
                            analogy, coding-decoding.
                            <br /> There was another section on programming and theory questions related to
                            Computer science.
                            <br />  Pseudo codes were asked in the programming section.
                            <br />  Theory qestions were asked on RAID (topic of computer architecture).
                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            After getting shortlisted for the interviews, I reviewed some concepts and
                            syntax of C programming language. I reviews the concepts of computer
                            networks and the related linux commands.
                            I saw the implementations of data structures using C.
                            <br />After the first technical round, I revised computer network concepts as much
                            as possible and computer architecture concepts.
                            <br />After the second technical round, I framed answers for the common HR
                            questions.
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        The first technical round was of Data structures and algorithms. We were
                        asked to write code in C programming language for DSA questions.
                        <br />The second technical round was of concepts of computer science where
                        questions on operating systems, computer networks, computer architecture
                        was asked. We should also be know linux networking commands.
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>

                        <br /> The first technical round was related to DSA using C programming language.
                        So DSA questions were asked and I had to write the code for it in C.
                        <br /> In the second technical round questions related to computer networks were
                        asked and some more questions on DSA.
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude test, 27 students were shortlisted for interviews.
                        In the interviews, students from the top and bottom of the shortlist were picked
                        for the first
                        Technical round, after which based on performance candidates were pushed
                        to the next round.
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br /> I used to solve DSA questions on Geeks for Geeks. Apart from that I
                        thoroughly studied computer engineering subjects like computer networks,
                        database management systems from youtube.

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Regular practice of DSA questions on platforms like geeks for geeks, leetcode
                            is recommended. Try to focus on building logic rather than more number of
                            questions.                         </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            A small discussion was there on my extracurricular activities. I was not asked
                            about my projects, I was directly asked to explain different computer
                            engineering concepts, coding questions, puzzles.                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            I would suggest to focus on having strong concepts of computer networks,
                            computer architecture.
                            <br />Practice Data structures and algorithms regularly in C programming language
                            because the company uses C/C ++ programming language.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                         I would recommend candidates to start practicing DSA on platforms like
                            leetcode , geeks for geeks as early as possible.
                            <br /> I would recommend candidates to be clear on concepts of DBMS, CN since
                            they are asked in many companies.
                            <br />  Finally I would like to say that believe in yourself and keep working hard.

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:bhargavsg22.comp@coeptech.ac.in">
                         bhargavsg22.comp@coeptech.ac.in 
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default bhargav_gejji;
