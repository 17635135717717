import React from "react";

const shrushtiGadale = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>

        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Srushti Gadale
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>BP Global</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>
              <ol>
                <li> Aptitude Test</li>
                <li> Technical Interview</li>
                <li> HR Interview</li>
              </ol>
            </p>
          </p>
          <p>
            <br />
            <b>
              2. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>
              A minimum of 7.5 CGPA was needed to sit for the Aptitude test.I do
              not have a definite answer as to how they selected 13 students
              after the Aptitude test since the test was unproctored and did not
              have a time limit (I know).My guess is that it was based on your
              pointer and whether you were able to submit the test in the given
              time frame. For your reference , my pointer was 8.47
            </p>
          </p>
          <br />
          <p>
            <b>3. What was the structure of the Aptitude Round ? </b>
            <br />
            <p>
              Mcq questions (must be about 15-20) ending with 2 simple coding
              questions
            </p>
          </p>
          <p>
            <b>
              4. After being shortlisted, how long was the wait time before the
              interviews : began? During this period, what specific areas did
              you focus on to prepare effectively?
            </b>
            <br />
            <p>
              Before the technical round(online) which was immediately the next
              day, I got well acquainted with the questions asked in the
              Aptitude test in case they asked me more about it and thoroughly
              looked into my projects. For the HR round which was on the next
              day after the technical interview, I went through Bp Global’s
              official website and looked at what qualities they were looking
              for in their employee.I recollected significant incidents from my
              life where I had exhibited those qualities.
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              5. How many rounds were there in the company, can you elaborate
              about each round in brief ?
            </b>
            <br />
            <p>
              <ol>
                <li>
                  <b> Aptitude Test</b> - Mcqs on a variety of topics such as
                  cloud computing , basic testing knowledge , software models,
                  etc. 2 coding questions at the end.
                </li>
                <li>
                  <b> Technical Round</b> - I was tested on my knowledge about
                  computer networks ,simple riddles. I was asked a lot about my
                  projects. The interview lasted for 50 minutes.
                </li>
                <li>
                  <b> HR Round</b> -There were 2 interviewers. I was asked to
                  introduce myself. In my introduction I had mentioned my
                  position as a head of a fest and thus was asked questions
                  regarding it. After which they asked me to describe the
                  greatest day of my life.At the end , I asked them a question
                  about Bp Global which they happily answered. Entire Interview
                  was 5 minutes long.
                </li>
              </ol>
            </p>
          </p>
          <br />
          <p>
            <b>
              6. What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <br />
            <p>
              During my technical interview , they grilled me on computer
              networks and my projects. Few of my friends were asked basic
              coding questions as well.
            </p>
          </p>
          <br />
          <p>
            <b>7. How many students were shortlisted after each round? </b>
            <br />
            108 students were eligible for giving the Aptitude test out of which
            13 students were shortlisted. After the technical round , 6 of us
            were left. At the end of the HR round , 2 students were given
            internships.
          </p>
          <br />
          <p>
            <b>8. Some good resources you used in your internship prep? </b>
            <br />
            <p>450 Dsa Cracker and Love Babbar’s DSA Playlist</p>
          </p>
          <br />
          <p>
            <b> 9. Some tips to crack coding questions ?</b>
            <br />
            <p>
              
              The questions I was asked were pretty simple. Make sure that you know the basics.
              <br />
            </p>
          </p>
          <p>
            <b>
              {" "}
              10. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>
              Since I had mentioned my extracurricular in my introduction , they
              asked me one question about that. Otherwise no such questions were
              asked.
            </p>
          </p>
          <p>
            <b>
              {" "}
              11. Is there something specific that you would want to tell people
              who try for the same company
            </b>
            <br />
            <p>
              I would suggest attending their ppt which happens before Apti.I
              would also advise you to be thorough with your projects.During HR
              , be your true self instead of pretending to be perfect.
            </p>
          </p>


          <br />
          <br />
          <p>
            <b> Any Final tips ?</b>
            <br />
            <p>
              Having been through this process myself, I understand how
              emotionally draining it can become. My advice to you is to stay
              consistent in your efforts and never give up. Take a moment to
              breathe in and breathe out—you've got this! If you ever need
              guidance or support, feel free to reach out to me via email. I'd
              be more than happy to help.
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            {/* <br />
            Phone: 7028009762
            <br /> */}
            <a href="mailto:srushtirg22.comp@coep.ac.in">srushtirg22.comp@coep.ac.in</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default shrushtiGadale;
