import React from "react";

const mansi_kumar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Mansi Kumar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Mastercard</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                    <br />
                    There was no interview. We had a 12 hrs hackathon after the aptitude round.
                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                    <br />
                    For the hackathon mentors were assigned to each of the teams and we had team meetings starting from 2 weeks prior to the hackathon (with the mentor) where we used to discuss certain strategies and how the work would be divided on day depending on the skills of our teammates. Winning the hackathon wasnt necessary to get the internship. Also everyone in the winning didnt get it.
                    </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        There were 2 coding questions(medium-hard difficulty) and we were given 45 minutes to solve them.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                        We were given approximately 2 weeks after the results of the aptitude round were announced and the hackathon team was made by the company itself and there were students from different colleges as well. As it was a hackathon i focused on web frameworks like MERN and basics of how to prepare lld(low level design) and hld(high level design).                        
                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        First one was the aptitude about which i have already mentioned above. One question was on graph and the other one was on trees.The second round was the 12 hrs hackathon which was conducted at the mastercard campus.
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>

                        No interviews but on the day of hackathon we were judged individually on the basis of planning, ideation, team work, coding skills, versatility, presentation skills, willingness to help others in the team.
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        Around 25 were selected after the aptitude round and 7 were selected after the hackathon for the final internship offer.

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />Strivers A2Z course for DSA, Lovebabbar for dbms,oneshots on yt for cn and os,scaler courses,udemy for webdev.
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Start doing daily questions on leetcode/gfg along with A2Z strivers sheet.                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        None
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        The coding questions in the aptitude were a bit tougher than the companies which had previously come.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Keep calm , study well and dont stress a lot because good things will happen to you when they are supposed to !

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kumarmr22.comp@coeptech.ac.in">
                        kumarmr22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default mansi_kumar;
