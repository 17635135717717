import React from "react";

const jay_kolhe = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Jay Kolhe
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>

                            <br />Aptitude Round (online)
                            <br /> Technical Round
                            <br /> HR Round
                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            <br /> Eligibility for the Aptitude Test: All Computer Engineering students with a CGPA of 8 or above were allowed to appear for the aptitude test.

                            <br />  Shortlisting After the Test: The top 16 candidates who scored the highest marks in the least amount of time were shortlisted for the next round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The test was conducted online on 7th Dec .Aptitude round had questions of logical reasoning, coding questions and 3 SQL queries. The first two coding questions were in range of easy to medium difficulty mostly easy and last one was pretty hard which I couldn’t able solve it and all 3 queries were simple.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The shortlist was not published immediately after the aptitude test(7th Dec started at 7 pm) or the next day. Instead, on 9th December, we had the pre-placement talk (PPT), and the shortlisted students were announced right after the PPT. Interviews were conducted immediately afterward in the placement cell.

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        There were total of 3 rounds :- Aptitude, 1 Technical and 1 HR’
                        <br /> Technical round :-The interview kicked off with a brief introduction about myself. The interviewer then transitioned to discussing SDLC and asked me to take an example and walk through it step by step. They emphasized understanding how core subject principles are applied in real-world scenarios and industries, which set the tone for the conversation.Next, the interviewer challenged me to design a simple database schema for BookMyShow (not on a large scale), followed by SQL queries . The discussion came to the projects listed on my resume. Moving on to DSA, the focus shifted to trees, including representing a BST using a doubly linked list and many more . This was followed by a straightforward coding question to evaluate my problem-solving approach.Towards the end, the interviewer delved into computer networks.
                        <br /> HR :- It started with an introduction, where I shared a bit about myself. The interviewer then asked about my hobbies and family background, which made the interaction feel personal.We also discussed my strengths beyond what was mentioned in my CV and areas where I felt I could improve.
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br /> Data structure and algorithm , Database Management system and Computer Networks


                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude test 16 were shortlisted for technical interviews. Then around 5 were asked to give an HR interview out of which 4 were finally selected.

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br /> Leetcode
                        <br /> GFG
                        <br /> Love Babber for DBMS and OS
                        <br /> Striver’s SDE Sheet
                        <br /> Indiabix - Aptitude
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            Aim to practice at least 1-2 problems daily.
                            <br /> After solving a problem, review and compare your approach with other available solutions. Analyze ways to optimize your solution for better efficiency and clarity
                            <br /> Additionally, engage in competitive programming on platforms like Codeforces to improve problem-solving speed and accuracy under time constraints.                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                        A good time was spent on projects in technical round . No Questions related to clubs and extracurricular were asked in the HR round
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Do not miss the Presentation. They ask a lot of questions like Which part in PPT do you like ? and also their Revenue Formula, etc. Be thorough with your projects and whatever you mention in your resume. Be confident while answering.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        The entire process can be frustrating and tiring. But all you need is to stay calm and optimistic. Just keep trying and don’t give up .
                        Be confident during the interviews but also do not make up things.

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:kolhejv22.comp@coeptech.ac.in">
                        kolhejv22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default jay_kolhe;
