import React from "react";

const Pratik_kardile = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left" 
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Pratik Kardile
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>
						Citi Corporation
					</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Electronics and Telecommunications, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<p>
							The interview process consisted of a technical round followed by a
							HR round:
							<br />
							<br />

							<b>Technical Round:</b> During my interview I was initially asked
							about my favourite subject and interest and how it connects with
							my studies beyond core Electronics.
							<br />
							Then I was asked questions from concepts such as Object oriented
							programming, some situational questions to identify correct
							techniques to achieve specific outputs with SQL. I had mentioned a
							DSA project. So they asked me a little about that and then to
							explain certain algorithms such as Djikstra’s shortest path
							finding and Quick sort.
							<br />
							Other than that I was asked some questions of logical reasoning,
							nothing technical about them. I believe they simply wanted to
							gauge my train of thought towards the answer.
							<br />
							<br />
							<b>HR round:</b> The HR round felt more like a conversation than a
							traditional question-and-answer session. I was asked about my
							hobbies, schooling, interests, and what motivates me. One
							interesting question was about what I knew about Citi beyond the
							information shared during their presentation.
						</p>
					</p>
					{/* Answer not provided */}
					{/* <p>
						<br />
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<p>
						</p>
					</p> */}
					<br />
					<p>
						<b>2. What was the structure of the Aptitude Round ? </b>
						<br />
						<p>
							The test consisted of questions from SQL, and frameworks like
							React and Angular, followed by coding problems. Here's the
							breakdown:
							<br />
							<b>Section 1:</b> Coding Questions (2 Questions) - Easy
							difficulty: The questions seemed tricky at first glance, but once
							you understood the logic, they turned out to be straightforward.
							<br />
							<b>Section 2:</b> Basic SQL Questions: Knowledge of fundamental
							SQL queries is sufficient to tackle this section.
							<br />
							<b>Section 3:</b> React Basics: Focus on the core concepts and
							common use cases in React.
							<br />
							<b>Section 4:</b> Angular Basics: Understanding key Angular
							features will help you sail through this part.
							<br />- For the said Frameworks a basic understanding should be
							enough to tackle most of the questions.
						</p>
					</p>
					<br />
					<p>
						<b>
							3. After being shortlisted, how long was the wait time before the
							interviews : began? During this period, what specific areas did
							you focus on to prepare effectively?
						</b>
						<br />
						<p>
							There usually is very little time from shortlistment and the
							interview. I used the day before my interview to revise SQL, React
							and some concepts of Angular. I had assumed that they would ask a
							lot of questions from these topics as the test had dedicated
							sections for each of them. That last minute preparation however,
							didn’t help much in the actual interview. What helped me were the
							hours I had spent practising coding questions, learning new
							concepts of DSA and planning and the time spent on my projects.
						</p>
					</p>
					<br />

					{/* Answer not provided */}
					{/* <p>
						<b>
							{" "}
							5. How many rounds were there in the company, can you elaborate
							about each round in brief ?
						</b>
						<br />
					</p>
					<br /> */}

					{/* Answer not provided */}
					{/* <p>
						<b>
							6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
		
					</p> 
					<br />
					*/}
					<p>
						<b>4. How many students were shortlisted after each round? </b>
						<br />A total of 44 students were shortlisted for the first
						technical interview, with 32 students selected for ICG Tech and 12
						for PWBM. For ICG Tech, 12 candidates progressed to the HR round,
						and all of them received offers in the end.
					</p>
					<br />
					<p>
						<b>5. Some good resources you used in your internship prep? </b>
						<br />
						<ol>
							<li>Code help - by Love Babbar, for DSA concepts.</li>
							<li>
								Leetcode, Strivers sheet(takeUforward) - for coding questions.
							</li>
							<li>Apna College - for topics such as SQL </li>
							<li>
								GeeksForGeeks is also a great resource on innumerable topics.
							</li>
						</ol>
					</p>
					<br />
					<p>
						<b> 6. Some tips to crack coding questions ?</b>
						<br />
						<p>
							This is where consistency is very important. Solving questions and
							contests are intimidating and seem insurmountable. But solving a
							lot of questions is the way to go. Learn the approach and keep on
							upgrading your toolkit to solve different types of questions.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							7. How many questions were asked about projects, clubs and
							extracurricular activities in your resume ?
						</b>
						<br />
						<p>
							For a lot of my friends, they were asked extensively about their
							projects. So it is very important to know your resume well and
							then on the what and why of your projects. I was even asked about
							my project on microcontroller programming, however I was not asked
							as deeply about my projects, instead they focused more on the
							concepts.
							<br />I had mentioned my participation in our Chess team and our
							conversation ended with a little talk about how I started to play,
							how we practise and how the team works.
						</p>
					</p>
					<br />
					<p>
						<b>
							{" "}
							8. Is there something specific that you would want to tell people
							who try for the same company ?
						</b>
						<br />
						<p>
							Not just for a particular company, but in general, keep working on
							yourself beyond academics. Focus on developing your personality
							and interpersonal skills as well. Being able to express your
							knowledge clearly is just as crucial as having the skills
							themselves. Don’t get overwhelmed, take it one step at a time, and
							stay persistent.
						</p>
					</p>
					<br />
					<p>
						<b> Any Final tips ?</b>
						<br />
						<p>
							There’s no substitute for hard work. The effort and hours you
							invest in learning any skill will pay off in ways you can’t always
							foresee, often bringing rewarding surprises along the way.
							<br />
							Trust the process, and keep moving forward!
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID :{" "}
						<a href="mailto:pratikbk22.extc@coeptech.ac.in">
							pratikbk22.extc@coeptech.ac.in
						</a>
						<br />
						{/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default Pratik_kardile;
