import React from "react";

const aditya_deshmukh = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Aditya Deshmukh
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Algoquant Fintech</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br />
                            The selection process consisted of the following stages:
                            <br />
                            1.	Aptitude                     <br />
                            2.	Three Technical.
                            <br />
                            The entire process was conducted in offline mode.


                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Students with a CGPA of 8.0 or above were shortlisted for the aptitude round.                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            The aptitude round was conducted immediately after the company's introductory presentation and comprised the following components:
                            <br />●	3 Coding Questions:
                            <br />Focused on Data Structures and Algorithms (DSA), ranging from above average to hard difficulty.
                            <br />Examples of questions included:
                            <br />1.	Image Processing Problem:You are given n images to process, each requiring processing within a specific time interval [startDay[i], endDay[i]] at a cost filterCost[i] per day.Additionally, a discounted rate discountPrice is available to process all images in a single day.The objective was to determine the minimum cost of processing all images, returning the result modulo 10^9 + 7.
                            <br />2.	Processor Problem: An array tasks[i] represents the number of tasks to be completed.Two processors with processing times A and B per task are available.The task was to find the minimum time required to complete all tasks by optimally distributing them between the two processors.
                            <br />3.	Longest Increasing Subsequence Problem: A problem centered around finding the longest increasing subsequence from a given array.
                            <br /><br />●	1 SQL Query:
                            <br />A database-related question designed to test SQL skills.

                            <br /><br />My Performance :
                            <br />●	Attempted the Longest Increasing Subsequence Problem using memoization. While it passed 10 out of 15 test cases, it resulted in a Time Limit Exceeded (TLE) error for the remaining cases.
                            <br />●	Partially attempted the Processor Problem, but I couldn't verify its correctness because the system auto-submitted my code before I could run it.
                            <br />●	Did not attempt the Image Processing Problem or the SQL question.
                            <br />Despite the challenges, candidates who solved 2 to 3 questions partially, including myself, were shortlisted for the next round, highlighting the weighted evaluation based on question difficulty.

                        </p>

                    </p>
                    {/* <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The test took place on 5th December and the Pre-Placement talk and  Interviews were scheduled on 9th December. The shortlist was announced few minutes before the interviews, so I had already prepared for the interviews after the test without waiting for the shortlist declaration. During this period, I solved few DSA questions on Leetcode just to remain in touch with coding.
                            <br />
                            I read the GFG articles for OOP in Java, and my handwritten notes for Computer Networks and DBMS revision. One good thing was that our end semester exam had just finished so I just needed revision for these subjects.
                            <br />
                            Also I had brushed up on all my projects and also prepared for some questions that were likely to be asked about these projects.
                            <br />
                            My major focus this time was on the HR interview preparation and I had prepared answers for probable questions which the HR might ask.


                        </p>

                    </p> */}
                    <br />
                    <p>
                        <b> 4. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        The interview process was comprehensive, covering a mix of DSA questions, C++ concepts, and system design problems. The interviewer placed significant emphasis on advanced C++ topics such as template metaprogramming, CRTP (Curiously Recurring Template Pattern), smart pointers, and low-latency optimizations. There was also a focus on problem-solving and mathematical reasoning
                        <br /> <b>First Technical Round:</b>
                        <br />The first round focused primarily on JavaScript concepts and asynchronous programming.
                        <br />Questions Asked:
                        <br />●	Singleton Design Pattern: I was asked to explain the Singleton pattern. I used the example of a Logger to explain it, which was deemed satisfactory.
                        <br />●	File Monitoring: The interviewer gave me a scenario where a file is continuously written to, and a command displays the end N lines of the file. He asked how the command would detect when new data is written and how to handle concurrency between the writing and reading operations. I wasn’t familiar with the specific Linux commands involved but discussed the need for event detection, triggers, and concurrency handling.
                        <br />●	Generator Functions: The interviewer asked me to explain generator functions and how the yield keyword works in JavaScript. I explained it well, but when asked about the control flow in the case where a generator function is called within a while loop, I couldn’t fully explain it. The interviewer helped me understand the concept more clearly.
                        <br />●	Event Loop: The interviewer asked me to explain the JavaScript event loop. He helped me refine my understanding of how asynchronous tasks are handled, including the call stack, callback queue, and microtask queue.
                        <br /><br />
                        <b>Second Technical Round: </b>
                        <br />

                        The second round focused on problem-solving and C++ concepts, and lasted 45–50 minutes.
                        <br />
                        Questions Asked:
                        <br />●	Skyscrapers Puzzle: The interviewer gave me a Skyscrapers puzzle, which is similar to Sudoku. The task was to fill a grid with numbers based on clues about the visibility of buildings. I initially tried to explain a programmatic approach using Longest Increasing Subsequence and DFS, but the interviewer asked me to solve it manually. After 15 minutes of trying, I couldn’t fully solve it, but later realized it was a 5x5 medium difficulty puzzle. More on Skyscrapers Puzzle : <a href="https://www.puzzles-mobile.com/skyscrapers/random/5x5-normal" target="_blank">Skyscrapers Puzzle (5x5 Normal)</a>

                        <br />●	DSA Questions: The interviewer asked a few DSA questions. Once I demonstrated my understanding, he skipped over most of the questions, confident in my abilities.
                        <br />●	C++ Smart Pointers: The interviewer moved on to meta-template programming in C++, particularly focusing on shared pointers for low-latency programs in High-Frequency Trading (HFT). He asked me to implement shared pointers. I was unable to fully implement it, but I explained the key operations involved.
                        <br />-	Learn more about shared_ptr in C++
                        <br />●	Doubly Linked List Problem: The interviewer gave me a problem on Doubly Linked Lists: moving a node to the second position without traversing the entire list. I attempted the problem, but he pointed out some mistakes and areas for improvement.
                        <br /><br />
                        <b>Third Technical Round:</b>
                        <br />I initially thought this round would be an HR round, but it turned out to be more technical. I went in without my resume, and the interviewer asked for a printed copy.
                        <br />Questions Asked:
                        <br />●	Probability Questions: The interviewer asked several probability-related questions.
                        <br />-	Conditional Probability: He asked about Bayes' Theorem and conditional probability. Since I wasn’t well-prepared, I answered using combinatorics and permutation logic, which didn’t satisfy the interviewer.
                        <br />-	Probability of Points in a Corner: The interviewer gave me a question about the probability of 5 randomly chosen points lying in the corner of a rectangle. I attempted to approach it by area ratio, but my explanation wasn’t sufficient.
                        <br />●	Follow-up Questions: After some unsatisfactory answers, the interviewer asked me to share something relevant. I spoke about my experience in hackathons and my DSA project. He continued probing further into my knowledge.
                        <br />●	Final Questions:Some general HR questions and At the end of the interview, the interviewer asked:
                        <br />-	Why do you want to join AlgoQuant?
                        <br />The round concluded with reflection on my motivation and background.

                    </p>
                    <br />
                    <p>
                        <b>5. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        C++ (meta-template programming), system design, puzzles, OS.

                    </p>
                    <br />
                    <p>
                        <b>6. How many students were shortlisted after each round? </b>
                        <br />
                        Aptitude Round: 9 students were shortlisted.
                        <br />Technical Round 1: 4 students progressed to the next round.
                        <br />Technical Round 2: 2 students were finally selected.

                    </p>
                    <br />
                    <p>
                        <b>7. Some good resources you used in your internship prep? </b>
                        <br />
                        <br />For internship preparation, the TY diary of my senior was incredibly helpful. Here are some other key resources:
                        <br />YouTube Channels:
                        <br />GeeksforGeeks – Good for C++ concepts, smart pointers.
                        <br />CodingInterviewPrep and karaniitgn0908 for advanced C++ and problem-solving.
                        <br /><a href="https://www.youtube.com/@CodingInterviewPrep" target="_blank">Coding Interview Prep</a><br />
                        <a href="https://www.youtube.com/@karaniitgn0908" target="_blank">karaniitgn0908</a>




                    </p>
                    <br />
                    <p>
                        <b> 8. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            <br />Striver’s DSA Sheet: Follow it for structured practice on essential data structures and algorithms. <a href="https://takeuforward.org/strivers-a2z-dsa-course/strivers-a2z-dsa-course-sheet-2/" target="_blank">Striver's A2Z DSA Course Sheet</a>

                            <br />Consistent Practice: Solve problems regularly, starting from easy to hard.
                            <br />Optimize: Always aim for more efficient solutions after brute force.
                            <br />Recognize Patterns: Identify common patterns in problems to solve them faster.
                            <br />Learn from Mistakes: Review optimal solutions and improve your approach.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 9. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            Only about projects (in C & C++)
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            For anyone applying to the same company, make sure you understand the role you're applying for and the specific skills required. In the case of a Quant Developer role, be prepared for topics like metaprogramming, low-latency programming, and advanced C++ concepts.
                            <br /> Familiarize yourself with the company's core focus areas to tailor your preparation effectively.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                            Stay calm and answer confidently based on what you know—don't hold back or regret your responses later. Do your best while you still can and focus on mastering problem-solving, as it will help you immensely. Remember, luck plays a significant role, but it’s beyond your control. Just keep moving forward and stay focused on your growth and learning.

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:adityasd314@gmail.com">
                            adityasd314@gmail.com
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default aditya_deshmukh;
