import React from "react";

const isha_khutafale = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						className="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div className="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Isha Khutafale
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>
						Deutsche Bank
					</h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<br />
						<p>
							Structure of the Interview Process:
							<br />
							1. Aptitude Round (Online):
							<br />
							a. MCQs (10)
							<br />
							b. Coding Questions (2)
							<br />
							Duration: 90 minutes.
							<br />
							2. Interview Rounds (Offline):
							<br />
							- Technical Round
							<br />
							- Professional Fitness Round
							<br />
							- HR Round
						</p>
					</p>
					<br />
					<p>
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<br />
						<p>
							<b>For Aptitude Round:</b>
							<br />
							- Equal weightage was given to both MCQs and coding questions.
							<br />
							- It is important to attempt the MCQ questions as they carry
							significant marks. Avoid skipping them.
							<br />
							- For coding questions, ensure that at least a few test cases
							pass, as this plays a crucial role in shortlisting.
							<br />
							<b>For Interview Process:</b>
							<br />
							- Confidence and technical knowledge are key factors for
							shortlisting during the interviews.
							<br />
							- A strong understanding of core computer science fundamentals is
							crucial, as the major focus will be on these concepts rather than
							specific areas like AI technologies.
							<br />
							- Proficiency in Data Structures and Algorithms (DSA) is
							particularly important, as technical questions are heavily
							focused on this area.
						</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round?</b>
						<br />
						<p>
							The aptitude round was divided into two sections:
							<br />
							<b>MCQs:</b>
							<br />
							This section consisted of 10 questions covering topics such as
							Computer Networks, DBMS, Operating Systems, OOPS, and Java-based
							code snippets.
							<br />
							The code snippets required predicting the output and tested
							fundamental programming concepts.
							<br />
							<b>Coding Round:</b>
							<br />
							This section included two coding questions of easy to medium
							difficulty.
							<br />
							The questions were based on topics like dynamic programming and
							binary search.
							<br />
							One of the problems involved calculating the Manhattan distance.
							<br />
							Duration: 90 minutes.
						</p>
					</p>
					<br />
					<p>
						<b>4. After being shortlisted, how long was the wait time before the interviews began? During this period, what specific areas did you focus on to prepare effectively?</b>
						<br />
						<p>
							There was no specific waiting time mentioned. During the
							interviews, I focused on revising core concepts such as DBMS,
							Operating Systems, Computer Networks, and Object-Oriented
							Programming (OOPS).
							<br />
							Practicing coding questions and staying calm was my main strategy.
						</p>
					</p>
					<br />
					<p>
						<b>
							5. How many rounds were there in the company, and can you elaborate
							about each round in brief?
						</b>
						<br />
						<p>
							<b>Technical Round:</b>
							<br />
							- This round started with my self-introduction, followed by a Data Structures and Algorithms (DSA) question based on linked lists. The problem was to detect a cycle in a linked list, which I explained clearly and in detail.
							<br />Next, the interviewer discussed my major project—an Android app developed using Java. They presented a scenario where I had to design an Android application and asked me to write the algorithm to approach the problem. The solution involved the use of priority queues, and I successfully wrote the algorithm.
							<br />The discussion then moved to Object-Oriented Programming (OOPs), including the core principles of OOP and some fundamental questions related to the subjects mentioned in my resume. This round lasted approximately 60 minutes.

							<br />
							<b>Professional Fitness Round:</b>
							<br />
							- This round focused on assessing my suitability for the role. The interviewer asked situational questions, such as:
							<br />If you were a team leader and a team member was struggling to complete or delaying their tasks, how would you handle it?
							<br />They also discussed another project from my resume, asking why I chose the problem statement and what challenges I faced during its implementation.
							<br />Additionally, they asked a puzzle, which I could not answer completely. Toward the end, they inquired about my knowledge of the company. Hence, it is crucial to research the company beforehand to handle such questions confidently.
							<br />This round lasted for 30–45 minutes.

							<br />
							<b>HR Round:</b>
							<br />
							- The HR round was quite chill. The questions included:
							<br />My self-introduction.
							<br />My college experiences, especially the challenges I faced and how I overcame them.
							<br />As a direct second-year student, how I transitioned from a diploma program to engineering and adapted to the new environment.
							<br />My achievements and leadership roles in various events.
							<br />This round was short, lasting about 15 minutes.

						</p>
					</p>
					<br />
					<p>
						<b>6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)</b>
						<br />
						<p>
							- Data Structures and Algorithms (DSA)
							<br />
							- Core computer science subjects like DBMS, OS, Networks, and OOPS
							<br />
							- Problem-solving, logical reasoning, and behavioral questions
						</p>
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round?</b>
						<br />
						<p>
							<b>Aptitude Round:</b> 35 students <br />
							<b>After Technical and Professional Fitness Rounds:</b> 11
							students <br />
							<b>Final Offers:</b> All 11 students received internship offers.
						</p>
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep?</b>
						<br />
						<p>
							- For DSA: Striver's A2Z DSA Course/Sheet
							<br />
							- For core subjects: GeeksforGeeks and Last-Minute Notes
						</p>
					</p>
					<br />
					<p>
						<b>9. Some tips to crack coding questions?</b>
						<br />
						<p>
							- First, identify the data structure that can be applied to the
							problem statement. <br />
							- Start with a brute force solution and then work on optimizing
							it. <br />
							- Make sure to think aloud so the interviewer understands your
							thought process.
						</p>
					</p>
					<br />
					<p>
						<b>10. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
						<br />
						<p>
						A significant number of questions were asked about my projects and involvement in clubs. It's important to thoroughly prepare your resume and be ready to discuss these areas in detail.

						</p>
					</p>
					<br />
					<p>
						<b>11. Is there something specific that you would want to tell people who try for the same company?</b>
						<br />
						<p>
							- Make sure your core concepts are clear, as the company places
							strong emphasis on them.
							<br />
							- Be prepared to explain your projects confidently and discuss your
							approach.
						</p>
					</p>
					<br />
					<p>
						<b>Any Final Tips?</b>
						<br />
						<p>
							<i>
								Once your aptitude is clear, remember that this is your
								opportunity to showcase your skills. Stay confident, focus on
								what you have prepared, and don’t worry about what others are
								doing. Make sure you know your strengths and are ready to
								demonstrate them.
							</i>
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID:{" "}
						<a href="mailto:ishakhutafale2004@gmail.com">
							ishakhutafale2004@gmail.com
						</a>
						<br />
					</div>
					{/* <div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Phone Number: 9405140973
						<br />
					</div> */}
				</div>
			</section>
		</div>
	);
};

export default isha_khutafale;