import React from "react";

const onkarBhagwat = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>
            Onkar Bhagwan Bhagwat
          </h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>
            Marvell Technology Group Ltd.’s
          </h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>
              The recruitment process consisted of an aptitude test followed by
              three rounds of interviews (two technical rounds and one HR
              round).
            </p>
          </p>
          <p>
            <br />
            <b>
              2. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>
              Performance in the aptitude test, particularly in technical topics
              such as Computer Networks and C Programming.
            </p>
          </p>
          <br />
          <p>
            <b>3. What was the structure of the Aptitude Round ? </b>
            <br />
            <p>
              <b>The aptitude test covered:</b>
              <br />
              • Computer Networks
              <br />
              • C Programming
              <br />
              • Reasoning
              <br />
              • Code Output Prediction
              <br />
            </p>
          </p>
          <p>
            <b>
              4. After being shortlisted, how long was the wait time before the
              interviews : began? During this period, what specific areas did
              you focus on to prepare effectively?
            </b>
            <br />
            <p>
              • The wait time was approximately 12 hrs.
              <br />• During this period, I focused on revising:
              <br /> - Data Structures and Algorithms (DSA)
              <br />  - Operating Systems (OS) concepts
              <br />  - Computer Networks basics
              <br />  - My project details and implementation
              <br />  - Behavioral questions
              <br />
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              5. How many rounds were there in the company, can you elaborate
              about each round in brief ?
            </b>
            <br />
            <p>
              <p>
                <b>Round 1: Technical Interview</b> <br />• Explain the concept
                of DSA, its types, and applications. <br />
                • Detailed discussion about my project: how it works,
                technologies used, and user perspective. <br />
                • Concepts of memory management and paging. <br />• Explanation
                of the TCP/IP layers and their functionality. <br />• Write a
                code snippet to implement a tree data structure. <br />
              </p>
              <p>
                <b>Round 2: Technical Interview</b>
                <br />
                • Provide an in-depth explanation of the DSA project, including
                examples of how it functions, the data structures used, and
                their alternatives.
                <br />
                • Explain debugging techniques for logical errors.
                <br />
                • Discuss Computer Networks topics like subnet masks. <br />
                • Discuss DSA concepts, such as drawing the structure of a
                doubly linked list.
                <br />
              </p>
              <p>
                {" "}
                <b>Round 3: HR Round </b>
                • Introduce yourself.
                <br />
                • Share details about your family background. <br />
                • What do you know about Marvell Technology?
                <br />
                • What are your expectations from the company?
                <br />
                • Do you have any questions?
                <br />
              </p>
            </p>
          </p>
          <br />
          <p>
            <b>
              6. What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <br />
            <b>• Technical Skills:</b> DSA, Computer Networks, Operating
            Systems, and project implementation.
            <br />
            <b>• Behavioral Skills:</b> Communication, self-awareness, and
            company knowledge. <br />
          </p>
          <br />
          <p>
            <b>7. How many students were shortlisted after each round? </b>
            <br />
            The exact number of shortlisted students was not disclosed.
          </p>
          <br />
          <p>
            <b>8. Some good resources you used in your internship prep? </b>
            • GeeksforGeeks for DSA and OS concepts.
            <br />
            • "Computer Networking: A Top-Down Approach" by Kurose and Ross for
            networking basics.
            <br />
            • InterviewBit and LeetCode for coding practice.
            <br />
            • YouTube tutorials for project-related technologies.
            <br />
          </p>
          <br />
          <p>
            <b> 9. Some tips to crack coding questions ?</b>
            <br />
            <p>
              {" "}
              1. Understand the problem thoroughly before jumping to solutions.
              <br />
              2. Focus on writing optimized and clean code.
              <br />
              3. Practice a variety of problems to improve problem-solving
              speed.
              <br />
              4. Always test your code with edge cases.
              <br />
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              10. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>
              Most questions were project-focused. My main project was discussed
              extensively in both technical rounds.
              <br />• No specific questions about clubs or extracurricular
              activities were asked.
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              11. Is there something specific that you would want to tell people
              who try for the same company ?
            </b>
            <br />
            <p>
              {" "}
              • Strong understanding of DSA and basic networking concepts is
              crucial.
              <br />
              • Be prepared to discuss your projects in depth, including
              alternative implementations.
              <br />
              • Show enthusiasm and interest in the company's work during the HR
              round.
              <br />
            </p>
          </p>
          <br />
          <br />

          <p>
            <b> Any Final tips ?</b>
            <br />
            <p>
              • Focus on building a solid foundation in core subjects like DSA,
              OS, and Computer Networks.
              <br />
              • Projects are an excellent way to showcase your technical
              skills—ensure you can explain every detail of your implementation.
              <br />
              • Stay confident and keep a positive attitude throughout the
              process.
              <br />
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            <a href="mailto:bhagwatonkar65@gmail.com"> 
            bhagwatonkar65@gmail.com
            </a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default onkarBhagwat;
