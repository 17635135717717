import * as React from "react";
// import Paper from "@mui/material/Paper";
import {
  Typography,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
/*import { codequestSr, codequestJr, columns } from "../assets/js/codequest2021";*/
import codequestsy from "../assets/content/CodeQuest3.0 - SY - Week 3.pdf"
import codequestty from "../assets/content/CodeQuest3.0 -TY Week 3.pdf"
import { codequestSr, codequestJr, columns } from "../assets/js/codequest2024";
import { tableCellClasses } from "@mui/material/TableCell";
import GetAppIcon from '@material-ui/icons/GetApp';


export default function CodequestRank() {
  const onDownload = (linkName, title) => {
    let a = document.createElement("a");
    a.href = linkName;
    // console.log(linkName);
    a.setAttribute("download", title);
    a.click();
  };
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#003399",
      color: theme.palette.common.white,
      fontFamily: 'Patua One',
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: 'Times New Roman',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    // <Paper >
    <Box sx={{ width: "80%", overflow: "hidden", margin: "auto" }}>
      {/* Junior Table */}
      <center>
        <Typography
          variant="overline"
          style={{ color: "white", fontSize: "1.5em" }}
        >
          CodeQuest 5.0 Junior Leaderboard
        </Typography>
      </center>
      <TableContainer
        sx={{ backgroundColor: "whitesmoke" }}
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ backgroundColor: "red" }}>
            <StyledTableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {codequestJr
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    // hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    sx={{ backgroundColor: "black" }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ color: "white" }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <center>
        {/* <Button

          variant="contained"
          color="primary"


          style={{
            padding: "15px 15px 15px 15px",
            marginLeft: "6px",
            marginBottom: "100px",
            backgroundColor: "#003399",
            fontSize: "1rem"
          }}
          onClick={() => onDownload(codequestsy, "CODEQUEST3_SY-WEEK3")}

        >

          <GetAppIcon />
          DOWNLOAD SCORES - SY
        </Button> */}
      </center>
      <br />


      {/* Senior Table */}
      <center>
        <Typography
          variant="overline"
          style={{ color: "white", fontSize: "1.5em" }}
        >
          CodeQuest 5.O Sr Leaderboard
        </Typography>
      </center>
      <TableContainer
        sx={{ backgroundColor: "whitesmoke" }}
        style={{ marginTop: "2em" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {codequestSr
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    // hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    sx={{ backgroundColor: "black" }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ color: "white" }}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <center>
        {/* <Button

          variant="contained"
          color="primary"
          style={{
            padding: "15px 15px 15px 15px",
            marginLeft: "6px",
            marginBottom: "100px",
            marginTop: "32px",
            backgroundColor: "#003399",
            fontSize: "1rem"
          }}
          onClick={() => onDownload(codequestty, "CODEQUEST3_TY-WEEK3")}

        >
          <GetAppIcon />
          DOWNLOAD SCORES - TY

        </Button> */}
      </center>

      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={codequestJr.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
    // </Paper>
  );
}
