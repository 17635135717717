import React from "react";

const soham_laghate = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Soham Laghate</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Science Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>Structure of Entire Interview Process:</b>
            <p>
              There were 3 rounds:
              <ol>
                <li>
                  <b>Technical round (40 mins):</b>
                  <p>
                    In the technical round, the interviewer began with a coding
                    question. It was a LeetCode question based on Stack Data
                    Structure. The interviewer then shifted to the projects
                    mentioned in my resume. He asked me to choose a project and
                    explain the functionalities, tech stack, algorithms used, and
                    also the future scope and limitations. He asked me my
                    favourite subject and then asked a few questions based on
                    that subject.
                  </p>
                </li>
                <li>
                  <b>Professional Fitness Round (40 mins):</b>
                  <p>
                    This round was more like an HR round where a lot of
                    questions related to general knowledge were asked. The
                    interviewer wanted to judge how deep I can think about a
                    topic. A few technical questions related to OOPS were asked.
                  </p>
                </li>
                <li>
                  <b>HR Round (10 mins):</b>
                  <ul>
                    <li>
                      This round included questions that tested my confidence
                      and language. Introduction, failures and learnings from
                      college, team building, and why DB were a few points
                      covered.
                    </li>
                  </ul>
                </li>
              </ol>
            </p>
          </p>
          <br />

          <p>
            <b>Structure of Aptitude Round:</b>
            <p>
            <br /> Aptitude round (online)  had 2 sections:

            <br /> 1.	MCQs : It had 10 Questions based on Computer Networks, DBMS , Operating Systems, OOPS, Java.
            <br /> 2.	2 Coding Questions (Difficulty: Easy to medium). Duration of the Aptitude round was 90 min.

            </p>
          </p>
          <br />

          <p>
            <b>How many students were shortlisted after each round?</b>
            <p>
              Post aptitude test almost 32 students were shortlisted. After
              each round, a few students were shortlisted for the next round.
              Finally, 11 students were offered an internship.
            </p>
          </p>
          <br />

          <p>
            <b>
              After being shortlisted, how long was the wait time before the
              interviews began? During this period, what specific areas did you
              focus on to prepare effectively?
            </b>
            <p>
              After being shortlisted, we only had 1 day in between to prepare.
              I focused on revising a few concepts like DBMS, OOPS, and Java.
            </p>
          </p>
          <br />

          <p>
            <b>
              What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <p>DSA, OOPS, and Behavioral questions were asked the most along with projects.</p>
          </p>
          <br />

          <p>
            <b>
              How many questions were asked about projects, clubs, and
              extracurricular activities in your resume?
            </b>
            <p>
              In the first round, I was asked thoroughly about my projects. In
              the last two rounds, I was asked about the clubs/fests that I was
              a part of and how it shaped my personality. Hobbies were also
              asked in the last round.
            </p>
          </p>
          <br />

          <p>
            <b>Some good resources you used in your internship prep:</b>
            <ul>
              <li>LeetCode</li>
              <li>DSA in C by Abdul Bari</li>
            </ul>
          </p>
          <br />

          <p>
            <b>Some tips to crack coding questions:</b>
            <ul>
              <li>Practice LeetCode daily.</li>
              <li>Stick to one language and master it.</li>
            </ul>
          </p>
          <br />

          <p>
            <b>
              Is there something specific that you would want to tell people
              who try for the same company?
            </b>
            <p>
              Portray yourself well in the interview. Show your eagerness to
              learn new things.
            </p>
          </p>
          <br />

          <p>
            <b>Final Tips for Juniors:</b>
            <ul>
              <li>
                Just practice as much as you can and as well as you can. The
                placement process is very stressful but just hang in there.
                Begin the process of preparation well early. Speak about the
                things you know and be confident. And again, portray yourself
                well :))
              </li>
            </ul>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:{" "}
            <a href="mailto:sohamlaghate@gmail.com">sohamlaghate@gmail.com</a>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default soham_laghate;
