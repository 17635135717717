import React from "react";

const shreyas_katkar = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						class="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div class="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Shreyas Katkar
					</h2>
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>
                        UBS
                    </h5>
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<br />
						<p>
							Structure of Entire Interview Process: Aptitude Test <br />
							Interview round: For Technical and HR round was combined due to time
							constraints.
						</p>
					</p>
					<br />
					<p>
						<b>
							2. What were the criteria for shortlisting candidates in the
							initial stages of the recruitment process?
						</b>
						<br />
						<p>I don’t know it exactly</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round?</b>
						<br />
						<p>
							The Aptitude test consisted of 1 coding question and 30 other
							questions from DBMS, Computer Networks, Logical Reasoning,
							Quantitative ability, and also questions related to languages C++
							and Java.
						</p>
					</p>
					<br />
					<p>
						<b>
							4. After being shortlisted, how long was the wait time before the
							interviews began? During this period, what specific areas did you
							focus on to prepare effectively?
						</b>
						<br />
						<p>
							We were given time of 9 am but recruitment team arrived around
							10:15 am and interviews started from 10:30 am. <br />
							Since both placements and internship interviews were taking place
							concurrently, it was taking a long time. <br />
							My interview started around 4 pm, in the meantime I revised basic
							OOPS concepts, revised my resume, and visualized the interview
							process so that I don’t panic in the interview.
						</p>
					</p>
					<br />
					<p>
						<b>
							5. How many rounds were there in the company, can you elaborate
							about each round in brief?
						</b>
						<br />
						<p>
							Because of a shortage of time, my Technical and HR rounds were
							merged. <br />
							My interview lasted for 25-30 mins. <br />
							I was asked about OOPS concepts, about projects in brief, and 2
							coding questions which were of easy to medium level. <br />
							In HR part, I was asked about my scores in JEE and CET and how I
							decided to join COEP.
						</p>
					</p>
					<br />
					<p>
						<b>
							6. What topics or skills did the interviewers focus on during the
							interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
							Design, or Behavioral questions)
						</b>
						<br />
						<p>
							In the interview, the focus was on OOPS concepts, although 2 DSA
							coding questions were given, they were on the easier side.
						</p>
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round?</b>
						<br />
						<p>
							30 students were shortlisted for interviews and 5 students were
							given final offer.
						</p>
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep?</b>
						<br />
						<p>
							Resources that I used for: <br />
							Geeks for Geeks for Basic concepts in OOPs, Computer Networks,
							DBMS. <br />
							Leetcode, GFG, and Love Babbar DSA sheet for practicing coding
							questions. <br />
							Indiabix for aptitude test preparation.
						</p>
					</p>
					<br />
					<p>
						<b>9. Some tips to crack coding questions?</b>
						<br />
						<p>
							Keep practicing coding questions on Leetcode. <br />
							Questions will not be that hard in interviews, just keep calm and
							try to recall concepts you learned while practicing. <br />
							Don’t panic.
						</p>
					</p>
					<br />
					<p>
						<b>
							10. How many questions were asked about projects, clubs, and
							extracurricular activities in your resume?
						</b>
						<br />
						<p>
							I was asked to just briefly explain projects, they didn’t go deep
							into the concepts of the project. <br />
							1-2 questions regarding clubs and extracurricular activities.
						</p>
					</p>
					<br />
					<p>
						<b>
							11. Is there something specific that you would want to tell people
							who try for the same company?
						</b>
						<br />
						<p>
							UBS prefers candidates with C++ as their primary language as far as
							I have observed.
						</p>
					</p>
					<br />
					<p>
						<b>Any Final Tips?</b>
						<br />
						<p>
							<i>
								There is a lot of luck factor involved in the internship
								selection process. Just believe in yourself and don’t lose hope.
							</i>
						</p>
					</p>
					<br />

					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID:{" "}
						<a href="mailto:shreyas3920@gmail.com">shreyas3920@gmail.com</a>
						<br />
					</div>
					{/* <div style={{ color: "gray", fontSize: "1.15rem" }}>
					<p>
  					<strong>Phone Number:</strong> 9860177997
					</p>
					</div> */}
				</div>
			</section>
		</div>
	);
};

export default shreyas_katkar;