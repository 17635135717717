import React from "react";

const parasDhole = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Paras Dhole</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>NPCI</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>HR Questions + Technicals Questions</p>
          </p>
          <p>
            <br />
            <b>
              2. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>No criteria as such, just needed a CGPA of 7+.</p>
          </p>
          <br />
          <p>
            <b>3. What was the structure of the Aptitude Round ? </b>
            <br />
            <p>
              AI/ML Questions, Essay Writing (approx 200 words), Logical
              Aptitude, Quantitative Aptitude, Probability Aptitude and 2 coding
              questions (1 medium + 1 hard)
            </p>
          </p>
          <p>
            <b>
              4. After being shortlisted, how long was the wait time before the
              interviews : began? During this period, what specific areas did
              you focus on to prepare effectively?
            </b>
            <br />
            <p>
              The interviews lasted 30-45 minutes. The interviewers mostly
              focused on the resume, so that’s what I focused on before my
              interview.
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              5. How many rounds were there in the company, can you elaborate
              about each round in brief ?
            </b>
            <br />
            <p>Just one interview round which consisted of HR+Technical.</p>
          </p>
          <br />
          <p>
            <b>
              6. What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <br />
            <p>
              The entire interview revolved around my resume, with a strong
              focus on my projects and the programming languages I had listed.
              They began by asking me to briefly explain my projects and
              followed up with counter-questions about why I chose specific
              languages or tech stacks for those projects.
            </p>
            <p>
              Once the discussion on my projects was complete, they shifted to
              the skills section of my resume. They questioned me on every skill
              I had mentioned, including various web development technologies,
              C/C++, Java, SQL, and MongoDB. They started with an SQL query and
              then asked me to rewrite the same query in MongoDB. Afterward,
              they moved on to generic questions about web development, where
              they asked me specifically about JavaScript and React.
            </p>
            <p>
              As for DSA, they didn’t focus much on it. They asked a few
              questions about LinkedLists, including implementing one, and gave
              me a medium-level coding problem based on LinkedLists to solve.
            </p>
          </p>
          <br />
          <p>
            <b>7. How many students were shortlisted after each round? </b>
            <br />
            There were only 2 rounds, Apti and then interview. After the
            aptitude, 10 people were shortlisted for the HR+Technical
            interviews.
          </p>
          <br />
          <p>
            <b>8. Some good resources you used in your internship prep? </b>
            <br />
            Striver, NeetCode, TLE Eliminators, FreeCodeCamp, Hitesh Choudhary,
            NetNinja, LeetCode (ofc).
            <br />
            Cracking the coding Interview by Gayle, Laakmann and McDowell.
          </p>
          <br />
          <p>
            <b> 9. Some tips to crack coding questions ?</b>
            <br />
            <p>Practice makes perfect.</p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              10. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>None</p>
          </p>
          <br />
          <br />
          <p>
            <b> Any Final tips ?</b>
            <br />
            <p>
              Don’t stress yourself out during internship or placement periods.
              There will be ups and downs, but once you set a path and stay
              determined, you’ll definitely achieve your goal!
            </p>
          </p>
          <br />

          {/* <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            +91-8421270069
            <br />
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default parasDhole;
