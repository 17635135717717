import React from "react";

const Riti = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        className="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div className="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>Riti Panganti</h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Marvell</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>The interview process consisted of three main rounds:
                            <ul>
                                <li>Technical Round 1 (Focused on Data Structures and Algorithms)</li>
                                <li>Technical Round 2 (Advanced DSA Problems)</li>
                                <li>HR Round</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Candidates were shortlisted based on:
                            <ul>
                                <li>Performance in the aptitude round.</li>
                                <li>Academic records.</li>
                                <li>Resume quality, particularly relevant projects and extracurricular activities.</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>3. What was the structure of the Aptitude Round?</b>
                        <p>The aptitude round consisted of reasoning and problem-solving questions. Key topics included:
                            <ul>
                                <li>Logical Reasoning</li>
                                <li>Data Structures Basics</li>
                                <li>Sorting and Searching Algorithms</li>
                                <li>Basic Programming Concepts</li>
                                <li>Linux Commands and OS-level Operations</li>
                            </ul>
                            The aptitude questions were multiple-choice, and some tested knowledge of programming, Linux commands, and data structures.
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>4. After being shortlisted, how long was the wait time before the interviews began?</b>
                        <p>
                            The list of shortlisted candidates for the interviews was released 4 days after the test and 1 day prior to the interviews. After being shortlisted, I focused on:
                            <ul>
                                <li>How Java works</li>
                                <li>OOPS in Java</li>
                                <li>Searching & sorting algorithms</li>
                                <li>DSA</li>
                            </ul>
                            I also went through the projects listed on my resume in detail, revised DBMS concepts, and practiced basic SQL queries. Prior to the interview, I prepared a proper introduction for the technical round. The panel focused on the topics I mentioned in my intro.
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>5. How many rounds were there in the company? Can you elaborate on each round briefly?</b>
                        <p>
                            <b>Technical Round 1:</b>
                            <ul>
                                <li>Began with basic questions on C programming, such as pass-by-value vs pass-by-reference and the difference between global and local variables.</li>
                                <li>Discussed arrays and linked lists. I explained using examples from my project.</li>
                                <li>Solved two coding problems:
                                    <ul>
                                        <li>Find the Kth node in a linked list.</li>
                                        <li>Count the occurrences of each letter in my name.</li>
                                    </ul>
                                </li>
                                <li>Ended with a puzzle to find a faulty coin in a stack of 8 coins using minimum weighing.</li>
                            </ul>
                            <b>Technical Round 2:</b>
                            <ul>
                                <li>Involved solving three DSA problems:
                                    <ul>
                                        <li>Check if a given string is a palindrome.</li>
                                        <li>Find a substring in a given string.</li>
                                        <li>Add a number recursively.</li>
                                    </ul>
                                </li>
                            </ul>
                            <b>HR Round:</b>
                            <ul>
                                <li>Online round where I introduced myself and answered questions about my family background.</li>
                                <li>Discussed my interest in joining Marvell Technology.</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>6. What topics or skills did the interviewers focus on during the interviews?</b>
                        <p>
                            <ul>
                                <li>Data Structures and Algorithms (DSA)</li>
                                <li>Puzzles</li>
                                <li>Problem-solving approaches</li>
                                <li>Basic programming concepts (C, recursion, linked lists, arrays)</li>
                                <li>Behavioural questions in the HR round</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>7. How many students were shortlisted after each round?</b>
                        <p>
                            <ul>
                                <li>After the aptitude round: Approximately 27 students were shortlisted.</li>
                                <li>After the first technical round: 10 students advanced to the second technical round.</li>
                                <li>Final selections: 7 students were chosen for offers.</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>8. Some good resources you used in your internship prep?</b>
                        <p>
                            <ul>
                                <li><b>DSA Preparation:</b> Kunal Kushwaha’s YouTube channel (concepts are explained in detail for Java)</li>
                                <li><b>Linux Commands:</b> Kunal Kushwaha’s tutorials</li>
                                <li><b>DBMS:</b> Jenny’s Lectures, Gate Smasher</li>
                                <li><b>Computer Networks:</b> Gate Smasher</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>9. Some tips to crack coding questions?</b>
                        <p>
                            <ul>
                                <li>While solving each question, discuss your approach with the interviewer.</li>
                                <li>Explain your thought process step by step.</li>
                                <li>Practice problems from platforms like LeetCode and HackerRank.</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>10. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
                        <p>
                            The interviewer asked in detail about each hobby and club mentioned in my resume, ensuring I could substantiate my involvement and experiences.
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>11. Is there something specific that you would want to tell people who try for the same company?</b>
                        <p>
                            <ul>
                                <li>Be confident and clear in explaining your thought process.</li>
                                <li>Avoid mentioning future education plans.</li>
                                <li>Showcase enthusiasm for joining Marvell Technology.</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <div>
                        <b>Any final tips?</b>
                        <p>
                            <ul>
                                <li>Focus on building strong fundamentals in DSA and problem-solving.</li>
                                <li>Use online resources and tutorials effectively for preparation.</li>
                                <li>Be proactive in discussing your solutions during interviews.</li>
                            </ul>
                        </p>
                    </div>
                    <br />

                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <p>Email-ID: <a href="mailto:ritisp22.comp@coeptech.ac.in">ritisp22.comp@coeptech.ac.in</a></p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Riti;
