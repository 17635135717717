import React from "react";

const saurabh_deulkar = () => {
	return (
		<div>
			<section id="content">
				<a href="../diaries.html">
					<span
						className="fa fa-arrow-left"
						style={{
							fontSize: "2rem",
							position: "absolute",
							marginTop: "2rem",
						}}
					></span>
				</a>
				<div className="container text-justify">
					<h2 style={{ lineHeight: "unset", margin: "unset" }}>
						Saurabh Deulkar
					</h2>
					<br />
					<h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Bank</h5>
					<br />
					<div style={{ color: "gray", marginTop: "0.5rem" }}>
						<p style={{ fontStyle: "oblique" }}>
							Computer Engineering, Batch of '26
						</p>
					</div>
					<br />

					<p>
						<b>1. What was the structure of the entire interview process?</b>
						<br />
						<p>
							1. Online Aptitude Test<br />
							2. One Technical Interview<br />
							3. One HR Interview<br />
						</p>
					</p>
					<br />
					<p>
						<b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
						<br />
						<p>CGPA above 7.</p>
					</p>
					<br />
					<p>
						<b>3. What was the structure of the Aptitude Round?</b>
						<br />
						<p>
							The aptitude round was conducted online and had four sections (for
							ICG Tech Portfolio):<br />
							- Coding section: 2 coding questions of easy to medium difficulty.<br />
							- Section 2: Basic questions on Angular (12 questions).<br />
							- Section 3: Basic questions on React (12 questions).<br />
							- Section 4: Questions on SQL (12 questions).<br />
						</p>
					</p>
					<br />
					<p>
						<b>4. After being shortlisted, how long was the wait time before the interviews began? During this period, what specific areas did you focus on to prepare effectively?</b>
						<br />
						<p>
							After the Aptitude round, there were 4 days in between.<br />
							In that time, I focused on my projects and basically what I had
							mentioned in my resume.
						</p>
					</p>
					<br />
					<p>
						<b>5. How many rounds were there in the company, can you elaborate about each round in brief?</b>
						<br />
						<p>
							<b>Technical Round:</b><br />
							The interview started with an introduction. I was asked to
							describe my journey, qualifications, skills, interests, and future
							goals. Follow-up questions were asked for clarification.<br />
							I was then asked to introduce my DSA project on the Huffman Text
							Compression Algorithm. I explained it for 10 minutes, and
							technical questions were raised about intermediate steps and how
							the algorithm works.<br />
							The discussion moved to my web development project. Questions
							focused on technologies used, their advantages, and implementation
							details.<br />
							The discussion lasted 45-50 minutes and concluded with a Q&A where
							I asked about the future of banking sector companies.<br />
							<b>HR Round:</b><br />
							The HR interview lasted 15-20 minutes. It began with a brief
							introduction, including my name and hobbies.<br />
							The interviewer asked about the non-technical aspects of my
							development project. After a short explanation, the focus shifted
							to general questions like "Why do you want to join Citi?"<br />
							The round ended with me asking a question to the interviewer.<br />
						</p>
					</p>
					<br />
					<p>
						<b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)</b>
						<br />
						<p>
							The primary focus was on projects and their technical
							aspects.<br />
							Some candidates reported being asked general GFG puzzles and
							questions on OS and Networks if their projects were related to
							those domains.
						</p>
					</p>
					<br />
					<p>
						<b>7. How many students were shortlisted after each round?</b>
						<br />
						<p>
							For ICG Tech:<br />
							- After the online aptitude test, 34-36 students were shortlisted
							for the interview round.<br />
							- 12 students were shortlisted after the first technical round.<br />
							- After the HR Round, 9 received the final internship offer.
						</p>
					</p>
					<br />
					<p>
						<b>8. Some good resources you used in your internship prep?</b>
						<br />
						<p>
							<b>DSA:</b> Striver A2Z sheet and videos.<br />
							<b>Puzzle, DBMS, OS, Networks:</b> GFG, InterviewBit.<br />
							<b>Behavioral questions:</b> Past interview experiences on various
							websites.<br />
						</p>
					</p>
					<br />
					<p>
						<b>9. Some tips to crack coding questions?</b>
						<br />
						<p>
							Review previously asked questions for the company.<br />
							Practice daily on platforms like LeetCode.<br />
							Focus on covering standard DSA questions.<br />
							Many companies, including Citi, often ask standard problems.
						</p>
					</p>
					<br />
					<p>
						<b>10. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
						<br />
						<p>
							My entire interview focused on projects.<br />
							No questions were asked about clubs or extracurricular
							activities.<br />
						</p>
					</p>
					<br />
					<p>
						<b>11. Is there something specific that you would want to tell people who try for the same company?</b>
						<br />
						<p>
							Prepare your projects thoroughly.<br />
							Understand their full functionality, advantages, and potential
							improvements.<br />
							Build diverse projects across fields like DSA, web development,
							ML, app development, etc.<br />
						</p>
					</p>
					<br />
					<p>
						<b>Any Final Tips?</b>
						<br />
						<p>
							<i>
								Start preparing early. Don't wait until the last moment.<br />
								Learn actively using various resources, focusing on cracking
								interviews rather than deep theoretical knowledge if your goal
								is placement.<br />
								Stay calm and confident. Reach out to friends and family during
								the process to manage stress. Believe in yourself; even if you
								fumble a question, the outcome can still be positive.<br />
							</i>
						</p>
					</p>
					<br />
					<h3>Contact Info:</h3>
					<div style={{ color: "gray", fontSize: "1.15rem" }}>
						<br />
						Email-ID:{" "}
						<a href="mailto:saurabhdeulkar11@gmail.com">
							saurabhdeulkar11@gmail.com
						</a>
						<br />
					</div>
					<br />
					{/* <div style={{ color: "gray", fontSize: "1.15rem" }}>
						<p>
							<strong>Phone Number:</strong> 8010175662
						</p>
					</div> */}
				</div>
			</section>
		</div>
	);
};

export default saurabh_deulkar;