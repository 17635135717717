import React from "react";

const ishita_gaikwad= () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                    Ishita Gaikwad
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Deutsche Bank</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                        We were made to go through 3 rounds. First was a basic DSA round which lasted around 50mins. I was asked in depth about DSA, CN, OOPs, DBMS and computer fundamentals however most people were asked about DSA only. This was completely conceptual, problem solving based round where I had to illustrate my solutions as well. Round 2 was a professional fitness round where my projects were discussed in detail, it lasted around 45mins. Round 3 was an HR round with basic HR questions, lasting around 10mins.

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                        First round was completely problem solving based and your proficiency in it while second was more about how much knowledge one had in the projects they worked with.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                        The online apti had about 10-15 mcqs about computer fundamentals and 2 medium-high level coding questions.
                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews :
                            began?</b><br/><p> First round began within like 90 mins after ppt. Second round was about 1-1.5 hours after this. I revised through LMNs available on GFG.
</p>
                        
                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        In round 1 was asked DSA algorithms, Java questions(since I said my preferred programming language was java), dbms, oops and CN. In dsa I was asked to find solutions for problems like TSP and was made to write it down. During the CN part, I was asked about TCP, UDP, firewalls, etc. During DBMS i wasn’t questioned a lot, only basics about keys and restraints. OOPs was asked in depth, from what are the 4 pillars to multithreading everything was asked in depth. In round 2 I was asked about my project, why I chose them and how I ran tests on them. I was also questioned about skills/subjects on my resume. I was asked about gates since I mentioned Digital Logic Design and OSI model with HTTP/HTTPS was discussed in detail.
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>
                        <br />
                        OOPs, DSA, CN, Java, DBMS, core subjects mentioned in resume.
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        About 33 people were shortlisted after the aptitude round for Intern role. 
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br />
                        Striver’s SDE sheet and GFG LMN’s for last minute prep.

                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                        Being able to translate your answer is the main point, being correct always is not necessary, your problem solving ability is checked.
 </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>I was mostly asked about Projects only and Tedx in pro-fit round as my interviewer was a part of Tedx as well.
                        </p>
                    <br /></p>
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                        Be prepared for in depth DSA questions.</p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Stay consistent, it always works. It’s never about what your CG is or how well you do in the interview, everything will eventually work out for the best with or without an internship.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:gaikwadia22.comp@coeptech.ac.in"></a>
                        gaikwadia22.comp@coeptech.ac.in

                        
                        <br />

                    </div>
                </div>
            </section >
        </div >
    );
};

export default ishita_gaikwad;
