import React from "react";

const suyog_arlimar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Suyog Arlimar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Wells Fargo</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br />The internship process consisted of 4 main rounds:
                            <br />1.	Aptitude Round: The initial elimination round to assess basic skills.
                            <br /> 2.	First Technical Interview (Virtual): Focused on core technical topics.
                            <br /> 3.	Second Technical Interview (In-person): A deeper dive into the resume, projects, and technical skills.
                            <br /> 4.	HR Interview (In-person): Assessed soft skills, family background, and extracurricular activities.



                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            Students above 7.5 cgpa were shortlisted for the test. Selection for the interviews was based on the test scores.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            1.	Finance-related questions to evaluate basic financial knowledge.
                            <br />2.	General aptitude questions, testing problem-solving and logical reasoning.
                            <br /> 3.	Three coding problems:
                            <br /> ○	Two easy-level coding problems.
                            <br />○	One medium-level coding problem.


                        </p>

                    </p>
                    {/* <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The test took place on 5th December and the Pre-Placement talk and  Interviews were scheduled on 9th December. The shortlist was announced few minutes before the interviews, so I had already prepared for the interviews after the test without waiting for the shortlist declaration. During this period, I solved few DSA questions on Leetcode just to remain in touch with coding.
                            <br />
                            I read the GFG articles for OOP in Java, and my handwritten notes for Computer Networks and DBMS revision. One good thing was that our end semester exam had just finished so I just needed revision for these subjects.
                            <br />
                            Also I had brushed up on all my projects and also prepared for some questions that were likely to be asked about these projects.
                            <br />
                            My major focus this time was on the HR interview preparation and I had prepared answers for probable questions which the HR might ask.


                        </p>

                    </p> */}
                    <br />
                    <p>
                        <b> 4. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        <br />1.	Aptitude Round:
                        <br />○	Covered a mix of finance-related and general aptitude questions along with coding problems.
                        <br />2.	First Technical Interview (Virtual):
                        <br />○	Topics included:
                        <br /> ■	Data Communications.
                        <br />  ■	Writing and explaining SQL queries.
                        <br />  ■	Solving DSA problems.
                        <br />  ■	Solving a puzzle (Camel Puzzle from GFG).
                        <br />  ■	Python basics.
                        <br />  3.	Second Technical Interview (In-person):
                        <br />  ○	A detailed discussion on:
                        <br />  ■	Projects and technical experiences from the resume.
                        <br />  ■	Additional DSA problems.
                        <br />  ■	General technical questions to test core understanding.
                        <br />  4.	HR Interview (In-person):
                        <br />  ○	Covered:
                        <br />  ■	Family background.
                        <br />  ■	Extracurricular activities.
                        <br />  ■	Resume highlights.
                        <br />  ■	Discussion about college(where he subtly tested presence of mind through some light-hearted remarks about the college).


                    </p>
                    <br />
                    <p>
                        <b>5. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>

                        <br />○	Data Structures and Algorithms (DSA).
                        <br /> ○ SQL queries and basic DBMS concepts.
                        <br />○	Puzzles to assess logical thinking.
                        <br />  ○ Networking fundamentals.
                        <br />  ○ Python proficiency (if mentioned in your resume).
                        <br />  ○ Communication skills were emphasized.

                    </p>
                    <br />
                    <p>
                        <b>6. How many students were shortlisted after each round? </b>
                        <br />
                        1.	From the Aptitude Round, 22 or 23 students were selected.
                        <br />2.	After the First Technical Interview, 13 students were shortlisted for the subsequent rounds.
                        <br />3.	Ultimately, 11 students were selected after all rounds.


                    </p>
                    <br />
                    <p>
                        <b>7. Some good resources you used in your internship prep? </b>
                        <br />
                        ○	LeetCode
                        <br /> ○	Striver’s DSA Sheet
                        <br /> ○	GeeksforGeeks (GFG)
                        <br /> ○	GeeksforGeeks puzzles section

                        <br />

                    </p>
                    <br />
                    <p>
                        <b> 8. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            1.	Practice consistently on platforms like LeetCode and GFG.
                            <br /> 2.	Understand concepts thoroughly instead of memorizing solutions.
                            <br /> 3.	Solve puzzles regularly to improve problem-solving speed.
                            <br /> 4.	Maintain calmness and confidence during the interview.


                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 9. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            ●	These were mainly discussed in the second technical and HR interviews.
                            <br /> ●	Focused on:
                            <br /> 1.	Projects mentioned in the resume.
                            <br /> 2.	Contributions to clubs.
                            <br /> 3.	Achievements in extracurricular activities.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            ●	Be confident and clear while discussing your resume and technical topics.
                            <br /> ●	Prepare thoroughly for both technical and behavioral aspects of the interview.
                            <br /> ●	Communication skills are critical for this company.

                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        <br /> ●	Stay calm and confident throughout the process.
                        <br /> ●	Focus equally on communication skills and technical preparation.
                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:arlimarsr22.comp@coeptech.ac.in">
                        arlimarsr22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default suyog_arlimar;
