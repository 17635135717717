import React from "react";

const vaishnavi_shelar = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        class="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div class="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Vaishnavi Shelar
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>Pattern Technologies</h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>

                        <b>1. What was the structure of the entire interview process?</b>
                        <p>
                            <br /> Aptitude round
                            <br /> Technical interview
                            <br /> HR interview

                        </p>
                    </p>
                    <p>
                        <br />
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            <br /> Eligibility for the Aptitude Test: All Computer Engineering students with a CGPA of 8 or above were allowed to appear for the aptitude test.

                            <br />  Shortlisting After the Test: The top 16 candidates who scored the highest marks in the least amount of time were shortlisted for the next round.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round ? </b>
                        <br />
                        <p>
                            <br />

                            MCQ based on tank problems
                            <br />3 coding questions
                            <br /> To find the local extrema from the given array.
                            <br /> Fix the code to determine if a given string is a valid credit card number
                            <br /> A valid credit card number should:

                            <br />  i)Contain exactly 16 digits.

                            <br /> ii)Contain only digits (0-9).

                            <br /> iii)Pass the Luhn algorithm

                            <br />  Library management problem
                            <br /> Ahmad the new library manager, wants to organize books in the best way possible. He has N books sath hing W₁ width and H₁ height.

                            <br /> He wants to stack his books on some bookshelves. However, he has to stack his books in order. This means that books from 1 to k(for some value of k) should be stacked on the first shelf, stacking on the second shef should start from book k+1, and so on.

                            <br /> Sql queries
                            <br /> 3 queries related to filtering, grouping data using GROUP BY, and similar operations.

                        </p>

                    </p>
                    <p>
                        <b>4. After being shortlisted, how long was the wait time before the interviews
                            began? During this period, what specific areas did you focus on to prepare effectively?
                        </b>
                        <br />
                        <p>
                            The test was conducted on 5th December 2024, followed by the presentation (PPT) on 9th December 2024. The shortlisting for the interviews was announced immediately after the presentations, and the interviews began shortly after.

                        </p>

                    </p>
                    <br />
                    <p>
                        <b> 5. How many rounds  were there in the company, can you elaborate about each round in brief ?</b>
                        <br />
                        Aptitude round
                        <br />
                        Technical interview
                        <br />
                        The first round of my interview lasted about 60 to 70 minutes and covered a variety of topics, including OOP concepts, my project, APIs, DSA, SQL, and system design.

                        <br />
                        Object-Oriented Programming (OOP) Concepts
                        <br />
                        The interview began with questions about basic OOP concepts like classes, objects, and method overloading. I was then asked to explain these concepts by using the room we were in as an example, identifying what could be considered as classes and objects.

                        <br />
                        Project and API Discussion
                        <br />
                        Next, the discussion moved to my project . I was asked to:

                        <br />
                        the database schema for my project, explain the relationships between tables, and describe the cardinality.

                        <br />
                        Talk about specific features I implemented, explain how I approached them, and suggest alternative methods for implementing them.

                        <br />
                        Explain the difference between REST and SOAP APIs, their use cases, and which one I preferred for certain situations.

                        <br />
                        DSA Question
                        <br />
                        The DSA question involved two input arrays:

                        <br />
                        The first array had scrambled words (e.g., atpetnr).

                        <br />
                        The second array had a sequence of indices (e.g., 2315476).

                        <br />
                        The task was to reorder the scrambled word based on the indices in the second array to form the correct word (pattern).

                        <br />
                        SQL Queries
                        <br />
                        I was asked basic SQL queries involving simple database operations, such as selecting and filtering data.

                        <br />
                        System Design: Chess Game
                        <br />
                        Finally, I was asked to design the structure of a chess game. I had to suggest appropriate data structures for the board and pieces, and explain how algorithms like dynamic programming and backtracking could be used for features like move validation and checkmate detection.

                        <br />
                        HR interview

                        <br />
                        In the HR round, I was asked about my hobbies and how they keep me motivated, along with my favorite subject and why I enjoy it. We also discussed my family background, where I shared how they have influenced my journey. Additionally, I spoke about some of my good and bad experiences, focusing on what I learned from them and how they shaped my perspective.
                    </p>
                    <br />
                    <p>
                        <b>6. What topics or skills did the interviewers focus on during the interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System Design, or Behavioral questions)
                        </b>

                        <br /> OOPs
                        <br /> DBMS
                        <br /> System Design

                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round? </b>
                        <br />
                        After the aptitude round 16 students were shortlisted for interview and 4 students received the internship offer.

                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep? </b>
                        <br /> Striver DSA sheet
                        <br />Leetcode
                    </p>
                    <br />
                    <p>
                        <b> 9. Some tips to crack coding questions ?</b>
                        <br />
                        <p>
                            I would recommend solving 1-2 coding problems every day and, for each problem, exploring multiple approaches to find different solutions. This practice helps improve problem-solving skills and builds a deeper understanding of various techniques. Make sure to  analyze the time and space complexity of each solution.                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 10. How many questions were asked about projects, clubs and extracurricular activities in your resume ?</b>
                        <br />
                        <p>
                            I was asked 2-3 questions on OOPs, several questions about my project, one DSA question, two SQL queries, and one question on system design.                        </p>
                    </p>
                    <br />
                    <p>
                        <b> 11. Is there something specific that you would want to tell people who try for the same company ?</b>
                        <br />
                        <p>
                            Develop a thorough understanding of the tech stack you have used and the details of your project.

                            <br /> Additionally, study OOPs and DBMS concepts, focusing on real-life examples to enhance your understanding and application of these principles.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b> Any Final tips ?</b>
                        <br />
                        <p>
                        Stay consistent throughout your preparation journey. When it comes to interviews, approach them with confidence and sincerity, as these qualities leave a lasting impression. Remember, rejections in interviews can be discouraging, but don’t let them demotivate you—persevere, learn from the experience, and keep moving forward.

                        </p>
                    </p>
                    <br />


                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID : <a href="mailto:shelarvv22.comp@coeptech.ac.in">
                        shelarvv22.comp@coeptech.ac.in
                        </a>
                        <br />

                        {/* LinkedIn : <a href="https://www.linkedin.com/in/abhinav-kurule-5339b3252/">Abhinav Kurule</a>
                        <br /> */}

                    </div>
                </div>
            </section >
        </div >
    );
};

export default vaishnavi_shelar;
