import React from "react";

const soumyajit_das = () => {
    return (
        <div>
            <section id="content">
                <a href="../diaries.html">
                    <span
                        className="fa fa-arrow-left"
                        style={{
                            fontSize: "2rem",
                            position: "absolute",
                            marginTop: "2rem",
                        }}
                    ></span>
                </a>
                <div className="container text-justify">
                    <h2 style={{ lineHeight: "unset", margin: "unset" }}>
                        Soumyajit Das
                    </h2>
                    <h5 style={{ lineHeight: "unset", margin: "unset" }}>
                        IDeaS - A SAS COMPANY
                    </h5>
                    <div style={{ color: "gray", marginTop: "0.5rem" }}>
                        <p style={{ fontStyle: "oblique" }}>
                            Computer Engineering, Batch of '26
                        </p>
                    </div>
                    <br />

                    <p>
                        <b>1. What was the structure of the entire interview process?</b>
                        <p>1 Technical round, 1 HR round.</p>
                    </p>
                    <br />
                    <p>
                        <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
                        <p>
                            As there was no Aptitude test, the company did the
                            shortlisting on their own criteria which they didn't specify, but it
                            looked majorly on the basis of CGPA.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>3. What was the structure of the Aptitude Round?</b>
                        <p>No aptitude round was conducted.</p>
                    </p>
                    <br />
                    <p>
                        <b>
                            4. After being shortlisted, how long was the wait time before the
                            interviews began? During this period, what specific areas did you
                            focus on to prepare effectively?
                        </b>
                        <p>
                            The interviews were to happen 4 days after the shortlist but got
                            delayed by 8 days due to the potential Maharashtra bandh at that time.
                            During this time, I updated the resume I was going to present during
                            the interview with my latest achievements of winning a hackathon and
                            prepared my project code thoroughly along with revising the concepts
                            used in it like ML, GEN AI, database schema, etc.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>
                            5. How many rounds were there in the company, can you elaborate
                            about each round in brief?
                        </b>
                        <p>
                            There were 2 rounds:
                            <p>
                                The technical interview started with a self-introduction, where I shared my background, projects, and interests. They focused on my math course platform, which was built using Django, HTML, CSS, JavaScript, and MySQL. I explained the project with a prepared README and showcased the database schema, discussing table relations and keys. This led to SQL questions on subqueries and joins. Others were asked questions on OS or CN, depending on their projects. The session ended with a water jug puzzle: <b>measuring 4 litres using 3- and 7-litre jugs</b>.
                            </p>
                            <p>
                                In the HR round, conducted by five panellists - a Senior Manager and four interviewers, I introduced myself, including my family background and achievements. They asked about my strengths, weaknesses, career aspirations, "Why IDeaS," hobbies, and scenario-based questions. <b>Be prepared for classic HR questions, as well as scenario-based ones, as they may be asked.</b>
                            </p>

                        </p>
                    </p>
                    <br />
                    <p>
                        <b>
                            6. What topics or skills did the interviewers focus on during the
                            interviews?
                        </b>
                        <p>
                            They asked questions depending on the code explained to them. I was
                            asked DBMS questions since I explained my database file and code.
                            Others were asked OS or CN questions based on their projects. They
                            stressed puzzles, asking everyone a different one to assess logical
                            reasoning and willingness to learn when the interviewers showed the solution.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>7. How many students were shortlisted after each round?</b>
                        <p>
                            Initially, 20 students for the first round, then 5 for the HR round,
                            and 2 final selections.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>8. Some good resources you used in your internship prep?</b>
                        <p>
                            GeeksforGeeks for interview-ready core subjects like OS, DBMS, CN, etc.
                            Striver's A-Z and SDE sheets for DSA.
                            <br />
                            IndiaBix for aptitude round questions.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>9. Some tips to crack coding questions?</b>
                        <p>
                        It might be stated enough already but practice has no substitute here , it helps increase familiarity , because many companies don't post direct leetcode questions , but the concept and logic used to solve their questions are based off of leetcode questions , Also to prepare for time bound coding round keep giving coding contests on leetcode / gfg / hackerrank regularly.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>
                            10. How many questions were asked about projects, clubs, and
                            extracurricular activities in your resume?
                        </b>
                        <p>
                        The interview was centred around my project - keep a unique project on the side for this occasion and know your code base line by line , 

                        I did not mention clubs or extracurricular activities in my resume , but in the HR round I was asked regarding my hobbies and other non career aspirations.
                        Since I was the TnP coordinator at the time coordinating with the company panellists for the hiring activities so that was also brought up during the interview. 

                        </p>
                    </p>
                    <br />
                    <p>
                        <b>
                            11. Is there something specific that you would want to tell people
                            who try for the same company?
                        </b>
                        <p>
                        Being a revenue management system based company , an interest in data science fields is definitely a plus point. They expect you to know your project end to end. Not mandatory but mentioning interest in learning AWS or knowing it makes you stand out to them.
                        </p>
                    </p>
                    <br />
                    <p>
                        <b>Any Final Tips?</b>
                        <p>
                        Internship season is a tough time , you will see the most unexpected things happen , sometimes you will get rejected even when you are almost guaranteed an offer , sometimes you will feel you deserved the offer more than a person who was selected. These thoughts and feelings are likely to occur and cannot be avoided,
                        <br />You can only take the lessons from the mistakes you made and keep refining them , plenty of opportunities will come and you should try to keep trying your best in each. 
                        <br />Talk to family , close people to you during this time for support and help. 
                        <br />A tip - Try to research about the company and check if recent coep alumni are employees there or not , sometimes they come for the interview process and having a good connection with them beforehand can give a boost to your profile during the process. 
                        <br />All the best to everyone and give your best !!!

                        </p>
                    </p>
                    <br />
                    <h3>Contact Info:</h3>
                    <div style={{ color: "gray", fontSize: "1.15rem" }}>
                        <br />
                        Email-ID:{" "}
                        <a href="mailto:soumyajitd22.comp@coeptech.ac.in">
                            soumyajitd22.comp@coeptech.ac.in
                        </a>
                        <br />
                        LinkedIn:{" "}
                        <a href="https://www.linkedin.com/in/soumyajit-das-a46822251/">
                            Soumyajit Das
                        </a>
                        
                    </div>
                </div>
            </section>
        </div>
    );
};

export default soumyajit_das;
