import React from "react";

const payal_sankpal = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            className="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div className="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Payal Sankpal</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Arcesium</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>
              Aptitude test followed by online interview process. The interview process was divided into 4 rounds, 3 technical and 1 HR.
            </p>
          </p>
          <br />
          <p>
            <b>2. What were the criteria for shortlisting candidates in the initial stages of the recruitment process?</b>
            <p>
              They had a CGPA criteria of above 7 for the aptitude test, and for interviews, it was based on the test scores.
            </p>
          </p>
          <br />
          <p>
            <b>3. What was the structure of the Aptitude Round?</b>
            <p>
              The test was conducted on the HackerRank platform. It consisted of:
              <ol>
                <li>Quantitative and Qualitative Aptitude questions</li>
                <li>Core subjects based questions - DBMS, System Design, etc.</li>
                <li>2 coding questions(1 easy + 1 medium(based on binary narrowing used to find answers-16 Binary Search on Answer Concept))</li>
              </ol>
              <br />All questions were compulsory.
            </p>
          </p>
          <br />
          <p>
            <b>4. After being shortlisted, how long was the wait time before the interviews began? During this period, what specific areas did you focus on to prepare effectively?</b>
            <p>
              The Aptitude test was on July 20th. The results were declared on 22nd July. The Interviews were conducted on 23rd July. 
              The best thing to do for me was get in a good headspace. I was reading short notes I had prepared during my studies on core subjects or solving easy leetcode problems to calm down.
              You can use GFG or other platforms for last minute notes/ questions.
            </p>
          </p>
          <br />
          <p>
            <b>5. How many rounds were there in the company, can you elaborate about each round in brief?</b>
            <p>
              The interview had 4 rounds - 3 technical and 1 HR. Each technical interview lasted between 40 - 60 mins. The HR interview was 20 mins.
              <ul>
                <li><b>First Interview:</b> 2 DSA questions were asked. First was Subset sum 2 from Leetcode. Second on BFS on n-ary tree.</li>
                <li><b>Second Interview:</b> It started with discussion on my projects and the techstack I was familiar with. He asked me questions on the choice of type of database  and concepts in React. He then moved to core subjects and asked questions about OOPs and classes in python specifically. He then gave me a DSA question on Coin Change (Unbounded Knapsack type). He expected me to explain my approach in detail - how sub-problems lead to the final solution.</li>
                <li><b>Third Interview:</b> It started with discussion on my projects and the techstack I was familiar with. He asked me questions on the choice of type of database  and concepts in React. He then moved to core subjects and asked questions about OOPs and classes in python specifically. He then gave me a DSA question on Coin Change (Unbounded Knapsack type). He expected me to explain my approach in detail - how sub-problems lead to the final solution.</li>
                <li><b>HR Interview:</b>For this interview questions were based more on knowing about me. He asked me about my family and residential history. He asked me about my location preference. Why did I prefer one over the other? Make a pros and cons list. Questions about the company. While not my experience, some other candidates were grilled on core subjects questions even in this round.</li>
              </ul>
            </p>
          </p>
          <br />
          <p>
            <b>6. What topics or skills did the interviewers focus on during the interviews?</b>
            <p>
            DSA - for all the three interviews, the majority of time was spent on testing DSA knowledge.
            Since I mentioned in my resume web development projects where my contribution was front-end, the interviewers asked questions on my tech stack - mostly React, how it works (components, passing info, what role it plays)
            Object-Oriented programming - In the second interview, I was asked in detail about OOPs and its language-specific characteristics (overloading vs overriding, how it is implemented in python vs java).

            </p>
          </p>
          <br />
          <p>
            <b>7. How many students were shortlisted after each round?</b>
            <p>
              After the Aptitude test, 15 students were shortlisted. For the final HR interview, there were only 4 candidates, and 2 got selected.
            </p>
          </p>
          <br />
          <p>
            <b>8. Some good resources you used in your internship prep?</b>
            <p>
              <ul>
                <li>Aptitude: Aditya Verma Playlist for DP, Striver's SDE sheet (coding questions), IndiaBix (aptitude/quant questions)</li>
                <li>Interview: Gate Smashers DBMS playlist and OS playlist,GFG archives, GFG Interview questions on core subjects</li>
              </ul>
            </p>
          </p>
          <br />
          <p>
            <b>9. Some tips to crack coding questions?</b>
            <p>
            Practice your basics arrays, binary search, etc. I would suggest Aditya Verma Playlist for DP. He builds the solution intuitively, helping you think. Recursion is an important topic - again you can find good playlists on youtube
            </p>
          </p>
          <br />
          <p>
            <b>10. How many questions were asked about projects, clubs, and extracurricular activities in your resume?</b>
            <p>
            In the second interview, questions were asked about projects. The questions were based more on how familiar I was with the tech stack rather than the actual project.No questions were asked on club or extracurriculars. I had not mentioned much extracurriculars on my resume.
            </p>
          </p>
          <br />
          <p>
            <b>11. Is there something specific that you would want to tell people who try for the same company?</b>
            <p>
            1.	One question on dynamic programming has been tradition: Familiarize yourself with the basics like bounded and unbounded knapsack and past questions
            <br/>2.	The third interview is basically a discussion on a problem where the interviewer is looking for a data structure based answer. They will give you a sort of real world problem and you have to design a solution using DSA. They will give you hints, pay close attention to them. They will ask you questions that will help you build the solution. Be calm, think and explain your thoughts.
            <br/>3.	They are definitely looking at the way you code, your variable names, your function names, general clean coding practices. 
            </p>
          </p>
          <br />
          <p>
            <b>Final Tip for Juniors:</b>
            <p>
              Be confident. Interviews are as much about good communication as about technical skills. Don't underestimate the basics, whether in core subjects or your projects. Make sure you are confident about everything you mention in your resume. While it can feel high-pressure and challenging to remain composed, maintaining control is essential. Getting nervous during an interview can hinder your ability to answer even well-prepared questions effectively..
            </p>
          </p>
          <br />
          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID:{" "}
            <a href="mailto:payalss22.comp@coeptech.ac.in">
              payalss22.comp@coeptech.ac.in
            </a>
            <br />
            LinkedIn:{" "}
            <a href="https://www.linkedin.com/in/payal-sankpal-4732652a5/">
              Payal Sankpal
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default payal_sankpal;
