import React from "react";

const akshitaDhoot = () => {
  return (
    <div>
      <section id="content">
        <a href="../diaries.html">
          <span
            class="fa fa-arrow-left"
            style={{
              fontSize: "2rem",
              position: "absolute",
              marginTop: "2rem",
            }}
          ></span>
        </a>
        <div class="container text-justify">
          <h2 style={{ lineHeight: "unset", margin: "unset" }}>Akshita Dhoot</h2>
          <h5 style={{ lineHeight: "unset", margin: "unset" }}>Citi Corporation</h5>
          <div style={{ color: "gray", marginTop: "0.5rem" }}>
            <p style={{ fontStyle: "oblique" }}>
              Computer Engineering, Batch of '26
            </p>
          </div>
          <br />

          <p>
            <b>1. What was the structure of the entire interview process?</b>
            <p>1. Technical Interview 1</p>
            <p>2. Technical Interview 2</p>
            <p>3. HR Interview</p>
          </p>
          <p>

            <br />
            <b>
              2. What were the criteria for shortlisting candidates in the
              initial stages of the recruitment process?
            </b>
            <p>I opted for PBWM. The initial shortlisting was done based on resume evaluation and CGPA. After this, there was an Aptitude Test, which was followed by interviews.</p>
          </p>
          <br />
          <p>
            <b>3. What was the structure of the Aptitude Round ? </b>
            <br />
            <p>
              1. MCQs: Quantitative Aptitude, Verbal Ability, Logical Reasoning, and Technical questions.
              <br />
              2. Coding: 2 questions.
            </p>
          </p>
          <p>
            <b>
              4. After being shortlisted, how long was the wait time before the
              interviews : began? During this period, what specific areas did
              you focus on to prepare effectively?
            </b>
            <br />
            <p>
              After being shortlisted, the interview was conducted the very next day, on 9th October 2024, with the test being held on 4th October 2024 and the shortlist for interviews coming out on 8th October 2024. 
With limited time to prepare, my main focus was on revising concepts from DSA, OOPs, SQL, and other core subjects. I also concentrated on reviewing the projects I had mentioned in my resume, ensuring I could explain them in detail. Additionally, I worked on problem-solving and puzzles to sharpen my skills for the technical rounds.
            </p>
          </p>
          <br />
          <p>
            <b>
              {" "}
              5. How many rounds were there in the company, can you elaborate
              about each round in brief ?
            </b>
            <br />
            <p>The interview process for Citi Corporation consisted of four rounds:
            <br />1. Aptitude Test: The aptitude round covered multiple areas including quantitative reasoning, verbal abilities, logical reasoning, technical concepts, and coding. The level of the questions was generally easy to medium. The quantitative section included basic questions on ratio and proportion, permutations and combinations, matrices, etc. Verbal ability included passage-based questions. Logical reasoning involved sequence series, mirror image, and IQ-based questions. The technical section focused on sorting, searching algorithms, complexity analysis, and the output of code snippets or SQL queries. <br />
            <br />2. Technical Interview 1: I was asked in-depth questions about my regression project, where I had to explain the project details, its working, and other related aspects. The interviewer also tested my knowledge on DSA concepts, particularly arrays and linked list. I was given a problem to find the middlemost element in a linked list and asked to analyze its time complexity. Additionally, I was questioned on OOPs concepts, specifically inheritance, and was asked to demonstrate how inheritance works in OOPs. <br />
            <br />3. Technical Interview 2: This round was a combination of technical and managerial questions. The interviewer asked about my projects, clubs and my roles in it, while also asking about how I manage stress, work in a team, and manage time, my future career plans, how I would manage or handle situational scenarios, and why I chose to apply to Citi. The focus was both on my technical abilities and how I would fit within a team.
            <br />4. HR Interview: The HR round was mostly conversational, focusing on me as an individual. The interviewer asked about my hobbies, biggest achievements, strengths, and weaknesses. I was also asked questions about my resume, and mostly focused on personality and soft skills. I also asked questions regarding the role at Citi and received feedback on my interview performance.
</p>
          </p>
          <br />
          <p>
            <b>
              6. What topics or skills did the interviewers focus on during the
              interviews? (e.g., DSA, puzzles, DBMS, OS, Networks, System
              Design, or Behavioral questions)
            </b>
            <br />
            <p>
              The interviewers primarily focused on projects, asking detailed questions about the work and technologies involved. They also emphasized DSA and OOPs, problem-solving abilities, as well as personal aspects such as my strengths, weaknesses, and experiences.
            </p>
          </p>
          <br />
          <p>
            <b>7. How many students were shortlisted after each round? </b>
            <br />
            There were two roles: Institutional Clients Group (ICG) and Personal Banking and Wealth Management (PBWM).
            <br />
    • After Aptitude Round: 44 students were shortlisted (32 for ICG and 12 for PBWM).
    <br />
    • After Interviews: Final selections – 9 for ICG and 3 for PBWM.
          </p>
          <br />
          <p>
            <b>8. Some good resources you used in your internship prep? </b>
            <br />
Aptitude: Indiabix <br />
Puzzles: GFG<br />
DSA: Striver’s A2Z DSA course and Luv Babbar’s 450 DSA sheet. <br />
Coding Practice: Leetcode, HackerRank <br />
Concepts: GFG’s Last Minute Notes for DBMS, CN, OS. <br />
          </p>
          <br />
          <p>
            <b> 9. Some tips to crack coding questions ?</b>
            <br />
            <p>Practice regularly with problems of varying difficulty to enhance your coding skills. Focus on concepts like array, linked lists, searching and sorting algorithms, patterns. Understand the problem thoroughly before coding. Coding questions are usually easy, so stay calm and approach problems effectively.</p>
          </p>
          <br />
          <p>
            <b>
              10. How many questions were asked about projects, clubs and
              extracurricular activities in your resume ?
            </b>
            <br />
            <p>A lot of questions were asked on my projects in the technical round which revolved around the tech-stack that I had used. A few questions about my clubs, extracurricular activities, and hobbies were asked in round 2 and 3.</p>
          </p>
          <br />
          <br />
          <p>
            <b> Any Final tips ?</b>
            <br />
            <p>
Stay confident and positive throughout the process. If you encounter a question you're unsure about, focus on your thought process and how you approach the problem—it’s all about demonstrating your problem-solving skills. The internship journey can be challenging, but don’t let the pressure get to you. Be honest and confident in your interviews, prioritize your well-being, and continue pushing yourself forward. Remember, what is meant for you will come—so stay calm, do your best, and keep trying. Best of luck!
            </p>
          </p>
          <br />

          <h3>Contact Info:</h3>
          <div style={{ color: "gray", fontSize: "1.15rem" }}>
            <br />
            Email-ID: <a href="mailto:dhootakshita@gmail.com
">dhootakshita@gmail.com
            </a>

            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default akshitaDhoot;
